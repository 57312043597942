<template>
  <el-card class="components">

    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="输入编号检索" @keydown.enter.native="onSubmitQuery"></el-input>
      </div>
      <div class="box-inline standard-search mar-lft-10">
        <el-select v-model="queryForm.supplier_id" placeholder="工厂" clearable>
          <el-option v-for="(item) in suppliers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="box-inline standard-search mar-lft-10">
        <el-date-picker value-format="yyyy-MM-dd" clearable
          v-model="queryForm.start_date"
          type="date" placeholder="开始日期">
        </el-date-picker>
      </div>
      -
      <div class="box-inline standard-search">
        <el-date-picker value-format="yyyy-MM-dd" clearable
          v-model="queryForm.end_date"
          type="date" placeholder="截至日期">
        </el-date-picker>
      </div>
      <el-button size="small" @click="onSubmitQuery">
        <i class="fa fa-fw fa-search"></i>
      </el-button>
    </div>

    <table class="table" v-loading="loading">
      <thead>
        <tr>
          <th width="25">#</th>
          <th>款号</th>
          <th>编号</th>
          <th>工厂</th>
          <th>数量</th>
          <th>日期</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="item.id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.kh }}</td>
          <td>{{ item.code }}</td>
          <td>{{ supplierMap[item.supplier_id] }}</td>
          <td>{{ item.number }}</td>
          <td>{{ item.occur_date }}</td>
        </tr>
      </tbody>
    </table>

    <div class="clearfix mar-top">
      总计入库件数：{{sum}}
      <el-pagination class="pull-right"
        @current-change="changePage"
        layout="total, prev, pager, next"
        :total="totalItem"
        :current-page="currentPage"
        :page-size="pageSize">
      </el-pagination>
    </div>

  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      waiting: false,
      loading: false,
      // 查询条件form
      queryForm: {
        code: '',
        supplier_id: null,
        start_date: null,
        end_date: null,
      },
      sum: 0,
      suppliers: [],
      queryParams: {}, // 分页查询参数
      list: [], // 管理的列表对象
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    supplierMap() {
      const r = {};
      this.suppliers.forEach((i) => {
        r[i.id] = i.name;
      });
      return r;
    },
  },
  created() {
    this.getSuppliers();
    this.onSubmitQuery();
  },
  methods: {
    // 加工厂信息
    getSuppliers() {
      this.axios.get('clothes-supplier', { params: { all: 1 } }).then((r) => {
        this.suppliers = r.data;
      });
    },
    // 查询
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 查询列表数据
    getList() {
      this.sum = 0;
      this.loading = true;
      this.$http.get('supplier-log-sum', { params: this.queryParams }).then((r) => {
        this.sum = r.data;
      });
      this.$http.get('supplier-log', { params: { ...this.queryParams, page: this.currentPage, pageSize: this.pageSize } })
        .then((response) => {
          const list = response.data.data;
          this.loading = false;
          this.list = list;
          this.list = response.data.data;
          this.totalItem = response.data.total;
        }, (err) => {
          this.loading = false;
          this.$utils.resp(err);
        });
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
  },
};
</script>
