<template>
  <el-card>
    <el-button @click="getList" type="primary">刷新</el-button>
    <div class="mar-btm-10 pull-right">
      <div class="box-inline standard-search">
        <el-input v-model="queryForm.code" clearable placeholder="SKU"></el-input>
      </div>
    </div>
    <el-table ref="table" class="big" border v-loading="loading" :data="showList" style="width: 100%; " height="calc(100vh - 200px)">
      <el-table-column width="130" label="款号(库存)" prop="code" fixed="left">
        <template slot-scope="scope">
         {{scope.row.code.substr(0, 5)}}（{{scope.row.sum}}）
        </template>
      </el-table-column>
      <el-table-column width="130" label="颜色" prop="code" fixed="left">
        <template slot-scope="scope">
         ({{scope.row.code.substr(5, 8)}}){{scope.row.color}}
        </template>
      </el-table-column>
      <el-table-column width="100" label="类型" prop="category" fixed="left"></el-table-column>
      <el-table-column width="70" label="图片" prop="code" fixed="left">
        <template slot-scope="scope">
         <img v-if="scope.row.simg" style="width: 40px" :src="'data:image/jpg/png/gif;base64,' + scope.row.simg"
          alt=""/>
        </template>
      </el-table-column>
      <el-table-column width="70" label="库存" prop="stock" fixed="left"></el-table-column>
      <el-table-column width="150" v-for="(item, index) in outTypes" :key="index" :label="item.label" prop="code">
        <template slot-scope="scope">
          <span class="text-primary" v-if="scope.row.counts">{{scope.row.counts[item.key][0] - scope.row.counts[item.key][1]}}</span>
          <span class="text-primary" v-else>0</span>
          [
          <span class="text-primary" v-if="scope.row.counts">{{scope.row.counts[item.key][0]}}</span>
          <span class="text-primary" v-else>0</span>
          /
          <span class="text-danger" v-if="scope.row.counts">{{scope.row.counts[item.key][1] ? -scope.row.counts[item.key][1] : 0}}</span>
          <span class="text-danger" v-else>0</span>
          ]
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      list: [],
      queryForm: {
        code: null,
      },
      outTypes: [],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    showList() {
      let r = this.list;
      const code = this.queryForm.code ? this.queryForm.code.trim() : null;
      if (code) {
        r = r.filter((i) => i.code.indexOf(code) !== -1);
      }
      return r;
    },
  },
  methods: {
    getList() {
      this.loading = true;
      const params = { status: '1' };
      this.axios.get('outtypetj', { params }).then((r) => {
        this.loading = false;
        const { types, list } = r.data;
        this.setOutTypes(types); // 出库类型
        const khList = list.sort((a, b) => {
          let result = 0;
          if (a.sum < b.sum) {
            result = 1;
          } else {
            result = -1;
          }
          return result;
        });
        const allList = [];
        khList.forEach((item) => {
          item.list.forEach((i) => {
            const simg = i.imgs ? i.imgs.simg : null;
            allList.push({ ...i,
              kh: item.kh,
              sum: item.sum,
              stock: i.stock,
              counts: i.counts,
              simg,
            });
          });
        });
        this.list = allList;
        this.$nextTick(() => {
          if (this.$refs.table && this.$refs.table.doLayout) {
            this.$refs.table.doLayout();
          }
        });
      }, (e) => {
        this.loading = false;
        this.$utils.resp(e);
      });
    },
    // 排序出库类型
    setOutTypes(types) {
      const typeList = [];
      const typeObj = { ...types };
      const typeOrder = ['4', '5', '8', '52', '1', '6', '2'];
      // 出货预设排序
      typeOrder.forEach((i) => {
        typeList.push({
          label: types[i],
          key: i,
        });
        typeObj[i] = null;
      });
      // 出货预设排序后面所有分销 3开头的
      const allKeys = Object.keys(types);
      allKeys.forEach((a) => {
        if (a.indexOf('3') === 0 && typeObj[a]) {
          typeList.push({
            label: types[a],
            key: a,
          });
          typeObj[a] = null;
        }
      });
      // 另外的排后面
      allKeys.forEach((a) => {
        if (typeObj[a] && a !== '0') {
          typeList.push({
            label: types[a],
            key: a,
          });
          typeObj[a] = null;
        }
      });
      // 其他排最后面
      typeList.push({ key: '0', label: '其他' });
      this.outTypes = typeList;
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .el-table.big th{
    padding: 3px 0 !important;
  }
  .el-table.big td{
    padding: 3px 0 !important;
  }
}
</style>
