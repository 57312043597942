<template>
  <el-card>
    <div>
      <el-select  v-model="queryForm.distributor_id" placeholder="分销商" @change="getData">
        <el-option v-for="item in distributors" :key="item.id" :value="item.id" :label="item.name"></el-option>
      </el-select>
      <el-input style="width: 200px;" clearable v-model="queryForm.code" @keydown.enter.native="getData" placeholder="编号"></el-input>
      <el-button icon="el-icon-search" @click="getData"></el-button>
    </div>
    <div>
      <table class="table bordered" v-loading="loading">
        <thead>
          <tr>
            <th>款号</th>
            <th>类型</th>
            <th>图片</th>
            <th>颜色</th>
            <th>F</th>
            <th>XS</th>
            <th>S</th>
            <th>S/M</th>
            <th>M</th>
            <th>M/L</th>
            <th>L</th>
            <th>XL</th>
            <th>XXL</th>
            <th>合计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td>{{item.kh}}</td>
            <td>{{item.sub_category}}</td>
            <td><img v-if="item.imgs[0]" :src="'data:image/jpg/png/gif;base64,' + item.imgs[0].simg" alt=""></td>
            <td>{{item.color}}</td>
            <td>{{item.F.stock}}({{item.F.in * 1 + item.F.back * 1}} / {{item.F.out}})</td>
            <td>{{item.XS.stock}}({{item.XS.in * 1 + item.XS.back * 1}} / {{item.XS.out}})</td>
            <td>{{item.S.stock}}({{item.S.in * 1 + item.S.back * 1}} / {{item.S.out}})</td>
            <td>{{item['S/M'].stock}}({{item['S/M'].in * 1 + item['S/M'].back * 1}} / {{item['S/M'].out}})</td>
            <td>{{item.M.stock}}({{item.M.in * 1 + item.M.back * 1}} / {{item.M.out}})</td>
            <td>{{item['M/L'].stock}}({{item['M/L'].in * 1 + item['M/L'].back * 1}} / {{item['M/L'].out}})</td>
            <td>{{item.L.stock}}({{item.L.in * 1 + item.L.back * 1}} / {{item.L.out}})</td>
            <td>{{item.XL.stock}}({{item.XL.in * 1 + item.XL.back * 1}} / {{item.XL.out}})</td>
            <td>{{item.XXL.stock}}({{item.XXL.in * 1 + item.XXL.back * 1}} / {{item.XXL.out}})</td>
            <td>{{item.total.stock}}({{item.total.in * 1 + item.total.back * 1}} / {{item.total.out}})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      waiting: false,
      queryForm: {
        distributor_id: null,
      },
      distributors: [],
      list: [],
      // flag: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  created() {
    const params = { pageSize: 100, page: 1 };
    this.$http.get('distributor', { params }).then((r) => {
      this.distributors = r.data.data;
    });
    setTimeout(() => {
      this.queryForm.distributor_id = this.user.distributor_id;
      this.getData();
    }, 1000);
  },
  methods: {
    getData() {
      if (!this.queryForm.distributor_id) {
        //
      } else {
        const params = {
          ...this.queryForm,
        };
        this.loading = true;
        this.axios.get('distributorstock', { params }).then((r) => {
          this.loading = false;
          const khs = r.data;
          khs.forEach((item) => {
            item.total = {
              in: item.F.in * 1 + item.XS.in * 1 + item.S.in * 1 + item['S/M'].in * 1
                + item['M/L'].in * 1 + item.M.in * 1 + item.L.in * 1 + item.XL.in * 1 + item.XXL.in * 1,
              out: item.F.out * 1 + item.XS.out * 1 + item.S.out * 1 + item['S/M'].out * 1
                + item['M/L'].out * 1 + item.M.out * 1 + item.L.out * 1 + item.XL.out * 1 + item.XXL.out * 1,
              back: item.F.back * 1 + item.XS.back * 1 + item.S.back * 1 + item['S/M'].back * 1
                + item['M/L'].back * 1 + item.M.back * 1 + item.L.back * 1 + item.XL.back * 1 + item.XXL.back * 1,
              stock: item.F.stock * 1 + item.XS.stock * 1 + item.S.stock * 1 + item['S/M'].stock * 1 + item['M/L'].stock * 1
                + item.M.stock * 1 + item.L.stock * 1 + item.XL.stock * 1 + item.XXL.back * 1,
            };
          });
          this.list = khs;
        }, (e) => {
          this.loading = false;
          this.$utils.resp(e);
        });
      }
    },
  },
};
</script>

<style>

</style>
