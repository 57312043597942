<template>
  <el-card class="component">
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane name="first" label="当前库存">
        <div class="mar-btm-10 text-right">
          <div class="pull-left">
            <el-button icon="fa fa-fw fa-download" title="导出前可在右边选择在售状态" @click="toExcel">导出库存excel</el-button>
            <a v-show="false" :href="excelUrl" ref="excelLink" class="href">库存统计导出</a>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="queryForm.kh" clearable filterable placeholder="款号">
              <el-option v-for="item in khs" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search mar-lft-10">
            <el-select v-model="queryForm.rela_style" clearable filterable placeholder="关联款号">
              <el-option v-for="item in relaStyles" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="queryForm.status" clearable filterable placeholder="状态">
              <el-option v-for="(label, key) in statuses" :key="key" :value="key" :label="label"></el-option>
            </el-select>
          </div>
          <div class="box-inline standard-search">
            <el-select v-model="orderType" clearable placeholder="排序" filterable>
              <el-option v-for="(label, key) in orderTypes" :key="key" :value="key" :label="`${label}`"></el-option>
            </el-select>
          </div>
          <el-button size="small" @click="onSubmitQuery">
            <i class="fa fa-fw fa-search"></i>
          </el-button>
          <!-- <el-button size="small" @click="excel">
            <i class="fa fa-fw fa-download"></i>
          </el-button> -->
        </div>
        <div>
          合计：<span class="text-primary">{{sumTj.stockTotal}}</span>
          <span class="text-warning"> 显示数据说明：库存/预警库存 [入库-出货] 动销率 </span>
        </div>
        <div :style="{height: boxHeight+'px', overflow: 'auto'}">
          <table class="table bordered" v-loading="loading">
            <thead>
              <tr>
                <th class="text-center">款号</th>
                <th class="text-center">关联款号</th>
                <th class="text-center">类别</th>
                <th class="text-center">颜色</th>
                <th class="text-center">图片</th>
                <th class="text-center">F</th>
                <th class="text-center">XS</th>
                <th class="text-center">S</th>
                <th class="text-center">S/M</th>
                <th class="text-center">M</th>
                <th class="text-center">M/L</th>
                <th class="text-center">L</th>
                <th class="text-center">XL</th>
                <th class="text-center">XXL</th>
                <th class="text-center">合计</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(obj) in dataList">
                <tr v-for="(item, index) in obj.list" :key="`${obj.code}_${index}`">
                  <td :rowspan="obj.list.length" v-if="index == 0">{{String(item.code).substr(0, 5)}}
                    <br/>
                    <span class="text-primary">{{obj.stockTotal}}</span>
                    <span v-if="obj.inTotal">
                      <br/>
                      [{{obj.inTotal}}-{{obj.outTotal-obj.backTotal}}]
                      {{(Math.round(((obj.outTotal - obj.backTotal) * 10000) / obj.inTotal) / 100) + '%'}}
                    </span>
                  </td>
                  <td :rowspan="obj.list.length" v-if="index == 0">{{item.rela_style}}</td>
                  <td :rowspan="obj.list.length" v-if="index == 0">
                    {{categories[String(item.code).substr(1, 2)] ? categories[String(item.code).substr(1, 2)] : item.category}}
                  </td>
                  <td>{{item.color}}</td>
                  <td>
                    <el-popover v-if="item.imgs"
                      placement="right"
                      trigger="hover">
                      <img :src="showImgUrl">
                      <img slot="reference" @mouseover="showItemImg(item, false)" @click="showItemImg(item, true)"
                        :src="'data:image/jpg/png/gif;base64,' + item.imgs.simg" alt="">
                    </el-popover>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size0[0] !== '-'">
                      {{item.size0[0]}}/{{item.size0[1]}}
                      <span class="text-warning">
                        {{ (item.size0[1] > item.size0[0])  ? item.size0[0] - item.size0[1] : ''}}
                      </span>
                      <span v-if="item.size0[2]">
                        <br/>
                        [{{item.size0[2]}}-{{item.size0[3]-item.size0[4]}}]{{item.size0[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size1[0] !== '-'">
                      {{item.size1[0]}}/{{item.size1[1]}}
                      <span class="text-warning">
                        {{ (item.size1[1] > item.size1[0])  ? item.size1[0] - item.size1[1] : ''}}
                      </span>
                      <span v-if="item.size1[2]">
                        <br/>
                        [{{item.size1[2]}}-{{item.size1[3]-item.size1[4]}}]{{item.size1[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size2[0] !== '-'">
                      {{item.size2[0]}}/{{item.size2[1]}}
                      <span class="text-warning">
                        {{ (item.size2[1] > item.size2[0])  ? item.size2[0] - item.size2[1] : ''}}
                      </span>
                      <span v-if="item.size2[2]">
                        <br/>
                        [{{item.size2[2]}}-{{item.size2[3]-item.size2[4]}}]{{item.size2[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size7[0] !== '-'">
                      {{item.size7[0]}}/{{item.size7[1]}}
                      <span class="text-warning">
                        {{ (item.size7[1] > item.size7[0])  ? item.size7[0] - item.size7[1] : ''}}
                      </span>
                      <span v-if="item.size7[2]">
                         <br/>
                        [{{item.size7[2]}}-{{item.size7[3]-item.size7[4]}}]{{item.size7[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size3[0] !== '-'">
                      {{item.size3[0]}}/{{item.size3[1]}}
                      <span class="text-warning">
                        {{ (item.size3[1] > item.size3[0])  ? item.size3[0] - item.size3[1] : ''}}
                      </span>
                      <span v-if="item.size3[2]">
                         <br/>
                        [{{item.size3[2]}}-{{item.size3[3]-item.size3[4]}}]{{item.size3[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size8[0] !== '-'">
                      {{item.size8[0]}}/{{item.size8[1]}}
                      <span class="text-warning">
                        {{ (item.size8[1] > item.size8[0])  ? item.size8[0] - item.size8[1] : ''}}
                      </span>
                      <span v-if="item.size8[2]">
                         <br/>
                        [{{item.size8[2]}}-{{item.size8[3]-item.size8[4]}}]{{item.size8[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size4[0] !== '-'">
                      {{item.size4[0]}}/{{item.size4[1]}}
                      <span class="text-warning">
                        {{ (item.size4[1] > item.size4[0])  ? item.size4[0] - item.size4[1] : ''}}
                      </span>
                      <span v-if="item.size4[2]">
                         <br/>
                        [{{item.size4[2]}}-{{item.size4[3]-item.size4[4]}}]{{item.size4[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size5[0] !== '-'">
                      {{item.size5[0]}}/{{item.size5[1]}}
                      <span class="text-warning">
                        {{ (item.size5[1] > item.size5[0])  ? item.size5[0] - item.size5[1] : ''}}
                      </span>
                      <span v-if="item.size5[2]">
                         <br/>
                        [{{item.size5[2]}}-{{item.size5[3]-item.size5[4]}}]{{item.size5[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    <template v-if="item.size6[0] !== '-'">
                      {{item.size6[0]}}/{{item.size6[1]}}
                      <span class="text-warning">
                        {{ (item.size6[1] > item.size6[0])  ? item.size6[0] - item.size6[1] : ''}}
                      </span>
                      <span v-if="item.size6[2]">
                         <br/>
                        [{{item.size6[2]}}-{{item.size6[3]-item.size6[4]}}]{{item.size6[5]}}
                      </span>
                    </template>
                  </td>
                  <td class="text-right">
                    {{item.stockTotal}}
                    <span v-if="item.inTotal">
                      <br/>
                      [{{item.inTotal}}-{{item.outTotal-item.backTotal}}]
                      <span>
                        {{(Math.round(((item.outTotal - item.backTotal) * 10000) / item.inTotal) / 100) + '%'}}
                      </span>
                    </span>
                  </td>
                </tr>

              </template>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      khs: [],
      activeName: 'first',
      relaStyles: [],
      outTypes: [],
      boxHeight: 100,
      queryForm: {
        status: '1',
      },
      countMap: {},
      totalData: [],
      orderType: 'stock_desc',
      statuses: {
        1: '在售',
        5: '下架',
      },
      orderTypes: {
        stock_desc: '库存',
        lack_desc: '缺货量',
      },
      showImgUrl: '',
      excelUrl: 'asset-api/e-stock-excel',
    };
  },
  created() {
    this.init();
  },
  computed: {
    dataList() {
      const order = this.orderType;
      const list = this.totalData;
      const l = list.sort((a, b) => {
        let res = b.stockTotal - a.stockTotal;
        if (order === 'lack_desc') {
          res = a.lackTotal - b.lackTotal;
        }
        return res;
      });
      l.forEach((item) => {
        item.list = item.list.sort((a, b) => {
          let res = b.stockTotal - a.stockTotal;
          if (order === 'lack_desc') {
            res = a.lackTotal - b.lackTotal;
          }
          return res;
        });
      });
      return l;
    },
    sumTj() {
      const res = {
        stockTotal: 0,
      };
      this.totalData.forEach((item) => {
        res.stockTotal += item.stockTotal;
      });
      return res;
    },
  },
  methods: {
    init() {
      this.boxHeight = document.documentElement.clientHeight - 260;
      if (this.$store.state.common.clothesDicts.sizes) {
        this.categories = this.$store.state.common.clothesDicts.orgCategories;
        this.colors = this.$store.state.common.clothesDicts.orgColors;
        this.outTypes = this.$store.state.common.clothesDicts.sub_types;
        this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
        this.khs = this.$store.state.common.clothesDicts.khs;
      } else {
        this.$store.dispatch('GET_COLTHES_DICTS').then((resp) => {
          this.categories = resp.data.orgCategories;
          this.colors = resp.data.orgColors;
          this.outTypes = this.$store.state.common.clothesDicts.sub_types;
          this.relaStyles = this.$store.state.common.clothesDicts.relaStyles;
          this.khs = this.$store.state.common.clothesDicts.khs;
        });
      }
    },
    // 标签切换时触发
    tabClick() {

    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm, tjType: 'realOut' };
      // if (this.currentPage !== 1) {
      //   this.currentPage = 1;
      // }
      // this.getList();
      this.getTj();
    },
    getTj() {
      this.loading = true;
      this.axios.get('e-stock-tj', { params: this.queryForm }).then((resp) => {
        const temps = resp.data;
        const keys = Object.keys(temps);
        const list = [];
        keys.forEach((key) => {
          const arr = temps[key];
          let stockTotal = 0;
          let lackTotal = 0;
          let inTotal = 0;
          let outTotal = 0;
          let backTotal = 0;
          arr.forEach((item) => {
            let stock = 0;
            if (item.size0[0] !== '-' && item.size0[2]) {
              item.size0[5] = (Math.round(((item.size0[3] - item.size0[4]) * 10000) / item.size0[2]) / 100) + '%';
            }
            if (item.size1[0] !== '-' && item.size1[2]) {
              item.size1[5] = (Math.round(((item.size1[3] - item.size1[4]) * 10000) / item.size1[2]) / 100) + '%';
            }
            if (item.size2[0] !== '-' && item.size2[2]) {
              item.size2[5] = (Math.round(((item.size2[3] - item.size2[4]) * 10000) / item.size2[2]) / 100) + '%';
            }
            if (item.size7[0] !== '-' && item.size7[2]) {
              item.size7[5] = (Math.round(((item.size7[3] - item.size7[4]) * 10000) / item.size7[2]) / 100) + '%';
            }
            if (item.size3[0] !== '-' && item.size3[2]) {
              item.size3[5] = (Math.round(((item.size3[3] - item.size3[4]) * 10000) / item.size3[2]) / 100) + '%';
            }
            if (item.size8[0] !== '-' && item.size8[2]) {
              item.size8[5] = (Math.round(((item.size8[3] - item.size8[4]) * 10000) / item.size8[2]) / 100) + '%';
            }
            if (item.size4[0] !== '-' && item.size4[2]) {
              item.size4[5] = (Math.round(((item.size4[3] - item.size4[4]) * 10000) / item.size4[2]) / 100) + '%';
            }
            if (item.size5[0] !== '-' && item.size5[2]) {
              item.size5[5] = (Math.round(((item.size5[3] - item.size5[4]) * 10000) / item.size5[2]) / 100) + '%';
            }
            if (item.size6[0] !== '-' && item.size6[2]) {
              item.size6[5] = (Math.round(((item.size6[3] - item.size6[4]) * 10000) / item.size6[2]) / 100) + '%';
            }
            stock += item.size0[0] !== '-' ? item.size0[0] : 0;
            stock += item.size1[0] !== '-' ? item.size1[0] : 0;
            stock += item.size2[0] !== '-' ? item.size2[0] : 0;
            stock += item.size7[0] !== '-' ? item.size7[0] : 0;
            stock += item.size3[0] !== '-' ? item.size3[0] : 0;
            stock += item.size8[0] !== '-' ? item.size8[0] : 0;
            stock += item.size4[0] !== '-' ? item.size4[0] : 0;
            stock += item.size5[0] !== '-' ? item.size5[0] : 0;
            stock += item.size6[0] !== '-' ? item.size6[0] : 0;
            item.stockTotal = stock;
            stockTotal += item.stockTotal;
            let lack = 0;
            lack += (item.size0[0] !== '-' && item.size0[0] < item.size0[1]) ? (item.size0[0] - item.size0[1]) : 0;
            lack += (item.size1[0] !== '-' && item.size1[0] < item.size1[1]) ? (item.size1[0] - item.size1[1]) : 0;
            lack += (item.size2[0] !== '-' && item.size2[0] < item.size2[1]) ? (item.size2[0] - item.size2[1]) : 0;
            lack += (item.size7[0] !== '-' && item.size7[0] < item.size7[1]) ? (item.size7[0] - item.size7[1]) : 0;
            lack += (item.size3[0] !== '-' && item.size3[0] < item.size3[1]) ? (item.size3[0] - item.size3[1]) : 0;
            lack += (item.size8[0] !== '-' && item.size8[0] < item.size8[1]) ? (item.size8[0] - item.size8[1]) : 0;
            lack += (item.size4[0] !== '-' && item.size4[0] < item.size4[1]) ? (item.size4[0] - item.size4[1]) : 0;
            lack += (item.size5[0] !== '-' && item.size5[0] < item.size5[1]) ? (item.size5[0] - item.size5[1]) : 0;
            lack += (item.size6[0] !== '-' && item.size6[0] < item.size6[1]) ? (item.size6[0] - item.size6[1]) : 0;
            item.lackTotal = lack;
            lackTotal += item.lackTotal;
            let inNum = 0;
            inNum += item.size0[0] !== '-' ? item.size0[2] : 0;
            inNum += item.size1[0] !== '-' ? item.size1[2] : 0;
            inNum += item.size2[0] !== '-' ? item.size2[2] : 0;
            inNum += item.size7[0] !== '-' ? item.size7[2] : 0;
            inNum += item.size3[0] !== '-' ? item.size3[2] : 0;
            inNum += item.size8[0] !== '-' ? item.size8[2] : 0;
            inNum += item.size4[0] !== '-' ? item.size4[2] : 0;
            inNum += item.size5[0] !== '-' ? item.size5[2] : 0;
            inNum += item.size6[0] !== '-' ? item.size6[2] : 0;
            item.inTotal = inNum;
            inTotal += item.inTotal;
            let outNum = 0;
            outNum += item.size0[0] !== '-' ? item.size0[3] : 0;
            outNum += item.size1[0] !== '-' ? item.size1[3] : 0;
            outNum += item.size2[0] !== '-' ? item.size2[3] : 0;
            outNum += item.size7[0] !== '-' ? item.size7[3] : 0;
            outNum += item.size3[0] !== '-' ? item.size3[3] : 0;
            outNum += item.size8[0] !== '-' ? item.size8[3] : 0;
            outNum += item.size4[0] !== '-' ? item.size4[3] : 0;
            outNum += item.size5[0] !== '-' ? item.size5[3] : 0;
            outNum += item.size6[0] !== '-' ? item.size6[3] : 0;
            item.outTotal = outNum;
            outTotal += item.outTotal;
            let backNum = 0;
            backNum += item.size0[0] !== '-' ? item.size0[4] : 0;
            backNum += item.size1[0] !== '-' ? item.size1[4] : 0;
            backNum += item.size2[0] !== '-' ? item.size2[4] : 0;
            backNum += item.size7[0] !== '-' ? item.size7[4] : 0;
            backNum += item.size3[0] !== '-' ? item.size3[4] : 0;
            backNum += item.size8[0] !== '-' ? item.size8[4] : 0;
            backNum += item.size4[0] !== '-' ? item.size4[4] : 0;
            backNum += item.size5[0] !== '-' ? item.size5[4] : 0;
            backNum += item.size6[0] !== '-' ? item.size6[4] : 0;
            item.backTotal = backNum;
            backTotal += item.backTotal;
          });
          const arr2 = arr.sort((a, b) => b.stockTotal - a.stockTotal);
          list.push({
            stockTotal,
            lackTotal,
            inTotal,
            outTotal,
            backTotal,
            list: arr2,
            code: key,
          });
        });
        this.totalData = list;
        this.loading = false;
      });
    },
    // 显示图片
    showItemImg(item, isFullScrean = false) {
      const p1 = /^[A-Z]/;
      let hint = 'clothes';
      if (p1.test(item.code)) {
        hint = 'e_clothes';
      }
      this.showImg(item.imgs, hint, isFullScrean);
    },
    showImg(file, hint = 'clothes', isFullScrean) {
      const params = {
        hint,
        name: file.name,
      };
      this.$http.get('getfile', { params, responseType: 'arraybuffer' }).then((resp) => {
        const blob = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const objurl = URL.createObjectURL(blob);
        this.showImgUrl = objurl;
        if (isFullScrean) {
          this.$store.commit('SET_BIG_IMG_URL', objurl);
          this.$store.commit('PICKER_SHOW_SHOW_BIG_IMG');
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    toExcel() {
      this.excelUrl = '/asset-api/e-stock-excel';
      if (this.queryForm.status) {
        this.excelUrl = `/asset-api/e-stock-excel?status=${this.queryForm.status}`;
      }
      this.$nextTick(() => {
        this.$refs.excelLink.click();
      });
    },
  },
};
</script>

<style>

</style>
