<template>
  <el-card>
    <form ref="form">
      上传文件<input type="file" ref="file" style="width: 100%;" @change="onChange" />
    </form>
    <el-button @click="reset"> 重置</el-button>
  </el-card>
</template>

<script>
import * as xlsx from 'xlsx';

export default {
  data() {
    return {
      data: {},
      khMaps: {},
    };
  },
  created() {
    this.getSKUs();
  },
  methods: {
    getSKUs() {
      this.axios.get('allkhclothes').then((r) => {
        const list = r.data;
        this.khMaps = list;
      });
    },
    // options { khCol款号对应列字母， colorCol 颜色对应列字母，sizeArr 数组[{size: size, in: 出库数量对应列字母, out: 销售数量对应列字母 }, ...] }
    getSheetDatas(workSheet, sheetName, did, options) {
      const objArrs = []; // 获取的内容
      const { khCol, colorCol, sizeArr } = options;
      const list = xlsx.utils.sheet_to_json(workSheet.Sheets[sheetName], { range: 3, header: 'A' });
      let lastKh = null;
      let row = 3;
      const errs = [];
      list.forEach((item) => {
        row++;
        let kh = item[khCol] ? item[khCol] : lastKh;
        kh = String(kh);
        kh = kh.toUpperCase();
        const colorMaps = this.khMaps[kh];
        if (colorMaps) {
          if (item[colorCol]) {
            const color = item[colorCol];
            const sizeMaps = colorMaps[color];
            if (sizeMaps) {
              sizeArr.forEach((i) => {
                const { size } = i;
                let sizeList = sizeMaps[size];
                if (!sizeList) {
                  if (size === 'S' || size === 'M') {
                    sizeList = sizeMaps['S/M'];
                  }
                  if (size === 'L') {
                    sizeList = sizeMaps['M/L'];
                  }
                }
                if (sizeList) {
                  const obj = sizeList[0];
                  if (item[i.in] > 0) {
                    const num = item[i.in] * 1;
                    const inObj = { ...obj, type: 'in', num, did };
                    objArrs.push(inObj);
                  }
                  if (item[i.out] > 0) {
                    const num = 0 - item[i.out];
                    const outObj = { ...obj, type: 'out', num, did };
                    objArrs.push(outObj);
                  }
                } else if (item[i.in] > 0 || item[i.out] > 0) { // 有数据但没有对应
                  errs.push({ row, kh, color, size: i.size });
                }
              });
            } else {
              errs.push({ row, kh, color });
            }
          }
        } else {
          errs.push({ row, kh });
        }
        lastKh = kh;
      });
      console.log('errs', errs);
      return objArrs;
    },
    onChange(e) {
      this.sizeWarning = false;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const wb = xlsx.read(data, { type: 'binary' });
        // // 晨风
        // const opts = {
        //   khCol: 'B',
        //   colorCol: 'E',
        //   sizeArr: [
        //     { size: 'S', in: 'F', out: 'M' },
        //     { size: 'M', in: 'G', out: 'N' },
        //     { size: 'L', in: 'H', out: 'O' },
        //     { size: 'XL', in: 'I', out: 'P' },
        //     { size: 'XXL', in: 'J', out: 'Q' },
        //     { size: 'F', in: 'K', out: 'R' },
        //   ],
        // };
        // const sheetName = wb.SheetNames[0];
        // const arr = this.getSheetDatas(wb, sheetName, 1, opts);
        // this.axios.post('distributor/1/init', { list: arr }).then(() => {
        // }, (e) => {
        //   this.$utils.resp(e);
        // });

        // 乘除
        // const opts2 = {
        //   khCol: 'A',
        //   colorCol: 'C',
        //   sizeArr: [
        //     { size: 'S', in: 'D', out: 'I' },
        //     { size: 'M', in: 'E', out: 'J' },
        //     { size: 'L', in: 'F', out: 'K' },
        //     { size: 'F', in: 'G', out: 'L' },
        //   ],
        // };
        // const sheetName2 = wb.SheetNames[1];
        // const arr2 = this.getSheetDatas(wb, sheetName2, 2, opts2);
        // this.axios.post('distributor/2/init', { list: arr2 }).then(() => {
        // }, (e) => {
        //   this.$utils.resp(e);
        // });

        // // ABC苏州
        // const opts3 = {
        //   khCol: 'A',
        //   colorCol: 'C',
        //   sizeArr: [
        //     { size: 'S', in: 'D', out: 'J' },
        //     { size: 'M', in: 'E', out: 'K' },
        //     { size: 'L', in: 'F', out: 'L' },
        //     { size: 'XL', in: 'G', out: 'M' },
        //     { size: 'F', in: 'H', out: 'N' },
        //   ],
        // };
        // const sheetName3 = wb.SheetNames[2];
        // const arr3 = this.getSheetDatas(wb, sheetName3, 3, opts3);
        // this.axios.post('distributor/3/init', { list: arr3 }).then(() => {
        // }, (e) => {
        //   this.$utils.resp(e);
        // });

        // // ABC沈阳
        // const opts4 = {
        //   khCol: 'A',
        //   colorCol: 'C',
        //   sizeArr: [
        //     { size: 'S', in: 'D', out: 'J', back: 'P' },
        //     { size: 'M', in: 'E', out: 'K', back: 'Q' },
        //     { size: 'L', in: 'F', out: 'L', back: 'R' },
        //     { size: 'XL', in: 'G', out: 'M', back: 'S' },
        //     { size: 'F', in: 'H', out: 'N', back: 'T' },
        //   ],
        // };
        // const sheetName4 = wb.SheetNames[3];
        // const arr4 = this.getSheetDatas(wb, sheetName4, 4, opts4);
        // console.log(arr4);
        // this.axios.post('distributor/4/init', { list: arr4 }).then(() => {
        // }, (e) => {
        //   this.$utils.resp(e);
        // });

        // // 遇陆
        // const opts5 = {
        //   khCol: 'A',
        //   colorCol: 'C',
        //   sizeArr: [
        //     { size: 'S', in: 'D', out: 'J', back: 'P' },
        //     { size: 'M', in: 'E', out: 'K', back: 'Q' },
        //     { size: 'L', in: 'F', out: 'L', back: 'R' },
        //     { size: 'XL', in: 'G', out: 'M' },
        //     { size: 'F', in: 'H', out: 'N', back: 'S' },
        //   ],
        // };
        // const sheetName5 = wb.SheetNames[4];
        // const arr5 = this.getSheetDatas(wb, sheetName5, 5, opts5);
        // console.log(arr5);
        // this.axios.post('distributor/5/init', { list: arr5 }).then(() => {
        // }, (e) => {
        //   this.$utils.resp(e);
        // });

        // 麓隐生活
        const opts6 = {
          khCol: 'A',
          colorCol: 'C',
          sizeArr: [
            { size: 'XS', in: 'D', out: 'J', back: 'P' },
            { size: 'S', in: 'E', out: 'K', back: 'Q' },
            { size: 'M', in: 'F', out: 'L', back: 'R' },
            { size: 'L', in: 'G', out: 'M', back: 'S' },
            { size: 'F', in: 'H', out: 'N', back: 'T' },
          ],
        };
        const sheetName6 = wb.SheetNames[5];
        const arr6 = this.getSheetDatas(wb, sheetName6, 6, opts6);
        console.log(arr6);
        this.axios.post('distributor/6/init', { list: arr6 }).then(() => {
        }, (e) => {
          this.$utils.resp(e);
        });
      };
      fileReader.readAsBinaryString(e.target.files[0]);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>
