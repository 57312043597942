<template>
<div class="full-screen">
  <div class="login-wrap login-width">
    <div class="login-title text-center">
      <div class="login-title-name">管理平台</div>
      <div class="login-title-description text-center pad-top mar-btm text-muted">资产管理平台</div>
    </div>
    <div class="login-content">
      <el-form :model="loginFormData" @keyup.enter.native="onSubmit">
        <el-form-item class="mar-btm">
          <el-input v-model="loginFormData.login_name" placeholder="登录用户名/手机"
            :disabled="loading" ref="login_name" autoComplete="on">
            <template slot="append"><i class="fa fa-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item class="mar-btm">
          <el-input type="password" v-model="loginFormData.password" placeholder="登录密码"
            :disabled="loading" ref="password">
            <template slot="append"><i class="fa fa-lock"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item class="mar-btm">
          <!-- <el-checkbox class="pull-left" v-model="loginFormData.remember">记住登录状态</el-checkbox> -->
        </el-form-item>
        <el-form-item class="mar-btm">
          <el-button class="login-button" type="primary" @click="onSubmit" :disabled="isValidated"
            v-loading="loading">登 录</el-button>
        </el-form-item>
      </el-form>

      <!-- <div class="login-addition pad-ver">
        <div class="text-center">
          <el-button type="text" @click="hintForgetPassword">忘记密码</el-button>
          <el-button type="text" @click="showRegister=true">注册新用户</el-button>
        </div>
      </div> -->
    </div>
  </div>

  <el-dialog v-model="showRegister" size="tiny" :show-close="false">
    <div class="text-center">
      <img src="/static/app-qr.png" />
      <div>请使用手机App注册用户</div>
    </div>
    <div slot="footer" class="dialog-footer text-center">
      <el-button type="primary" @click="showRegister=false">确定</el-button>
    </div>
  </el-dialog>

  <div class="text-center mar-top text-muted">
     &copy; <a href="https://beian.miit.gov.cn">苏ICP备18042792号</a>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
// import * as xlsx from 'xlsx';

export default {
  components: {
  },
  data() {
    return {
      loginFormData: {
        login_name: '',
        password: '',
        remember: true,
      },
      showRegister: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.auth.loginLoading,
      loginData: state => state.auth.loginData,
    }),
    isValidated() {
      // disabled为false时，即为valid状态
      return !(this.loginFormData.login_name && this.loginFormData.password && !this.loading);
    },
  },
  mounted() {
    // 浏览器自动填充需要设置一下
    setTimeout(() => {
      const evt = window.document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      this.$refs.login_name.$refs.input.dispatchEvent(evt);
      this.$refs.password.$refs.input.dispatchEvent(evt);
    }, 500);
  },
  methods: {
    onSubmit() {
      if (!this.isValidated) {
        this.$nextTick(() => {
          this.$store.commit('SET_LOGIN_LOADING', { loading: true });
        });
        this.$store.dispatch('AUTH_LOGIN', {
          ...this.loginFormData,
          agent: `web || ${navigator.platform} || ${navigator.userAgent}`,
        });
      }
    },
    hintForgetPassword() {
      this.$alert('请使用手机App的找回密码功能');
    },
    // download() {
    //   const t = [
    //     '000010001~~24L树脂扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010002~~28L树脂扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010003~~16L树脂扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010004~~40L树脂扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010005~~GH-25~~~~~~0.13~~12090~~件~~0.0~~False~~0000000000~~False~~108041501~~否~~钢铁制弹簧~~~~~~',
    //     '000010006~~GASKET FLAT RD~~~~~~0.13~~121287~~件~~0.0~~False~~0000000000~~False~~109021599~~否~~其他乳品加工机械~~~~~~',
    //     '000010007~~LUGEMEI~~~~~~0.13~~119881~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010008~~IDADR~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010009~~FANGMEIGE~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010010~~男圆领套头衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010011~~男圆领套头衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010012~~XIANGLIANHUA~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010013~~YINGSHANHONG~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010014~~Safari 靠垫套~~~~~~0.13~~120886~~件~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000010015~~ZHEZHEYIN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010016~~QIQIKU~~~~~~0.13~~1206~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010017~~CVC机织布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000010018~~CVC坯布~~~~0401~~0.13~~~~米~~15.82~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010019~~CVC染色布~~~~0401~~0.13~~~~米~~15.82~~True~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010020~~CVC色织布~~~~0401~~0.13~~~~米~~15.82~~True~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000010021~~CVC色织牛津纺~~~~0401~~0.13~~~~米~~15.82~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010022~~LVTAISHAN~~~~~~0.13~~5522~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010023~~KUNIUYI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010024~~HUIRONMEI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010025~~D形环~~~~0401~~0.13~~~~只~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010026~~D型扣~~~~0401~~0.13~~~~个~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010027~~CICI-RD~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~10302020701~~否~~果冻~~~~~~',
    //     '000010028~~YUANJIEYI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010029~~FENHU~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010030~~IBMIBM RD 彩页~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~109051299~~否~~其他电子计算机配套产品及耗材~~~~~~',
    //     '000010031~~WHIWI~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010032~~DANRED~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010033~~SHCOMB~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010034~~SHANCEL~~~~~~0.13~~1213~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010035~~SHASOW~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010036~~SHOBI~~~~~~0.13~~120~~件~~0.0~~False~~0000000000~~False~~107022302~~否~~护肤用化妆品~~~~~~',
    //     '000010037~~注塑产品PIN, SKI-LIGASURE-LSD~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107060110~~否~~塑料零件~~~~~~',
    //     '000010038~~SHKALER~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010039~~SHALKING~~~~~~0.13~~件~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010040~~JIAXIU~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010041~~JIETUZI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010042~~JILV~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~10999~~否~~其他机械、设备类产品~~~~~~',
    //     '000010043~~OEC2-2M~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~1090199~~否~~其他通用设备和零件~~~~~~',
    //     '000010044~~JUSHOP-M~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000010045~~TP2M~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~108031501~~否~~铜材~~~~~~',
    //     '000010046~~KL~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000010047~~LANDALAN~~~~~~0.13~~122~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010048~~LEIBAIYI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010049~~LEIJIANTOU~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010050~~LEISIBANG~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010051~~LONGLINE~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000010052~~MAERSI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010053~~MEINIPIN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010054~~MEIWENQUN~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010055~~ME擦拭布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000010056~~MINGYUEGUANG~~~~~~0.13~~1223~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010057~~N66 滑道组件~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~109051003~~否~~网络连接设备~~~~~~',
    //     '000010058~~NOTMAO~~~~~~0.13~~122396~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010059~~PTA~~~~4001~~0.13~~~~吨~~0.0~~False~~0000000000~~False~~107021303~~否~~合成纤维单体~~~~~~',
    //     '000010060~~QIANZHEMEI~~~~~~0.13~~12202~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010061~~RCFBOLOS~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010062~~利快RELAX米色长方地垫60X100cm~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000010063~~RODO-RD~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107021101~~否~~染料~~~~~~',
    //     '000010064~~2-ME~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~10703070799~~否~~其他血液制品~~~~~~',
    //     '000010065~~GASKET FLAT RD~~~~~~0.13~~11863~~件~~0.0~~False~~0000000000~~False~~109021599~~否~~其他乳品加工机械~~~~~~',
    //     '000010066~~SHASOW~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010067~~SHCAMO~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010068~~SHENIM~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010069~~SHFLAG~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010070~~shima咬铃球SW5020~~~~~~0.13~~120801~~件~~0.0~~False~~0000000000~~False~~106040802~~否~~填充类玩具~~~~~~',
    //     '000010071~~SHOBI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107022302~~否~~护肤用化妆品~~~~~~',
    //     '000010072~~SUIHUAYI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010073~~SUSUQUN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010074~~T/C布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010075~~T/C坯布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010076~~T/C染色布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010077~~T/C绉布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010078~~T/R 65/35 棉型纱~~~~0401~~0.13~~~~米~~14.5~~True~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010079~~T/R 纱~~~~0401~~0.13~~~~吨~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010080~~T/R 纬弹布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010081~~T/R氨纶汗布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010082~~T/R布~~~~0401~~0.13~~~~米~~14.5~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010083~~T/R弹力布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010084~~T/R弹力坯布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010085~~T/R棉型纱~~~~0401~~0.13~~~~米~~14.5~~True~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010086~~T/R面料~~~~0401~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010087~~T/R坯布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010088~~T/R平纹呢~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104011204~~否~~针织钩编织物（针织坯布）~~~~~~',
    //     '000010089~~T/R染色布~~~~0401~~0.13~~~~米~~14.5~~True~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010090~~T/R色织布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010091~~T/R色织弹力布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010092~~T/R色织格子布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010093~~T/R纱~~~~0401~~0.13~~~~吨~~14.5~~True~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010094~~T/R四面弹~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010095~~T/R纬弹布~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010096~~T/R针织布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010097~~TR色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010098~~TUYAZI~~~~~~0.13~~119266~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010099~~HEPES (1 M)~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~107030710~~否~~生物制剂~~~~~~',
    //     '000010100~~V领套头衫~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010101~~套头背心~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010102~~男装套头长袖毛衫~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010103~~V领提花男套背心~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010104~~WHIWI~~~~~~0.13~~120800~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010105~~XIAOHUANGREN~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010106~~XIXIQUN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010107~~ZHEDAINIU~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010108~~ZHEDAIYI~~~~~~0.13~~120~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010109~~ZHEQUN~~~~~~0.13~~件~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010110~~ZHIDAI~~~~~~0.13~~120317~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010111~~氨纶丝~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~107040307~~否~~氨纶纤维~~~~~~',
    //     '000010112~~按钮~~~~0401~~0.13~~~~副~~0.0~~False~~0000000000~~False~~1090299~~否~~其他专用设备及零部件~~~~~~',
    //     '000010113~~澳毛条~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010114~~巴厘纱~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010115~~白色弹力府绸~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010116~~半开襟立领套头衫~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010117~~背带裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010118~~男变化条纹圆领长袖毛衫~~II~~0401~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010119~~标牌~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000010120~~标牌贴纸~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~106020103~~否~~广告宣传印刷品~~~~~~',
    //     '000010121~~玻璃珠~~~~0401~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~108011403~~否~~室内及室内装饰用玻璃器具~~~~~~',
    //     '000010122~~布~~~~0401~~0.13~~~~米~~25.025~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010123~~布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010124~~布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010125~~布料~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010126~~衬~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010127~~衬布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000010128~~衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010129~~衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010130~~衬衫~~~~0601~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010131~~成衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010132~~打样费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010133~~大衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010134~~涤纶PTT~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010135~~涤纶弹力面料~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010136~~涤纶混纺染色布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010137~~涤纶里布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010138~~涤纶染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010139~~涤纶梭织布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010140~~涤丝纺~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010141~~涤塔夫~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010143~~涤粘染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010144~~涤粘色织布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010145~~风衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010146~~服装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010148~~格子毛呢~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000010149~~女式连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010150~~横机罗纹~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104011204~~否~~针织钩编织物（针织坯布）~~~~~~',
    //     '000010151~~花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000010152~~化纤布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000010153~~化纤混纺针织布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010154~~化纤制女式背心~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010155~~化纤制女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010156~~化纤制女式防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010157~~混纺纱~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010158~~加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010159~~胶袋~~~~0401~~0.13~~~~件~~0.52~~True~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000010160~~胶水袋~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~106040109~~否~~胶水~~~~~~',
    //     '000010161~~腈纶短纤~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000010162~~腈纶纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010163~~腈纶条~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010164~~腈纶粘胶纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010165~~腈纶针织面料~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000010166~~精短毛~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010167~~酒~~~~~~0.13~~~~瓶~~0.0~~False~~0000000000~~False~~1030306~~否~~其他酒~~~~~~',
    //     '000010168~~裤子~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010169~~拉链~~~~0401~~0.13~~~~条~~0.76923~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000010170~~蕾丝面料~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010171~~里布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010172~~里料~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010173~~连衣裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010174~~连衣裙~~~~0601~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010175~~连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010176~~连衣裙~~~~~~0.13~~~~件~~0.0~~True~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010177~~连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010178~~连衣裙（梭织）~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010179~~拎包~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000010180~~罗纹~~~~0401~~0.13~~~~米~~46.0~~True~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000010181~~毛衫类~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010182~~毛纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010183~~毛条~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010184~~帽子~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000010185~~绵羊绒~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010186~~棉布~~~~~~0.13~~~~米~~0.0~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010188~~棉弹力染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010189~~棉弹力印花布~~~~~~0.13~~~~~~0.0~~True~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010190~~棉涤弹力布~~~~~~0.13~~~~~~0.0~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010192~~棉点~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104011307~~否~~针织手套~~~~~~',
    //     '000010193~~棉类~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010195~~棉纱~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010196~~棉绳~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010197~~棉梭织布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010198~~棉条~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010199~~面辅料~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~107060299~~否~~其他塑料半成品、辅料~~~~~~',
    //     '000010200~~面料~~~~0101~~0.13~~~~米~~12.3~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010201~~莫代尔~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010202~~尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000010203~~尼龙短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000010204~~钮扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010205~~女衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010206~~女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010207~~女式机织背带裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010208~~女式机织衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010209~~女式机织大衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010210~~女式机织吊带衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010211~~女式机织短裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010212~~女式机织防风夹克~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010213~~女式护胸背带裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010214~~女式机织九分裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010215~~女式机织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010216~~女式机织连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010217~~女式机织马甲~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010218~~女式机织裙子~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010219~~女式机织外套式衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010220~~女式机织无袖罩衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010221~~女式机织休闲西服上衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010222~~女式机织长裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010223~~女式机织罩衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010224~~女式裤子~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010225~~女式棉服~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010226~~女式牛仔裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010227~~女式外套~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010228~~女式胸针~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000010229~~女式羽绒服~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010230~~女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010231~~女式针织T恤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010232~~女式针织背心~~~~~~0.13~~GTN001008~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010233~~女式针织短裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010234~~女式针织连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010235~~女式针织毛绒衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010236~~女式针织七分裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010237~~女式针织裙子~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010238~~女式针织套衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010239~~女式针织无袖套衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010240~~女式针织无袖罩衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010241~~女式针织长裤~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010242~~女童机织防风夹克~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010243~~女童针织T恤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010244~~女童针织短裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010245~~女童针织连衣裙~~~~~~0.13~~GDR006563~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010246~~女装梭织衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010247~~坯布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010248~~坯布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010249~~汽车~~~~~~0.13~~~~台~~0.0~~False~~0000000000~~False~~10903050101~~否~~乘用车（排气量在1.0升以下（含））~~~~~~',
    //     '000010250~~春亚纺面料~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010251~~全涤染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010252~~全涤提花面料~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010253~~全涤提花面料~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010254~~全腈纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010255~~全毛纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010256~~全毛纱线~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010257~~全棉染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010258~~全棉印花布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010260~~染色灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010261~~染色涤粘布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010262~~人棉布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010263~~人棉类~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010264~~人棉平布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010265~~色织布~~~~0401~~0.13~~~~米~~18.88~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010266~~纱~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010267~~纱线~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010268~~山羊绒~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010269~~食品~~~~~~0.13~~~~批~~0.0~~False~~0000000000~~False~~1030299~~否~~其他食品~~~~~~',
    //     '000010270~~食品~~~~~~0.13~~~~批~~0.0~~False~~0000000000~~False~~1030299~~否~~其他食品~~~~~~',
    //     '000010271~~手套~~~~~~0.13~~~~双~~0.0~~False~~0000000000~~False~~104011307~~否~~针织手套~~~~~~',
    //     '000010272~~水洗费~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~3070599~~否~~其他居民日常服务~~~~~~',
    //     '000010273~~四面弹春亚纺~~~~~~0.13~~~~米~~0.0~~True~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010274~~松紧~~~~~~0.13~~~~米~~0.0~~True~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000010275~~童套头衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010276~~背带裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010277~~贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010278~~外套~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010279~~网布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010280~~涡轮增压器~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~109010206~~否~~发动机零部件~~~~~~',
    //     '000010281~~洗净毛~~~~~~0.09~~~~千克~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010282~~橡胶木板材~~~~~~0.13~~~~立方米~~0.0~~False~~0000000000~~False~~105010101~~否~~普通锯材~~~~~~',
    //     '000010283~~休闲衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020118~~否~~便服套装~~~~~~',
    //     '000010284~~修身长裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010285~~雪纺~~~~0401~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010286~~羊毛条~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010287~~样布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010288~~衣架~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000010289~~印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010290~~印花连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010291~~婴儿针织T恤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010292~~婴儿针织连衣裙~~~~~~0.13~~GDR006563~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010293~~羽绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000010294~~粘胶短纤~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000010295~~粘胶纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010296~~针织布~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010297~~针织面料~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000010298~~针织面料~~~~0401~~0.13~~~~公斤~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000010299~~织带~~~~0401~~0.13~~~~公斤~~19.9~~True~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000010300~~中斜纹~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010301~~珠片~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010302~~T恤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010303~~针织衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010304~~色织大提花布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010305~~人棉绉布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010306~~QIANHUAQUN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010307~~VANNY~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010308~~DIANHUDIE~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010309~~FENZHE~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010310~~ZAINIU~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010311~~布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010312~~女式针织T恤衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010313~~花边面料~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010314~~发箍~~~~~~0.13~~~~只~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000010315~~纸箱~~~~~~0.13~~~~只~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000010316~~鹿皮绒~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010317~~人棉印花布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010318~~腰带~~~~~~0.13~~~~条~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000010319~~XIAOHUIGE~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010320~~TIAOXINGXING~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010321~~LOUJIANQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010323~~MAOBQUN~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010324~~SANCENGQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010325~~ZHOUJK~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010326~~CENGCQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010327~~XIANLEIQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010328~~ZZBAI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010329~~LEIQUNC~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010330~~LOUJIANQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010331~~HUANGCQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010332~~LEISIBQ~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010333~~GAGAMEI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010334~~GASKET FLAT RD~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~109021599~~否~~其他乳品加工机械~~~~~~',
    //     '000010335~~GASKET FLAT RD~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~109021599~~否~~其他乳品加工机械~~~~~~',
    //     '000010336~~女式休闲长裤~~~~~~0.13~~096CC1B034 4488698~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010337~~女式休闲短裤~~~~~~0.13~~096CC1C001 4488699~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010338~~棉~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000010339~~尼龙单纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010340~~衬衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010341~~男式棉服~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010342~~裤袜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011306~~否~~针织袜~~~~~~',
    //     '000010343~~粘胶~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000010344~~春亚纺里料~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010345~~全棉布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010346~~绣花布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010348~~涤粘弹力布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010349~~染色针织面料~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010350~~披肩~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000010351~~马油~~~~~~0.13~~~~瓶~~0.0~~False~~0000000000~~False~~103010704~~否~~动物油脂及加工制品~~~~~~',
    //     '000010352~~女式针织大衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010353~~棉质女式衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010354~~化纤制女式连身裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010355~~化纤制女式衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010356~~棉制女式衬衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010357~~金银丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040403~~否~~涤纶加工丝~~~~~~',
    //     '000010358~~腈纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000010359~~合纤制女式上衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010360~~烫钻~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~109020304~~否~~工程钻机~~~~~~',
    //     '000010361~~羽毛纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010362~~女式针织连体裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010363~~围巾~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011401~~否~~围巾类~~~~~~',
    //     '000010364~~T恤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010365~~LVYE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107030710~~否~~生物制剂~~~~~~',
    //     '000010366~~布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010367~~拉毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010369~~MIANJIEYI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000010370~~HUAHONG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010371~~TIAOTIAOLONG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~~~~~~~~~~~',
    //     '000010372~~BEISHATIAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010401~~否~~蚕丝~~~~~~',
    //     '000010374~~ZHAOSHADUAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010375~~HONGTIAOTIAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010376~~羊毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010377~~色织针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010378~~纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010379~~样品费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010380~~尼龙色纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010381~~锦纶丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040402~~否~~锦纶加工丝~~~~~~',
    //     '000010382~~棉弹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010383~~化纤针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010384~~商标~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010385~~面料（码）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010386~~珠片绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010387~~面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010388~~面料(码)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010389~~化纤珠片布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000010390~~女式机织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010391~~女式护胸背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010392~~女式机织无袖罩衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010393~~合纤制女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010394~~化纤制女式套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010395~~化纤制女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010396~~化纤制女式衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010397~~女式机织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010398~~女式机织防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010399~~女式针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010401~~女式机织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010402~~女式机织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010403~~女式针织吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010406~~FASHIONDR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010407~~HUIHUIKU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010408~~MEITIAOQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010409~~橡胶木板材(立方米)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010101~~否~~普通锯材~~~~~~',
    //     '000010410~~女式休闲上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010411~~羊毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010412~~汽车(台)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~202~~否~~修理修配劳务~~~~~~',
    //     '000010413~~爱美丽订购~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000010414~~连衣裤(件)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010415~~绣花片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000010416~~棉腈混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010417~~纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010418~~尼龙单纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010419~~粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010420~~锦纶针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010421~~房租~~~~~~0.05~~~~~~0.0~~False~~0000000000~~False~~3040502020199~~否~~其他住房租赁服务~~~~~~',
    //     '000010422~~GARLIEDEV~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010423~~JIAXIUER~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010424~~混纺毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000010425~~化纤里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010426~~棉麻条子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010427~~针织呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010429~~女式机织休闲西服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010430~~女式机织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010431~~女式机织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010432~~女式机织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010433~~女式针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010434~~女式机织七分裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010435~~女式针织开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010436~~男士针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020110~~否~~男Ｔ恤衫~~~~~~',
    //     '000010437~~女式针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010438~~女式针织毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010439~~男式针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020110~~否~~男Ｔ恤衫~~~~~~',
    //     '000010440~~棉晴混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010441~~并线加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010442~~全棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010443~~色织全棉斜纹机织物~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010444~~羽毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010446~~带子纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000010447~~床头柜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010448~~衣柜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010449~~妆台~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020199~~否~~其他家具~~~~~~',
    //     '000010450~~茶几~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010451~~饰物柜~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010452~~地柜~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010453~~化纤制女式开襟衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010454~~化纤制女式梭织马甲~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010455~~女式连衣裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010456~~合纤制女式梭织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010457~~棉制婴儿连衣裙~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010458~~棉制婴儿尿布~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104011306~~否~~针织袜~~~~~~',
    //     '000010459~~化纤制女式梭织背心~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010460~~女式短裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010462~~女式机织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010463~~E-女短袖梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010464~~发箍~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000010465~~机织婴儿大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010466~~女童机织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010467~~针织罗马~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010468~~针织罗马布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010469~~针织T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000010470~~女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010471~~合纤制女式针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010472~~化纤制女式钩编吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010473~~化纤制女式针织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010474~~棉制女式套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010475~~合纤制女式针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010476~~全身印花衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010477~~染料助剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021406~~否~~纺织工业用整理剂、助剂~~~~~~',
    //     '000010478~~腈纶冰岛毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010479~~腈纶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010480~~女士夹克~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010481~~女式机织开襟罩衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010483~~PU短靴(千克)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020401~~否~~皮革鞋靴~~~~~~',
    //     '000010484~~女式针织短背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010485~~针织裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010486~~棉涤针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010487~~面料（米）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050604~~否~~手工染织工艺品及机织工艺品~~~~~~',
    //     '000010488~~皮袢~~~~~~0.13~~~~付~~0.0~~False~~0000000000~~False~~104020401~~否~~皮革鞋靴~~~~~~',
    //     '000010489~~布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010490~~布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010491~~人纤制女式梭织连衣裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010493~~40S/2+40D~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010494~~维亭30s~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~103020899~~否~~其他营养、保健食品~~~~~~',
    //     '000010495~~2/30S棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010496~~40S/2+40D~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010497~~粘胶色纺纱2/30s~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010498~~粘胶纱2/30s~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010499~~粘胶纱2/40s~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010500~~粘胶色纺纱2/40s~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010501~~涤纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000010502~~MXDQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010503~~女式针织防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010504~~标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010505~~女士外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010506~~合纤制女式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010507~~男式机织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020117~~否~~男大衣、男风衣~~~~~~',
    //     '000010508~~男式机织七分裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010509~~男式机织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010510~~男式针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010512~~女式机织紧身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010513~~连衣裤（针织）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010514~~女式半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010515~~棉涤色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010516~~棉制婴儿衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010517~~棉制男童衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010518~~压褶费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010519~~线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010520~~涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010521~~领头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050299~~否~~其他金属工艺品~~~~~~',
    //     '000010523~~童套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010524~~男童针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010525~~婴儿针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010526~~女童针织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010527~~女童针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010528~~女童针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010529~~女式针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010530~~STRIPLACING~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010531~~MEETYOUDR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010532~~DINGDANGQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010533~~NIUZAILIUSU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010534~~毛制女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010535~~化纤制女式针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000010536~~豹纹提花连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010537~~COMYA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010538~~DRANO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010539~~DREVA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010540~~shooter系列~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109013103~~否~~中央空调冷水/热泵机组~~~~~~',
    //     '000010541~~NAVYJ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010542~~LONGKU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010543~~NBA ifree 蓝牙便携防水音响（蓝）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051906~~否~~电声器件~~~~~~',
    //     '000010544~~FB-ZAAR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010545~~FB-FABU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010546~~2寸CACA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109012303~~否~~轴承零配件~~~~~~',
    //     '000010547~~WUXIU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010548~~WEIJIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000010549~~SUIHUAER~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010550~~QIANGE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010551~~ONSHU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010552~~FB-TUBAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010553~~LUOCHANGYI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010554~~天丝布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010555~~双面呢大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020202~~否~~非天然皮革服装~~~~~~',
    //     '000010556~~女式衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010557~~棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000010558~~棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000010559~~RIBENHUA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010560~~涤棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010561~~粗纱条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080116~~否~~玻璃纤维及其制品~~~~~~',
    //     '000010562~~粘胶短纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010563~~蕾丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010564~~男式机织防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010565~~纯棉机织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010566~~2/39NM 100%超细羊毛（羊毛规格为19.5微米）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050702~~否~~机制地毯、挂毯~~~~~~',
    //     '000010567~~红酒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000010568~~男裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010569~~绣花费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010570~~棉涤双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010571~~HONGHUAQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010572~~FANGFANGMEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010573~~LEISIW~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010574~~RUFFLESK~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010575~~TINYDR-L~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107030301~~否~~植物类饮片~~~~~~',
    //     '000010576~~YINGLVQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010577~~PUNIU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010578~~短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010579~~LVQQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010580~~CUTEJA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010581~~STITCHJA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010582~~CRINKLEJK~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010583~~LTGREEN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010584~~毛腈纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010585~~ELKADR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010586~~女童针织防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010587~~女童针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010588~~T恤(件)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010589~~标唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000010591~~染色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010592~~女式半截裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010593~~天丝面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010594~~全麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010595~~全麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010304~~否~~亚麻印染布~~~~~~',
    //     '000010596~~男式防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010597~~派克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010598~~GEGE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021105~~否~~塑料用模具~~~~~~',
    //     '000010599~~YAYINMEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010600~~RIVET~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032404~~否~~航空器零件~~~~~~',
    //     '000010601~~RD-43~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000010602~~LANJINLIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010603~~CHECKGIRL~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010604~~LONGHOOD~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010605~~GREENBREATH~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010606~~NIUJIEXIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010607~~ZHOUHEITIAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010608~~YUANLINGSHAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010609~~HUIHUABIAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010610~~MAOQUANQUAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010611~~KUOXIUDAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010612~~TIHUAYI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010613~~CAICQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010614~~HONGLQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010615~~小缸费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010616~~棉锦弹梭织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010617~~机织婴儿长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010618~~男童机织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010619~~围裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000010620~~合纤制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010621~~人纤制女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010622~~餐椅~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020101~~否~~木质家具~~~~~~',
    //     '000010623~~沙发~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020106~~否~~软体家具~~~~~~',
    //     '000010624~~牛皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030201~~否~~成品革~~~~~~',
    //     '000010625~~女长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010626~~男西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000010627~~男长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010628~~人纤制女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010629~~涤纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000010630~~FB-CUTESK~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010631~~LINGQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010632~~网片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041301~~否~~钢铁丝制品~~~~~~',
    //     '000010633~~毛毡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010701~~否~~毡呢~~~~~~',
    //     '000010634~~羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010635~~涤纶中空纤维~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000010636~~别克GL8~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~10903050104~~否~~乘用车（排气量在2.0升以上至2.5升（含））~~~~~~',
    //     '000010637~~涤纶弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010638~~修色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010639~~衬衫(件)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010640~~粘胶色纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010641~~粘胶色纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010642~~化纤制女式梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010643~~棉制女式梭织裙子 LADIES WOVEN SKIRTS~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010644~~针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010645~~代理费~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~304080299~~否~~其他经纪代理服务~~~~~~',
    //     '000010646~~牛肉产品~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070101~~否~~猪、牛、羊、鸡、鸭、鹅鲜、冷、冻肉~~~~~~',
    //     '000010647~~女式针织防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010648~~助剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021408~~否~~制革工业用整理剂、助剂~~~~~~',
    //     '000010649~~舒棉绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000010650~~绗缝面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010652~~GREYSTRIPE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010653~~MIANBAIQ~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010654~~LIANGJU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010655~~WANGYOUCAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010656~~FB-15~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010657~~GREENTRY~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010658~~SHOMY~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010659~~JATIGER~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010660~~JAMAX~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010661~~SHECEL~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010662~~FB-MANGGUO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010663~~SHILAR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010664~~DISK S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1090313~~否~~摩托车零部件及配件~~~~~~',
    //     '000010665~~DAKONGLONG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010666~~SHUNVLAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010667~~合纤制女式梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010668~~合纤制女式针织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010669~~棉制女式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010670~~合纤制女童梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010671~~化纤制男童针织背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010672~~合纤制女式针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010673~~棉制女童梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010674~~HEYEBEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010675~~KUOKUOKU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010676~~YANBOCHANG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010677~~皮带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000010678~~男式针织毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020121~~否~~男毛衣~~~~~~',
    //     '000010679~~梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010680~~女式针织背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010681~~女式针织开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010682~~人棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010683~~化纤制女式梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000010684~~棉制女式梭织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010685~~高丝宝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000010686~~女式针织连体紧身衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010687~~女式半截裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010688~~绢丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010401~~否~~蚕丝~~~~~~',
    //     '000010689~~女式机织背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010690~~人棉坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010691~~罗纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000010692~~服装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010693~~波纹纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010694~~大肚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010695~~棉涤纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010696~~毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010697~~头带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040699~~否~~其他体育用品~~~~~~',
    //     '000010698~~连裤袜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011306~~否~~针织袜~~~~~~',
    //     '000010699~~人字带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000010700~~毡帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000010701~~锦粘弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010702~~化纤制女式防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010703~~女童针织开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010704~~人棉针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010705~~防盗标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010706~~印花费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000010707~~牛肉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10301070101~~否~~猪、牛、羊、鸡、鸭、鹅鲜、冷、冻肉~~~~~~',
    //     '000010708~~TR弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010709~~CHOUZHEYAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010710~~LANXIUHUA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010711~~XIANGTIAOHEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010712~~JIELU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010713~~HAHAHEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010714~~TIAOTIAOLAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010715~~TUZIZI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010716~~HAIZAOLV~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010717~~毛圈布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010718~~露肩连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010719~~FB-BOLOS~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010720~~梭织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010721~~XINJINXUEMEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010722~~FB-WARMHOOD~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010723~~CHENGSHIQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010724~~PURPLEIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010725~~全棉坯纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010726~~婴儿针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010727~~卡片夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '000010728~~儿童毛衫/针织︱套头衫︱男式︱80%MOHAIR,15%WOOL,53%ACRYLIC等︱MQD︱无货号~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000010729~~GREENRED~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010730~~吊带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010731~~涤纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000010732~~VLINGBEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010733~~毡帽(个)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000010734~~全麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010735~~试制费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~3040101~~否~~研发服务~~~~~~',
    //     '000010736~~棉弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010737~~涤纶针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010738~~马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010739~~女式连衫裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010740~~棉制梭织婴儿裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010741~~合纤制梭织婴儿裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010742~~合纤制梭织婴儿短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010743~~羽绒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000010744~~长风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020117~~否~~男大衣、男风衣~~~~~~',
    //     '000010745~~人纤制女式长裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010746~~合纤制女式短裤~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010747~~女童机织防寒上衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010748~~涤纶弹力针织染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010749~~上衣~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010750~~女装~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010751~~女式裙子~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010752~~FANGLINGTIAO~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010753~~TUYABAI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010754~~GEHONGZI~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010755~~化纤制女式针织背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010756~~棉制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010757~~AIAITIAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010758~~XIUTIAOHUA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010759~~HUANQIUGANG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010760~~HONGWAWA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010761~~YUEER~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010762~~YINHEXI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010763~~布水洗~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000010764~~涤纶针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010765~~吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010766~~尼龙羽毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010767~~主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000010768~~尼龙拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000010769~~男式针织防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010770~~麻棉印花布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010304~~否~~亚麻印染布~~~~~~',
    //     '000010771~~绣花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000010772~~纸制编结帽子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000010773~~TIAOWENMI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010774~~DABULIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010775~~涤粘弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010776~~印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010777~~人纤制女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010778~~化纤制女式针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010779~~人棉弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010780~~FEIQILAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010781~~CAMCAL~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010782~~WHATEVER~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051299~~否~~其他电子计算机配套产品及耗材~~~~~~',
    //     '000010783~~棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000010784~~PIAOXINGHUI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010785~~棉腈纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010786~~混纺色纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010787~~化纤制梭织领带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011402~~否~~领带~~~~~~',
    //     '000010788~~拉维德龙干白~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000010789~~人棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010790~~裤钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010791~~棉胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010792~~女式针织文胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000010793~~FB-9B~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109011504~~否~~电梯自动扶梯及升降机专用配套件~~~~~~',
    //     '000010794~~CABLE TIE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040806~~否~~配电或电器控制设备专用零件~~~~~~',
    //     '000010795~~ZHEDAIDUAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010796~~XIANJIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010797~~LANDAIDR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010798~~QUNBIANXIAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010799~~BAIHEBIAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010800~~YOUNAQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010801~~GULANJING~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010802~~HEBIANDUAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010803~~XIAOHUABEI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010804~~WEIWEIJUAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010805~~TR弹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010806~~防缩羊毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010807~~YUANLINGLAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010808~~ZHOUHEIDEV~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010809~~水枪玩具-DINO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040806~~否~~塑胶玩具~~~~~~',
    //     '000010810~~腈纶尼龙氨纶混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010811~~合纤制针织婴儿裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010812~~合纤制针织婴儿发箍~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010813~~棉制女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010814~~棉质女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010815~~女童风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010816~~牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010817~~混纺纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010818~~棉涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010819~~粘胶涤纶尼龙混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010820~~粘胶涤纶尼龙混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010821~~棉涤染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010822~~女式机织短背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010823~~机织婴儿短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010824~~男童机织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010825~~男童针织背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010826~~机织婴儿背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010827~~全棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010828~~粘棉混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010829~~女式针织休闲上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010830~~TIAOTIAOZHE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010831~~涤黏针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010832~~机织婴儿背带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010833~~腈纶尼龙氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010834~~男童针织背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010835~~OSB素板OO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010399~~否~~其他人造板~~~~~~',
    //     '000010836~~MEIMEILV~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010837~~XIAOGEGE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010838~~PUFUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010839~~FB-15~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010840~~FB-HUABAOYAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010841~~LAYERIN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010842~~HUIHUIBAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010843~~ZHEDAIDUAN工服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010844~~JIAOERQUN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010845~~SONA~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000010846~~NVNIUZAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010847~~女式机织休闲上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010848~~麂皮绒复合羊羔绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000010850~~胚布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000010851~~棉制女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010852~~化纤制女式针织连身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010853~~BOWTIE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000010854~~BLUESOM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010855~~FLOSSY~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010856~~WABAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010857~~嵌条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050601~~否~~橡胶密封件~~~~~~',
    //     '000010858~~ZOUTIANQIAO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010859~~LANTIAOBAI~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010860~~HUANPEICHANG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010861~~FENDENGLONG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010862~~HONGDENGLONG~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010863~~JIUSINIAN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010864~~OSB素板OO~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010399~~否~~其他人造板~~~~~~',
    //     '000010865~~涤纶粘胶混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010866~~涤纶粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010867~~全棉竹节纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010868~~混纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010869~~化纤制梭织女式背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010870~~棉制女式针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010871~~化纤制女式针织开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010872~~白粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010873~~黑粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000010874~~有色粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010875~~缝纫线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010104~~否~~缝纫线~~~~~~',
    //     '000010876~~女式针织罩衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010877~~金属牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032702~~否~~金属制地名牌、号码及类似标志~~~~~~',
    //     '000010878~~XIANGTIAOHEI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010879~~LANXIUHUA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010880~~ZEGNA衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010881~~MEISISI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010882~~GREYEE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010883~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010884~~DENGXINDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010885~~PAOJIEXIU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010886~~100A 2/28NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010887~~100P~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109010201~~否~~汽车用发动机~~~~~~',
    //     '000010888~~2/28NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010889~~REDFASHION-毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010890~~ZIOZIA裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010891~~YELOWWIN-外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010892~~YINER裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010893~~HUAERHUI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010894~~TINYDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010895~~超短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010504~~否~~卫生用纸制品~~~~~~',
    //     '000010896~~绳子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '000010897~~腰封~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000010898~~女士短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010899~~连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010900~~女士连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010901~~其他材料制女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010902~~其他材料制女式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010903~~背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010904~~婴儿针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010905~~涤纶丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000010906~~ZIOZIA裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010907~~YOUNAQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010908~~PIAOXINGHUI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010909~~女式腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1040301~~否~~半成品革~~~~~~',
    //     '000010910~~丝绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010911~~拉维德龙干红~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000010912~~化纤制女式梭织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010913~~毛涤色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010914~~XINVLING-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010915~~四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000010916~~JUDIAODAIKID-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010917~~JUDIAODAI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010918~~XIONXION-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010919~~QINGCI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010920~~HONGIRL-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010921~~15W/40~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109042409~~否~~电光源、灯具及照明装置零件~~~~~~',
    //     '000010922~~R 30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010923~~77A 23P 1/2.7NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010924~~2/28NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000010925~~棉羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000010926~~AGE-半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010927~~WULANKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010928~~WEIWEIJUAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010929~~JAC外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010930~~GULANJING-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010931~~女式束腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010932~~装饰腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000010933~~BISLE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010934~~YAOFENQI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010935~~STEREO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010936~~BLACKIE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010937~~YELCITY-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010938~~GUYE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010939~~KAIFEN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010940~~FB-BOLOS-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010941~~ZHOUHEIDEV-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010942~~QIONGHUA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010943~~SHENLE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010944~~SHENLEIKIDS-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010945~~NIUZAIJU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010946~~100R 2/30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010947~~60C 30N 10W 2/28NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000010949~~100N~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010601~~否~~陶瓷砖~~~~~~',
    //     '000010950~~50A SPT~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1090299~~否~~其他专用设备及零部件~~~~~~',
    //     '000010951~~MINIKISS-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010952~~森马服装~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010953~~DUOERSANKIDS-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010954~~DUOERSHAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010955~~YUANLINGLAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010956~~100R 2/30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010957~~气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000010958~~绣花花边面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000010959~~人造棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010960~~羊毛色条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000010961~~斜纹仿记忆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000010962~~牛津纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000010963~~3P 60A~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040703~~否~~电路连接装置~~~~~~',
    //     '000010964~~男式西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000010965~~40S/150M/50JC纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010966~~58A 42P 1/12NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051908~~否~~电子元件、组件零件~~~~~~',
    //     '000010967~~PU皮革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000010968~~机织婴儿风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010969~~100C 2/32S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010970~~72A 25N 3SP 1/13NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010971~~100A~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040301~~否~~金属切削机床用切削刀具~~~~~~',
    //     '000010972~~包芯纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010973~~51棉 30腈纶 19涤纶 1/16.5NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010974~~BAIGARLIE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000010975~~70C 30R 2/30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000010976~~NICEHOLE-毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '000010977~~防盗扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109025311~~否~~防盗、防火报警器及类似装置~~~~~~',
    //     '000010978~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010979~~PINKYGIRL-外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020202~~否~~非天然皮革服装~~~~~~',
    //     '000010980~~女童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010981~~自封袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000010982~~女婴上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000010983~~化纤制女式梭织连衣裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010984~~运费、~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~304040903~~否~~派送服务~~~~~~',
    //     '000010985~~摇粒绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000010986~~服装打缆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000010987~~女式机织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000010988~~FINITY裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000010989~~AD裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010990~~ZIOZIA裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000010991~~ADIDAS裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000010992~~BAIQIANDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010993~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010994~~KUIHUADR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010995~~JAC连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010996~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010997~~MESHSTRIP-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010998~~BLUEFLOWER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000010999~~垫肩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000011001~~皮革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030202~~否~~再生皮革~~~~~~',
    //     '000011002~~橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000011003~~ZIOZIA裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011004~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011005~~NIUTAOQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011006~~横机领~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022813~~否~~针织机械~~~~~~',
    //     '000011007~~LALALAND-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011008~~羊驼毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000011009~~女婴短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011010~~女童短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011012~~人造革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000011013~~压印箔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010302~~否~~转印纸~~~~~~',
    //     '000011014~~皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020201~~否~~天然皮革服装~~~~~~',
    //     '000011015~~DN40 25P 减压阀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022401~~否~~化学药原料药生产设备~~~~~~',
    //     '000011016~~珠片纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011017~~FB-DUOER-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011018~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011019~~JIANGHUANG-短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011020~~BAIHUALIAN-连衣裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011021~~ZHONGCHALA-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011022~~TIANYUANSAN-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011023~~CHENSHANKU-短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011024~~THREELAYER-半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011025~~52"化纤混纺针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011026~~ROSE连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011027~~ROSE连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011028~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011029~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011030~~YUANKUOJIU-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011031~~55R 30P 15C 2/36NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011032~~69A 28P 3W 1/12NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040703~~否~~电路连接装置~~~~~~',
    //     '000011033~~绣标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000011034~~修色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~202~~否~~修理修配劳务~~~~~~',
    //     '000011035~~DATIANTIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011036~~HUANPEIDUAN-短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011037~~DIANJIEDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011038~~SHAXIUDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011039~~BLUEPINK-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011040~~FINITY连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011041~~WBLUEDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011042~~HUAERHUANG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011043~~XIAOYUANDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011044~~梭织女上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011045~~梭织男上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011046~~金银线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000011047~~塑料袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011048~~女式连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011049~~女式梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011050~~短袖T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011051~~长袖T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011052~~吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011053~~卫衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011054~~女式机织百慕大式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011055~~翻箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022818~~否~~纺织机械及其辅助机械零件、附件~~~~~~',
    //     '000011056~~70C 30CA 2/322NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011057~~涤纶条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000011058~~标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011059~~价格牌和吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011060~~内钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011061~~金属拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000011062~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011063~~LANSHUIXIU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011064~~HUAGUNIANGK-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011065~~FEIWULAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011066~~HUAGUNIANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011067~~绗绣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000011068~~压褶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011069~~网眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019904~~否~~网眼薄纱，相关网眼织物~~~~~~',
    //     '000011070~~天丝灯芯绒坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011071~~织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011072~~绣花标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000011073~~210T布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011074~~210T~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011075~~色丁布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011076~~PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000011077~~口袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011078~~松紧带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011079~~吊链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '000011080~~斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011081~~金属扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011082~~圈绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000011083~~钩边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011084~~罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011085~~缎面雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011086~~PU面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011087~~JAC连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011088~~JAC连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011089~~女式短袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011090~~丝带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020407~~否~~靴鞋零件、护腿及类似品~~~~~~',
    //     '000011091~~挂件盒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000011092~~胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000011093~~尺码~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011094~~尺码条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020502~~否~~帽子附件~~~~~~',
    //     '000011095~~纸标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011096~~涤棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011097~~ZHUHEIKUO-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011098~~LEMONHUA-短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011099~~激光绣花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010502~~否~~被面~~~~~~',
    //     '000011100~~橡筋线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '000011101~~网绣花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000011102~~涤纶印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011103~~棉涤弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011104~~毛圈面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011105~~卷曲绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011106~~汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011107~~拉绒布面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011108~~单面麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011109~~标签贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011110~~螺纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108020705~~否~~钢筋~~~~~~',
    //     '000011111~~锦粘弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011112~~NAVYEMBDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011113~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011114~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011115~~QIANHUANGTAO-短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011116~~QIANCHAQUN-半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011117~~OUBIANXUE-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011118~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011119~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011120~~MENGTIANER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011121~~化纤混纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011123~~价格牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011124~~染色针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011125~~绣花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011126~~合纤制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011127~~PU皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000011128~~雪纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011129~~棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000011130~~棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000011131~~棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000011132~~商标吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011133~~织唛吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011134~~主标吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000011135~~女式针织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011136~~提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011137~~洗唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011138~~复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011139~~粘胶(千克)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020401~~否~~皮革鞋靴~~~~~~',
    //     '000011140~~2/28NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000011141~~100P~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109010201~~否~~汽车用发动机~~~~~~',
    //     '000011142~~棉锦色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011143~~涤纶面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011144~~女裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011145~~FGB94ZG129~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109012101~~否~~气动元件~~~~~~',
    //     '000011146~~蝶阀D941G-16C~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041404~~否~~其他金属紧固件~~~~~~',
    //     '000011147~~刺绣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000011148~~项链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050901~~否~~金银珠宝首饰~~~~~~',
    //     '000011149~~R60/C40纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010301~~否~~商品混凝土~~~~~~',
    //     '000011150~~C50/M50纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010301~~否~~商品混凝土~~~~~~',
    //     '000011151~~衬里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010504~~否~~卫生用纸制品~~~~~~',
    //     '000011152~~金属纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011153~~梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011154~~梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011155~~裤勾~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000011156~~亚麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011157~~涤粘针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011158~~印花罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011159~~装饰扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011160~~锦粘染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011161~~条形码~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011162~~肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000011163~~弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011164~~色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000011165~~针织毛呢面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011166~~串珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000011167~~拷贝纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010302~~否~~转印纸~~~~~~',
    //     '000011168~~网眼布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019904~~否~~网眼薄纱，相关网眼织物~~~~~~',
    //     '000011169~~压克力钻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000011170~~弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011171~~合纤制女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011172~~住房不动产租赁~~~~~~0.05~~~~~~0.0~~False~~0000000000~~False~~3070599~~否~~其他居民日常服务~~~~~~',
    //     '000011173~~毛绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011174~~丝绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011175~~人棉乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011176~~尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011177~~洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011178~~尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011179~~21S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011180~~60R 40C 2/30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011181~~60C 40R 2/30S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011182~~五爪扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011183~~灯芯绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011184~~长绒毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011185~~罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011186~~PPS纤维~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040399~~否~~其他合成纤维~~~~~~',
    //     '000011187~~71A 29P 1/12MN~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040702~~否~~低压开关、保护控制装置~~~~~~',
    //     '000011188~~40C 30N 23R 7W 2/32NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011189~~100A 2/28dmm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040407~~否~~钻孔或攻丝工具~~~~~~',
    //     '000011190~~10W RGB~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1090199~~否~~其他通用设备和零件~~~~~~',
    //     '000011191~~防霉贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021308~~否~~功能高分子材料~~~~~~',
    //     '000011192~~灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011193~~涤纶弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011194~~封箱带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022103~~否~~纸或纸板整理机械~~~~~~',
    //     '000011195~~装饰羽毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030701~~否~~加工填充用羽毛~~~~~~',
    //     '000011196~~MIAOXIAOHEI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011197~~LUOJIANGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011198~~SIJICHUN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011199~~棉制女式连身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011200~~TR面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011201~~女式机织短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011202~~混纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011203~~绗棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011204~~织唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011205~~碳带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051299~~否~~其他电子计算机配套产品及耗材~~~~~~',
    //     '000011206~~FB-NUOMI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011207~~毛球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000011208~~CONTRASTEE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011209~~FAKPINK-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011210~~FRMZA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011211~~GREEN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011212~~JUSTLU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011213~~YIJIANMEI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011214~~LANBOBO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011215~~MUXIANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011216~~涤粘针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011217~~化纤制男式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011218~~毛制男式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020117~~否~~男大衣、男风衣~~~~~~',
    //     '000011219~~挂饰品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051009~~否~~节庆庆典用品及相关娱乐用品~~~~~~',
    //     '000011220~~硬纸板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '000011221~~棉织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000011222~~毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011223~~箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032702~~否~~金属制地名牌、号码及类似标志~~~~~~',
    //     '000011224~~纸衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010399~~否~~其他加工纸~~~~~~',
    //     '000011225~~毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011226~~吊牌标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011227~~蕾丝花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000011228~~裤架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010803~~否~~木质框架，相关木制品~~~~~~',
    //     '000011229~~长毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011230~~拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011231~~袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011232~~天丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011233~~汽眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '000011234~~睫毛花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000011235~~粘锦针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011236~~30A瓷插~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040703~~否~~电路连接装置~~~~~~',
    //     '000011237~~仿铜氨~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011238~~棉粘弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011239~~锦棉弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011240~~锦粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011241~~主标/洗标/产地标/吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011242~~布标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011243~~女婴连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011244~~女式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011245~~FASHION衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011246~~面料加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011247~~衬板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010902~~否~~矿物材料制品~~~~~~',
    //     '000011248~~卡纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000011249~~男式机织防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000011250~~长丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000011251~~WUTONG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011252~~ORANLD0-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011253~~PINKGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011254~~乱麻罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011255~~缎带标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011256~~蜜赛尔舒缓马油面膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107022301~~否~~清洁类化妆品~~~~~~',
    //     '000011257~~蜜赛尔抗皱马油面膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107022301~~否~~清洁类化妆品~~~~~~',
    //     '000011258~~蜜赛尔保湿滋养马油面膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107022301~~否~~清洁类化妆品~~~~~~',
    //     '000011259~~蜜赛尔护肤马油面膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107022302~~否~~护肤用化妆品~~~~~~',
    //     '000011260~~颈肩胸膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107030406~~否~~膏药~~~~~~',
    //     '000011261~~童套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011262~~唛头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011263~~涤纶大化纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011264~~羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000011265~~防盗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011266~~合金牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000011267~~XIAOQIUKID-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011268~~XIAOQIUER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011269~~YINER裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011270~~分袖连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011272~~长袖风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011273~~长袖连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011274~~针织套衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011275~~长袖圆领衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011276~~长袖西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000011277~~珠片面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000011278~~尼龙丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011279~~金银丝线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040403~~否~~涤纶加工丝~~~~~~',
    //     '000011280~~DVLU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011281~~FB-RBBOW-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011282~~FB-DUANREN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011283~~FB-CHENSHAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011284~~CAPKING-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011285~~BIJIASUO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011286~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011287~~GUIFU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011288~~ROSE连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011289~~衬布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000011290~~灯芯绒染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011291~~衬肩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '000011292~~帽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000011293~~纸质标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011294~~扣子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011295~~色丁带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011296~~HUANGTQKID-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011297~~HUANGTAOQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011298~~BIANHUA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011299~~SHOXFORD-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011300~~FANHUAXIU-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011301~~KOUXIANQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011302~~HUANPEIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011303~~DENGXINKIDS-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011304~~DENGXINHUA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011305~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011306~~FUGUI-长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011307~~HEYETIAN-半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011308~~长大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011309~~压褶加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011310~~女式机织百慕大式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011311~~绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000011312~~包芯纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011313~~75A 14N 3SP 1/13NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011314~~PMMA 8N CLEAR~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060201~~否~~塑料粒料~~~~~~',
    //     '000011315~~PU涂层化纤制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011316~~水洗加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011317~~原毛~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000011318~~纸板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000011319~~罗文~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011320~~男式机织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011321~~100N/2绢棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010402~~否~~蚕丝及交织机织物~~~~~~',
    //     '000011322~~9.5支AB 拉毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011323~~100A AB 828~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040407~~否~~钻孔或攻丝工具~~~~~~',
    //     '000011324~~绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '000011325~~牛仔排~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10301070101~~否~~猪、牛、羊、鸡、鸭、鹅鲜、冷、冻肉~~~~~~',
    //     '000011326~~毛呢布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011327~~韩国绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011328~~涤毛混纺染色斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011329~~领链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000011330~~吊绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011331~~钩编松紧带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011332~~喷胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000011333~~FINITY连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011334~~100N 70D/2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010601~~否~~陶瓷砖~~~~~~',
    //     '000011335~~2/30NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011336~~100C~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107022203~~否~~表面活性剂~~~~~~',
    //     '000011337~~75A 22P 3SP 1/13NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011338~~75A 14N 8P 3SP 1/13NM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011339~~钻石~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050901~~否~~金银珠宝首饰~~~~~~',
    //     '000011340~~印标~~~~~~0.13~~~~个~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011341~~100C 金银铜喇叭线~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~109040999~~否~~其他电线电缆~~~~~~',
    //     '000011342~~100UF/50V~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~109023299~~否~~其他电子工业专用设备~~~~~~',
    //     '000011343~~梭织婴儿防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011344~~针织婴儿大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011345~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011346~~女童机织派克大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011347~~腰卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011348~~裤钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011349~~挂钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000011350~~烫钻面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011351~~吊牌贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011352~~翻箱费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~202~~否~~修理修配劳务~~~~~~',
    //     '000011353~~涤粘针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011354~~全棉弹力灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011355~~半透明拷贝纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010304~~否~~涂布浸渍覆盖纸~~~~~~',
    //     '000011356~~男童针织帽子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011357~~涤粘里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011358~~棉锦弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011359~~涤纶线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000011360~~金属夹子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000011361~~涤粘弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011362~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011363~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011364~~弹力绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000011365~~CASER裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011366~~XIAOGOGO-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011367~~BLACKE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011368~~BAIXINGDIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011369~~服装调价~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011370~~E-女短袖T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000011371~~E-女长袖梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011372~~E-女短袖休闲衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011373~~E-女中袖连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011374~~E-女长袖休闲衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011375~~E-女背带连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011376~~棉弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011377~~涤粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011378~~合成革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030202~~否~~再生皮革~~~~~~',
    //     '000011379~~钉珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011380~~嵌线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070503~~否~~橡胶板、杆、型材~~~~~~',
    //     '000011381~~数码印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011382~~罩杯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000011383~~人字带打金属头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000011384~~锦粘绒印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011385~~麂皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030201~~否~~成品革~~~~~~',
    //     '000011386~~男式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011387~~弹袖棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011388~~粘扣带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011389~~棉锦弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011390~~合金工字钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011391~~合纤制女式针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011392~~棉制女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011393~~女式针织休闲西服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011394~~人纤制女式针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011395~~棉制女式半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011396~~合纤制女式半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011397~~男式针织休闲衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011398~~女式针织休闲衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011399~~ZIOZIA裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011400~~长袖大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011401~~七分袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011402~~长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011403~~长袖外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011404~~仿羽绒棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000011405~~无纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '000011406~~涤粘弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011407~~XIAOGEBEI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011408~~GEGEPING-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011409~~合纤制女式连身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000011410~~吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000011411~~涤毛混纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011412~~纸吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011413~~女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011414~~棉制男式衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011415~~ZHENBOYE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011416~~DUOMAOMAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011417~~女式机织派克大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011418~~分裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011419~~长袖圆领衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011420~~全涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011421~~衬片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108012402~~否~~石棉制品~~~~~~',
    //     '000011422~~流苏~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000011423~~涤棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011424~~E-女中袖休闲衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011425~~尾夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '000011426~~针织绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011427~~锦氨网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010902~~否~~网类制品~~~~~~',
    //     '000011428~~挂牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000011429~~印花片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011430~~涤氨网眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011431~~围脖~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011401~~否~~围巾类~~~~~~',
    //     '000011432~~女童针织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011433~~针织婴儿连衣裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011434~~女式针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011435~~男式针织毛衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020121~~否~~男毛衣~~~~~~',
    //     '000011436~~TR氨纶面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011437~~梭织女式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011438~~透明扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011439~~半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011440~~FINITY裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011441~~NIKE针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011442~~FCXY针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011443~~TRENTA短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011444~~NIKE针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011445~~半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011446~~BOSS衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011447~~女式针织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011448~~涤粘弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011449~~导电长丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000011450~~婴儿针织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011451~~合纤制女裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011452~~太阳能背包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109042203~~否~~太阳能用具~~~~~~',
    //     '000011453~~针织婴儿连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011454~~女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011455~~绗缝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000011456~~金属钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011457~~备袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010702~~否~~包装用织物制品~~~~~~',
    //     '000011458~~半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011459~~男式牛仔衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011460~~复合丝雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011461~~打头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040101~~否~~钢结构及其产品~~~~~~',
    //     '000011462~~半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011463~~GREENSTRDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011464~~PPT连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011465~~ROSE连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011466~~半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011467~~塑料衣架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000011468~~男式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011469~~男式针织开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011470~~沙鱼骨~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010406~~否~~海水软体水生动物~~~~~~',
    //     '000011471~~针织麂皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030201~~否~~成品革~~~~~~',
    //     '000011472~~人造棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011473~~麂皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030601~~否~~鞣制毛皮~~~~~~',
    //     '000011474~~胸针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050901~~否~~金银珠宝首饰~~~~~~',
    //     '000011475~~涤粘弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011476~~棉涤梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011477~~涤粘弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011478~~青年布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011479~~染色加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011480~~管理费等~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~3070599~~否~~其他居民日常服务~~~~~~',
    //     '000011481~~涤棉针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011482~~嵌条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '000011483~~四面弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011484~~针织毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011485~~纸,~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000011486~~楼梯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011487~~人纤制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011488~~棉制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011489~~人纤制女式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011490~~婴儿连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011491~~薄页纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010201~~否~~未涂布印刷书写用纸~~~~~~',
    //     '000011492~~包装袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010702~~否~~包装用织物制品~~~~~~',
    //     '000011493~~蕾丝布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011494~~绣花线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010104~~否~~缝纫线~~~~~~',
    //     '000011495~~PU涂层化纤制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011496~~毛领~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000011497~~色织罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011498~~复合加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011499~~涤棉提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011500~~绣片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000011501~~涤锦粘罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011502~~粘涤磨毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011503~~吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109061801~~否~~钟~~~~~~',
    //     '000011504~~吊牌商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000011505~~衣架+尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000011506~~压克力~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000011507~~穿线链条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051399~~否~~其他信息系统安全设备~~~~~~',
    //     '000011508~~小花朵~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021302~~否~~合成橡胶~~~~~~',
    //     '000011509~~人棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011510~~半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011511~~FENFENLE-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011512~~FENGEKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011513~~YUANDAIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011514~~贡缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010399~~否~~其他麻纺织品~~~~~~',
    //     '000011515~~铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000011516~~人造丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011517~~皮片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020201~~否~~天然皮革服装~~~~~~',
    //     '000011518~~无纺衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011102~~否~~无纺织物制品~~~~~~',
    //     '000011519~~揿扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011520~~票夹卡片夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '000011521~~WETOGETHER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011522~~貉子毛领~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000011523~~被纽袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011524~~纯棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011525~~棉制男式内裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011526~~睡裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020103~~否~~女睡衣~~~~~~',
    //     '000011527~~纸箱胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10706010102~~否~~其他塑料薄膜~~~~~~',
    //     '000011528~~棉麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011529~~棉弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011530~~尺码唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011531~~海绵把套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000011532~~腈纶丝束~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000011533~~YINER连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011534~~FINITY裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011535~~YUANYUANQUAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011536~~FINITY衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000011537~~DP衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011538~~QINGCIT-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011539~~DP衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011540~~BEIGEI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011541~~WHITECHECK-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011542~~粘棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011543~~尼龙氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011544~~热转移印标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011545~~腰里~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000011546~~配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '000011547~~JAC连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011548~~ZEGNA连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011549~~FB-BORO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011550~~BOSS衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011551~~金属钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011552~~书包B16-01~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010502~~否~~纸制文具及用品~~~~~~',
    //     '000011553~~钮扣气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011554~~金属钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011555~~1.5CM织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011204~~否~~针织钩编织物（针织坯布）~~~~~~',
    //     '000011556~~拉链B70141132/B16-9挂白5号塑钢米兰牙54CM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000011557~~拉链B70141132/B16-9挂白5号塑钢米兰牙57CM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000011558~~棉服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000011559~~BAOBAOLE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011560~~DUJUAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011561~~GELANQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011562~~金属钮扣B17-11~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011563~~金属钮扣B16-01气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010502~~否~~纸制文具及用品~~~~~~',
    //     '000011564~~金属钮扣B16-11气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011565~~金属钮扣1.25暗扣A~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011566~~金属纽扣1.5CM尾夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011567~~XIAOGOKIDS-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011568~~麻粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011569~~绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011570~~男式机织休闲上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011571~~防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000011572~~PU皮袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000011573~~主唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011574~~两孔牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040102~~否~~文件夹~~~~~~',
    //     '000011575~~亚麻制女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011576~~人纤制女式半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011577~~男式机织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011578~~女童机织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011579~~BIANDEV-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011580~~HONGWANGGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011581~~LVTIAOSHAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011582~~VLINGLAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011583~~VLINGLANKIDS-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011584~~LANHONGGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011585~~AHUANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011586~~XIAOMANGGUO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011587~~CHENBAIKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011588~~GAIGAIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011589~~YUWEIGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011590~~PIDAIZHE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011591~~KOUDAIHEI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011592~~CHENGEKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011593~~CHENHONGKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011594~~CHENWENKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011595~~高领套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011596~~其他材料制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011597~~YUYUKIDS-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011598~~针织婴儿开襟衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011599~~半高领套头衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011600~~变化针型套头衫~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011601~~皮牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000011602~~贝壳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010504~~否~~淡水贝类~~~~~~',
    //     '000011603~~白卡衬板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108010902~~否~~矿物材料制品~~~~~~',
    //     '000011604~~海绵套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020105~~否~~塑料家具~~~~~~',
    //     '000011605~~饰品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050102~~否~~塑造工艺品~~~~~~',
    //     '000011606~~吊牌胶袋贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011607~~主标尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000011608~~D型扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011609~~麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011610~~涤弹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011611~~塑料胶针~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000011612~~模特~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~304030402~~否~~展览服务~~~~~~',
    //     '000011613~~衣架尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010904~~否~~竹编结制品，相关竹制品~~~~~~',
    //     '000011614~~商标挂牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000011615~~泡珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022503~~否~~灯泡及类似品封装机~~~~~~',
    //     '000011616~~不干胶纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010399~~否~~其他加工纸~~~~~~',
    //     '000011617~~毛线布印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011618~~奖品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000011619~~网膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051906~~否~~电声器件~~~~~~',
    //     '000011620~~涤纶针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011621~~后道加工~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011622~~亚光弹力色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011623~~全涤单面麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000011624~~羊毛面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011625~~棉制女式西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011626~~棉制女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011627~~尼龙腰~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011628~~锦粘染色绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011629~~毛涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011630~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011631~~合纤制男式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011632~~合纤制男式针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011633~~包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000011634~~别针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000011635~~印唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011636~~棉制女式背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011637~~领钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011638~~修身纽扣装饰套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011639~~针织短开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011640~~针织开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011641~~剪花雪纺印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011642~~彩带~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106051009~~否~~节庆庆典用品及相关娱乐用品~~~~~~',
    //     '000011643~~松紧棉绳织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011644~~全棉绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011645~~不干胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000011646~~BUDING-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011647~~NECKRUFFLE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011648~~SMALLNECK-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011649~~ZIWEIGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011650~~XUELIZHI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011651~~涤棉弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011652~~双层四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011653~~条子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011654~~棉弹力府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011655~~丝绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010402~~否~~蚕丝及交织机织物~~~~~~',
    //     '000011656~~梭织棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011657~~FENXIANER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011658~~烫图~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011659~~绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000011660~~涤纶长丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000011661~~棉涤双层弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011662~~人丝人棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011663~~TIAOTIAOJIE1-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011664~~XING LAN LAN -连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011665~~CHANG SHAN ER-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011666~~亚麻制女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011667~~西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000011668~~打揽~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011669~~全棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011670~~天丝染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011671~~缎带~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000011672~~LUOHUANKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011673~~YUANJINJIN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011674~~LANCHUJU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011675~~828腈纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011676~~涤纶雪尼尔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022810~~否~~纺织纱线处理机械~~~~~~',
    //     '000011677~~棉粘纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011678~~涤棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011679~~男童机织背带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011680~~1/1.7NM涤纶雪尼尔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022810~~否~~纺织纱线处理机械~~~~~~',
    //     '000011681~~100%有机棉 2/32S~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011682~~针织内搭~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011683~~雪纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011684~~全粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011685~~全涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011686~~WHYNOTA-外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011687~~HEIXIANXIAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011688~~HUIBAITIAO-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011689~~律师费~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~~~~~~~~~~~',
    //     '000011690~~涤纶弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011691~~涤纶梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011692~~TC布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011693~~欧根纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011694~~TR双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011695~~PU革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000011696~~珍珠岩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~102050204~~否~~砂岩~~~~~~',
    //     '000011697~~帽饰羽毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030701~~否~~加工填充用羽毛~~~~~~',
    //     '000011698~~WANJUNQING-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011699~~POLO领套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011700~~千岛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011701~~棉涤弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011702~~棉弹牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011703~~男童机织百慕大式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011704~~HUABIANNING-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011705~~HUANKOUDUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000011706~~毛腈混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011707~~男童机织背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011708~~HUANGNIUZAI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011709~~YUANXIGUA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011710~~FB-RIHE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011711~~YIDIANDIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011712~~塑料连接扣+模具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021105~~否~~塑料用模具~~~~~~',
    //     '000011713~~主标吊牌尺码标~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011714~~化纤制女式风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011715~~织标吊牌贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011716~~气眼+吊钟~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~109061801~~否~~钟~~~~~~',
    //     '000011717~~粘锦染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011718~~服务费~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~304020103~~否~~软件维护服务~~~~~~',
    //     '000011719~~邮费~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~302010199~~否~~其他邮件寄递服务~~~~~~',
    //     '000011720~~D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011721~~链条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '000011722~~绗棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011723~~人造革复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011724~~梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011725~~粘锦梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011726~~打揽加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011727~~粘锦弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011728~~标+箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011729~~HUAMEIXIAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000011730~~BAIBUDING-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011731~~肌理衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011732~~纽扣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011733~~可可牧场澳洲安格斯牛腱子500g/袋~~~~~~0~~~~~~0.0~~False~~0000000000~~False~~10301070101~~是~~猪、牛、羊、鸡、鸭、鹅鲜、冷、冻肉~~免税~~1~~',
    //     '000011734~~可可牧场澳洲安格斯精修牛肉块180g/袋~~~~~~0~~~~~~0.0~~False~~0000000000~~False~~103010805~~否~~干炸肉制品~~~~~~',
    //     '000011735~~和尚服两件套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000011736~~闭裆兔装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000011737~~肩开两件套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010599~~否~~其他床上用织物制品~~~~~~',
    //     '000011738~~中开两件套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010599~~否~~其他床上用织物制品~~~~~~',
    //     '000011739~~背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011740~~连帽拉链兔装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011741~~开裆兔装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011742~~塑料尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011743~~天丝棉弹力府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011744~~印花加工费~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011745~~长绒棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000011746~~男针织套衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000011747~~连衣裙两件套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011748~~小型普通客车(二手)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10903050103~~否~~乘用车（排气量在1.5升以上至2.0升（含））~~~~~~',
    //     '000011749~~腈纶点子纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000011750~~2.5NM涤纶雪尼尔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022810~~否~~纺织纱线处理机械~~~~~~',
    //     '000011751~~小童针织内搭~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011752~~针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011753~~纸卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010502~~否~~纸制文具及用品~~~~~~',
    //     '000011754~~A4彩卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010399~~否~~其他加工纸~~~~~~',
    //     '000011755~~毛呢面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000011756~~PTT~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109051099~~否~~其他计算机网络设备~~~~~~',
    //     '000011757~~基础v领衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011758~~HUANGHELOU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011759~~QINGPINGUO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011760~~ZHOUHEIHEI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011761~~BUDINGTA-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011762~~HUANGHELOUKG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011763~~亚麻制女式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011764~~亚麻制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011765~~备扣袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000011766~~针织套装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000011767~~亚克力钻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000011768~~弹力春亚纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011769~~帽钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041405~~否~~金属钉~~~~~~',
    //     '000011770~~女式带腰带裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011771~~女式带腰带长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011772~~男式针织百慕大式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011773~~95/5棉氨罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011774~~全棉/50gsm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011775~~双乔绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010402~~否~~蚕丝及交织机织物~~~~~~',
    //     '000011776~~绣花章~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011777~~剪花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011778~~布标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011779~~贴纸吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011780~~尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011781~~棉质女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011782~~双面缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011783~~纬弹春亚纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011784~~哑光色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011785~~涤纶绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011786~~CHENLINGJKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011787~~MANGZHONG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011788~~XINZHUANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011789~~LIANGTIAOKU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011790~~SUSHI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011791~~YOUMENG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011792~~DUANMANGGUO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011793~~JUNIUZAI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011794~~NIUZAIAQKG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011795~~LAHUAXKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011796~~NIUZAILQKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011797~~BUDINGTAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000011798~~YUHUDIE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011799~~女式短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011800~~仿记忆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011801~~晴纶针织帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000011802~~雪纺印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011803~~合纤制女式背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011804~~彩卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000011805~~备纽袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011806~~标签+吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011807~~耳套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000011808~~人棉弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011809~~金属标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000011810~~国标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011811~~套装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000011812~~成品腰~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~103010602~~否~~成品糖~~~~~~',
    //     '000011813~~女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011814~~纸垫片~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1090125~~否~~密封垫及类似接合衬垫~~~~~~',
    //     '000011815~~彩贴~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000011816~~反光标~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~109032701~~否~~发光标志及类似品及其零件~~~~~~',
    //     '000011817~~色织毛圈布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011818~~绗缝布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000011819~~女式带腰带连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011820~~WANGTIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011821~~HONGXIAOGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011822~~LANXIAOTIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011823~~PINPINW-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011824~~TR乱麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011825~~梭织里料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011826~~塑料挂钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000011827~~粘涤色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011828~~假捻缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011829~~尺码贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000011830~~尺码珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000011831~~针织圈圈呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011832~~棉涤弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011833~~女式带腰带短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011834~~ZHEZHEKU-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011835~~LUOXIONGBAI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011836~~MANYUEMEI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011837~~JIAOJIAO-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011838~~BUDINGQI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011839~~JINGDENGLONG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011840~~LEERMEI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011841~~LANYAOJI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011842~~DOUSHAQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011843~~SINGLESTR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011844~~LIUXINGZI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011845~~AMYFLOWER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011846~~拍照样衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000011847~~女式无袖套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011848~~女童连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011849~~草包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105011005~~否~~草制品~~~~~~',
    //     '000011850~~100%有机棉2/32S~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011851~~锦纶弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011852~~剪花提花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011853~~人棉梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011854~~婴儿外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011855~~幼儿外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011856~~纺丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '000011857~~人棉针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011858~~全涤雪纺染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011859~~全棉华夫格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011860~~女式马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000011861~~涤粘弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011862~~梭织提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011863~~备钮袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011864~~棉锦弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011865~~人丝细斜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011866~~TIAOTIAOJIE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011867~~XIAOFEIYANG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011868~~SOFTCHECKDR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011869~~FENFENQ-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011870~~STRSUMMER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011871~~BAILIAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011872~~LANJIANER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011873~~FB-WANGBAI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000011874~~LUOXIONGTIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011875~~LIERI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011876~~XIAOXIANGYU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011877~~衣架、尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010904~~否~~竹编结制品，相关竹制品~~~~~~',
    //     '000011878~~雪纺花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011879~~金属头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000011880~~涤纶梭织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011881~~人丝人棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011882~~棉锦弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011883~~吊牌、标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011884~~梭织罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011885~~标签、贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011886~~塑料衣架/尺码夹~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000011887~~背带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000011888~~金属扣+吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000011889~~吊牌+贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000011890~~衣架+胶袋~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104030304~~否~~塑料作面类似箱、包容器~~~~~~',
    //     '000011891~~纸箱+贴纸~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~109013403~~否~~容器装封、贴标签及包封机~~~~~~',
    //     '000011892~~胶针~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1070599~~否~~其他橡胶制品~~~~~~',
    //     '000011893~~短袖连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011894~~内衣盒~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000011895~~烫画~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000011896~~幼儿上装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011897~~幼儿下装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011898~~毛腈混纺纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011899~~金属袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011900~~亚麻人棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010304~~否~~亚麻印染布~~~~~~',
    //     '000011901~~撞钉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~108041405~~否~~金属钉~~~~~~',
    //     '000011902~~涤弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011903~~全涤四面弹~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011904~~圆绳~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000011905~~模具~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~109021199~~否~~其他模具~~~~~~',
    //     '000011906~~包边带~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000011907~~LANJIANERKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011908~~花边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010403~~否~~印染蚕丝及交织机织物~~~~~~',
    //     '000011909~~调节扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000011910~~棉锦弹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011911~~全涤针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000011912~~领压条~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000011913~~拉扣~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011914~~针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020110~~否~~男Ｔ恤衫~~~~~~',
    //     '000011915~~针织休闲服装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011916~~切条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109020408~~否~~建筑材料制品成型机械~~~~~~',
    //     '000011917~~BAIZITAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000011918~~LANZHENZHU-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011919~~STS连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011920~~STS连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011921~~TR布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011922~~色织提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011923~~涤粘染色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011924~~腈纶混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011925~~棉混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011926~~备用扣袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000011927~~针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011928~~TR染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011929~~全涤纶化纤梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011930~~四面弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011931~~ZISHAQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011932~~BAIFENTAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000011933~~JUNIUZAIKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011934~~LANLUOYE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011935~~BAIXIANLAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011936~~QIAOWEN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011937~~纸箱衬板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000011938~~粘棉弹力梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011939~~锦粘梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011940~~色纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011941~~弹力贡缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011942~~AITIAOO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011943~~LVBANGD-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011944~~WANGHUAA-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011945~~XUANZBIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000011946~~HONG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011947~~MESHBEAUTY-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011948~~小童针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011949~~小童针织两件套内搭~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011950~~烫标~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011951~~全涤汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011952~~半裙 针织~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011953~~拼接裤装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020104~~否~~金属家具~~~~~~',
    //     '000011954~~针织裤装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000011955~~混纺纱胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011956~~粘胶胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011957~~黑尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011958~~A2精棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020103~~否~~广告宣传印刷品~~~~~~',
    //     '000011959~~浅花绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011960~~浅花绵羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000011961~~黑尼龙短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000011962~~丝光羊毛条~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000011963~~回料尼龙~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011964~~紫绵羊绒~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000011965~~A3精棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~108020707~~否~~钢板~~~~~~',
    //     '000011966~~黄尼龙短纤~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000011967~~黑粘胶短纤~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000011968~~涤纶胚纱~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000011969~~白绵羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000011970~~珠带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050499~~否~~其他涂胶纺织物、带~~~~~~',
    //     '000011971~~绒带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050499~~否~~其他涂胶纺织物、带~~~~~~',
    //     '000011972~~涤纶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011973~~针织复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011974~~纬弹斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011975~~麻棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011976~~婴儿下装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000011977~~涤纶弹力梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011978~~涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011979~~纬弹太子呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000011980~~羽毛纱胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011981~~毛线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000011982~~涤粘弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011983~~尼丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000011984~~尼龙布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011985~~钩编带~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011986~~烫片~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000011987~~涤弹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011988~~人丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000011989~~梭织涤棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000011990~~树脂扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000011991~~亚麻粘染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010304~~否~~亚麻印染布~~~~~~',
    //     '000011992~~HUANGNIUZAKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011993~~XIAOFEIYANKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000011994~~XIUMENG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000011995~~织带松紧带~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000011996~~粘棉胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011997~~粘胶纱胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011998~~棉腈混纺纱胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000011999~~ZITOUGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012000~~平纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012001~~合纤制幼儿外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012002~~转移印花标~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012003~~垫片~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1090125~~否~~密封垫及类似接合衬垫~~~~~~',
    //     '000012004~~豹纹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012005~~羊羔绒~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010205~~否~~特种羊毛或动物细毛织物~~~~~~',
    //     '000012006~~针织里料~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012007~~棉粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012008~~棉粘混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012009~~尼龙扁平丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010903~~否~~吊装绳索具~~~~~~',
    //     '000012010~~涤纶大化~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012011~~亚麻制男童衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012012~~亚麻制婴儿衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012013~~涤弹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012014~~备用袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012015~~裤腰里~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012016~~白牛皮~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~101030306~~否~~生皮~~~~~~',
    //     '000012017~~锦纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012018~~梭织面料布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012019~~防霉纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010299~~否~~其他机制纸及纸板~~~~~~',
    //     '000012020~~棉涤弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012021~~磨具~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~108012801~~否~~固结磨具~~~~~~',
    //     '000012022~~TIAOMAZHE-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012023~~粘涤弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000012024~~TR弹力面料~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012025~~领花~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012026~~BAIDIANTIAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012027~~XIJINGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012028~~XIAOGEFEN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012029~~女式梭织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012030~~机织婴儿派克大衣~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012031~~针织染色烫金布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012032~~麂皮绒复合毛绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000012034~~棉涤混纺纱~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012035~~涤氨面料~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012036~~涤棉混纺纱~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012037~~针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000012038~~金属环~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012039~~棉服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020125~~否~~特种服装~~~~~~',
    //     '000012040~~连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012041~~棉服外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000012042~~棉裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000012043~~长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012044~~腰扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012045~~连体服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012046~~涤粘梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012047~~涤棉弹力针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012048~~莫代尔涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012049~~腈涤氨混纺胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000012050~~棉腈混纺胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012051~~XIAOMOGU-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012052~~纸标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012053~~大提花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012054~~ZIYIZI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012055~~LANSESHA-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012056~~LANGBODIAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012057~~GANGQINZHE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012058~~HEHEYAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012059~~TIAOMAZE-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012060~~YAOBAIDUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012061~~ZITIAOLAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012062~~HONGGELIAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012063~~包布扣~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012064~~装饰件~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~107060110~~否~~塑料零件~~~~~~',
    //     '000012065~~日字扣~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012066~~粘胶纤维~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012067~~法国绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012068~~粘棉梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012069~~蓬蓬球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040806~~否~~塑胶玩具~~~~~~',
    //     '000012070~~棉锦针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012071~~棉锦双层染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012072~~尼龙弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012073~~袖笼条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011102~~否~~无纺织物制品~~~~~~',
    //     '000012074~~枪针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109013305~~否~~喷枪及类似器具零件~~~~~~',
    //     '000012075~~XIANNVQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012076~~LANXIAOGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012077~~TAOQIZI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012078~~YUANBAIBAI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012079~~HEIBAIXIAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012080~~机织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012081~~合金扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012082~~全棉灯芯绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012083~~涤纶针织弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012084~~全尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012085~~棉涤梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012086~~假毛皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030306~~否~~生皮~~~~~~',
    //     '000012087~~巴黎绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010506~~否~~毯子~~~~~~',
    //     '000012088~~KAOYA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012089~~XIAOFEIXIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012090~~XIAOFEIXIAKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012091~~女士棉服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000012092~~梭织涤弹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012093~~辅料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060299~~否~~其他塑料半成品、辅料~~~~~~',
    //     '000012094~~莫代尔涤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012095~~羊毛汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012096~~拉手~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '000012097~~条码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012098~~毛涤拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012099~~防潮珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000012100~~涤纶弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012101~~标签+贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012102~~铁链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '000012103~~绣花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000012104~~YAOTIAOLUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012105~~TOUYAOYAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012106~~QIAOPIFEN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012107~~MESHBEAUTY-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012108~~衬肩+袖棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000012109~~色织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012110~~涤纶色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012111~~弹力灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012112~~纬弹太子呢印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20102~~否~~垃圾处理、污泥处理处置劳务~~~~~~',
    //     '000012113~~涤纶梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012114~~棉涤弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012115~~针织提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012116~~LILILAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012117~~HUATIAOKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012118~~MH-LIKE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012119~~ZITIAOTIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012120~~SIKOUZHE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012121~~QIANNIAOHUI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012122~~LANGMIGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012123~~女式T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020109~~否~~女Ｔ恤衫~~~~~~',
    //     '000012124~~RAINBOWSTR-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012125~~亮丽丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012126~~蕾丝染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012127~~涤纶染色绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012128~~MH-FENTUTU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012129~~FENTUTUKG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012130~~HEKOUDUAN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012131~~管理费~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~304080101~~否~~物业管理服务~~~~~~',
    //     '000012132~~针织拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012133~~双面胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012134~~男式针织马球衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012135~~针织婴儿套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012136~~合金钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012137~~封箱胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000012138~~ERTIAONI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012139~~XIAONIZI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012140~~MIZONG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012141~~纸盒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000012142~~挂衣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012143~~拉绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012144~~钉珠片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000012145~~乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012146~~防盗扣面扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109025311~~否~~防盗、防火报警器及类似装置~~~~~~',
    //     '000012147~~仿毛球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000012148~~钩子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080499~~否~~其他金属制品~~~~~~',
    //     '000012149~~绣花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012150~~防盗扣底扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109025311~~否~~防盗、防火报警器及类似装置~~~~~~',
    //     '000012151~~靠垫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000012152~~色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020402~~否~~纺织面鞋~~~~~~',
    //     '000012153~~隐形拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012154~~腰包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000012155~~追踪标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012156~~FAKPINK-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012157~~葫芦扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012158~~PU涂层化纤制梭织帽子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012159~~女式机织腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012160~~女式机织羽绒大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000012161~~弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012162~~锦粘针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012163~~透明不干胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012164~~人造毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000012165~~0.9D腈纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000012166~~SHUNMAONI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012167~~巴黎绒印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012168~~面料染色加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012169~~卫衣绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012170~~WUYETI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012171~~巴黎绒染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012172~~全涤氨纶汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012173~~针织婴儿裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012174~~循环再生棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012175~~棉混纺针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012176~~针织带打头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011304~~否~~针织或钩编相关餐桌用制品~~~~~~',
    //     '000012177~~防潮纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000012178~~泡泡色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012179~~彩盒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000012180~~涤纶提花染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012181~~纸箱+衬板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000012182~~按扣、裤钩、D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012183~~男装针织休闲外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012184~~斜纹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012185~~圣诞帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051009~~否~~节庆庆典用品及相关娱乐用品~~~~~~',
    //     '000012186~~梭织裤装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012187~~高弹春亚纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012188~~DAXIAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012189~~QIANGEDAI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012190~~SHUANGPINA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012191~~试制费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~3040101~~否~~研发服务~~~~~~',
    //     '000012192~~MAOGEQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012193~~GEBEIBEI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012194~~HEGECHANG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012195~~SHUANGDAIGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012196~~SHUANGDAIKG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012197~~BIUBIUQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012198~~QIANGEDUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012199~~MAOTIAOLUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012200~~RENZILUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012201~~复合面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012202~~梭织涤弹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012203~~腰带扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000012204~~佐积麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012205~~丝绒烫金面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012206~~印花标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012207~~SHUNMAODKG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012208~~SHUNMAODUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012209~~DADAIKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012210~~透明带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012211~~蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012212~~ZHOUGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012213~~ZHONGKOU-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012214~~DAJIN-IC-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012215~~MEIZHA-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012216~~ZHUXINGXING-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012217~~HUIFENLUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012218~~SHENLANBAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012219~~DAMIBAO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012220~~提花雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012221~~扣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012222~~洗标吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000012223~~薄膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10706010102~~否~~其他塑料薄膜~~~~~~',
    //     '000012224~~涤纶短线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012225~~ZHAOJUN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012226~~FENGYAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012227~~FB-BORO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012228~~FB-ELISE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012229~~GETIAOQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012230~~TUHUIJI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012231~~FENGYIQ-IC-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012232~~HEICHACHA-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012233~~BAOBAOLED-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012234~~尼龙绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012235~~PIPISHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012236~~涤纶弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012237~~吊牌+吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012238~~平绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012239~~真丝乔绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012240~~拷贝纸+衬板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010302~~否~~转印纸~~~~~~',
    //     '000012241~~衬肩+棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000012242~~NIANNUJIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012243~~CHENDENGXKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012244~~女式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012245~~女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012246~~女式背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012247~~GAOGEGE-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012248~~HEIGEBAI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012249~~JIULIANGQI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012250~~BAIXIANQI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012251~~LANXINGKONG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012252~~全亚麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012253~~洗标、价格牌、贴纸、主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012254~~主标唛头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012255~~产地标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012256~~吊牌织唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000012257~~后整加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012258~~浴巾加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012259~~儿童内衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012260~~拉绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012261~~压烫标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012262~~CHENARMYKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012263~~涤纶针织绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012264~~裆底透明贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000012265~~纸箱，衬板，拷贝纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010302~~否~~转印纸~~~~~~',
    //     '000012266~~棉涤弹力面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012267~~男式羽绒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '000012268~~呢绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000012269~~扁绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012270~~肩衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '000012271~~主标 尺码标 吊卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012272~~毛皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030307~~否~~生毛皮~~~~~~',
    //     '000012273~~女式机织连体紧身衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012274~~女式针织披肩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012275~~棉制灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012276~~纸片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000012277~~填充棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000012278~~LANDENGLUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012279~~HANGGEDUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012280~~ERTIAONI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020123~~否~~运动服装~~~~~~',
    //     '000012281~~BAIDENGQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012282~~太子呢染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012283~~棉锦布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012284~~标签吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012285~~马司贝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012286~~标签吊牌贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012287~~白牛皮纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000012288~~针织提花罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012289~~涤粘弹力针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012290~~撬边线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012291~~打缆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012292~~色织英伦格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012293~~1180816-4-0135拉花纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012294~~HONGSIRON-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012295~~天丝牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012296~~棉涤纶羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000012297~~涤粘梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012298~~女式外套（两件套）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012299~~发夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000012300~~涤锦粘针织弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012301~~主唛/吊牌/洗唛/尺码唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012302~~仿记忆面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012303~~包边布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012304~~提花橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000012305~~女式针织百慕大式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012306~~梭织全涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012307~~狮子绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000012308~~纸箱贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109013403~~否~~容器装封、贴标签及包封机~~~~~~',
    //     '000012309~~色纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012310~~全涤雪尼尔针织胚布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012311~~胆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012312~~织绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000012313~~氨纶花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040307~~否~~氨纶纤维~~~~~~',
    //     '000012314~~切条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '000012315~~条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '000012316~~三档扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080416~~否~~锁及其附件~~~~~~',
    //     '000012317~~全毛150g汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000012318~~拉链拉手~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012319~~五金皮带扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '000012320~~舒美绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000012321~~涤纶印花绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012322~~复合丝斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012323~~高压聚乙烯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021402~~否~~催化剂~~~~~~',
    //     '000012324~~天丝棉斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012325~~单丝人棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012326~~全涤里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012327~~商标 吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012328~~合金揿扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012329~~聚乙烯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10702130199~~否~~其他初级形态塑料~~~~~~',
    //     '000012330~~GETIAOQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012331~~XIEBIANCHANG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012332~~LIANGSHASHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012333~~HONGSIRON-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012334~~GETIAOCHANG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012335~~HONGSIRON-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012336~~ZHUANBEIXIN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012337~~KAFANGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012338~~XIANNVQUN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012339~~HONGFO-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012340~~FENYUN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012341~~HUIFANGGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012342~~腰带头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000012343~~吊牌、主标、尺码标、洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000012344~~袖标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012345~~梭织印花珠帆布-迷彩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000012346~~包缸费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012347~~锌合金标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000012348~~棉涤弹力灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012349~~丝绒印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011203~~否~~针织或钩编起绒织物~~~~~~',
    //     '000012350~~利息~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~3060108~~否~~统借统还~~~~~~',
    //     '000012351~~棉弹坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012352~~棉涤弹坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012353~~TR布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012354~~乱麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012355~~女式装饰链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050901~~否~~金银珠宝首饰~~~~~~',
    //     '000012356~~挂衣链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080499~~否~~其他金属制品~~~~~~',
    //     '000012357~~手塞棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '000012358~~TR双层四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012359~~拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012360~~箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012361~~MATCHA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012362~~领子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012363~~抽绳袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010702~~否~~包装用织物制品~~~~~~',
    //     '000012364~~拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012365~~斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012366~~拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012367~~中斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012368~~美丽绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012369~~乱麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012370~~提花罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012371~~锦粘弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012372~~花纹扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012373~~全棉灯芯绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012374~~照相样衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012375~~卡板纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010299~~否~~其他机制纸及纸板~~~~~~',
    //     '000012376~~HUIXIAOGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012377~~LIHAI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012378~~JINGEGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012379~~DADAIGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012380~~HEILALA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012381~~MH-MOGE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012382~~MH-MOGEKU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012383~~袋子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012384~~扎带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012385~~纸制拼制帽子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000012386~~杜邦舒弹绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012387~~仿丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012388~~面料复合~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012389~~全涤乱麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012390~~纬弹太子呢印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012391~~锦棉提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012392~~女式针织半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012393~~腈纶涤纶尼龙羊毛氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012394~~麻棉弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012395~~弹力亚麻粘~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012396~~人造革绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012397~~草制编结帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000012398~~暗花树脂扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012399~~羽毛制品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030701~~否~~加工填充用羽毛~~~~~~',
    //     '000012400~~雪纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012401~~涤粘提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012402~~ROUGUI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012403~~QINGYANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012404~~QINGYANGKB-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012405~~PINGHU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012406~~长袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012407~~涤纶针织染色绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012408~~软网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012409~~硬网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012410~~3444EV-W87~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000012411~~全棉空心绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012412~~辅料*穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012413~~女式梭织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012414~~豹纹毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000012415~~女式机织围巾~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012416~~女式机织腰包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000012417~~KAKAKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012418~~HEHEJIN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012419~~YAOKOULUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012420~~NINNAKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012421~~ZHISHANKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012422~~ZHISHANKUOKG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012423~~CESHANLUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012424~~PALOUTI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012425~~PALOUTIKG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012426~~YEZIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012427~~CAISHIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012428~~BAIBAOBAO-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012429~~LIANGXIAOKOU-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012430~~FENXIAOXIANG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012431~~FXIAOXIANGKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012432~~名字牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012433~~尼龙加捻定型丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012434~~尼龙弹力丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012435~~GETIAOQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012436~~GETIAOQUNKG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012437~~FB-JINSHU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012438~~FB-JINSHUKU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012439~~MH-JIANJIA-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012440~~MH-JIANJIAKU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012441~~JINGZHI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012442~~女式梭织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012443~~合纤制女式梭织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012444~~女式防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012445~~婴儿背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012446~~女式防寒外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012447~~男式防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012448~~棉人棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012449~~XIAOXIANGQ-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012450~~DIELANQ-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012451~~衬衣外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012452~~衬衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012453~~涤毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012454~~全涤印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012455~~亚麻粘印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012456~~亚麻棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012457~~纬弹太子呢染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012458~~人造棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012459~~粘锦弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012460~~亚麻粘色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012461~~兔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000012462~~棉弹力布印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012463~~罗缎布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012464~~HUANGSISI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012465~~女式针织裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012466~~胶袋贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012467~~预装贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012468~~色织格子面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012469~~主标、吊牌、腰卡、贴纸、洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012470~~人造棉帽带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000012471~~LANHUAER-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012472~~SUOLIANKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012473~~SHUANGYAKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012474~~麻粘坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012475~~羊绒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000012476~~色织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012477~~服装袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012478~~纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010202~~否~~涂布类印刷用纸~~~~~~',
    //     '000012479~~棉质男童衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012480~~穿针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000012481~~全棉花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012482~~女式梭织外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012483~~男式衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012484~~剪花提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012485~~金属夹扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012486~~弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012487~~修色片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000012488~~水洗片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000012489~~染色片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000012490~~连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012491~~原料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000012492~~ZONGHEYE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012493~~梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012494~~LANBAIGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012495~~LANBAIGEKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012496~~BAITHUAZITAO-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012497~~FENFENDIE-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012498~~FENNIUZAI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012499~~BAIXIANFANGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012500~~SEXHENGSHU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012501~~LANLINGJIE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012502~~MH-LANLINGKU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012503~~印花纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000012504~~男式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012505~~毛涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012506~~色织罗缎布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012507~~编结帽子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000012508~~人棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012509~~麻类~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010302~~否~~麻纱线~~~~~~',
    //     '000012510~~天丝人棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012511~~涤纶染色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012512~~发带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000012513~~花朵~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012514~~金属件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012515~~LVCHATIAO-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012516~~LANDAIXIAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012517~~DABOTUODIAN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012518~~HUIMENGGEZI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012519~~QINGYANG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012520~~MOLIAN-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012521~~BAISUZHEN-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012522~~FENZAI-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012523~~FENZAIKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012524~~CAISHIQUN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012525~~LIANGSHASHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012526~~LIANGSHAKG-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012527~~RUANKOUDAI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012528~~BEIERHUANG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012529~~FENBEIZHU-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012530~~FENXIANBEI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012531~~休闲裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012532~~CHANGKAQI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012533~~SANKOUKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012534~~QIAOQIAOFEN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012535~~MH-DOOTS-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012536~~MH-DOOTSKU-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012537~~MH-NAIYOU-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012538~~ROUGUIKG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012539~~ROUGUI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012540~~挂镀四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012541~~树脂拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012542~~亚麻粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012543~~蜂巢四面弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012544~~T/C本白~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012545~~粘麻七分袖衬衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012546~~粘麻七分袖上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012547~~全开粘麻长袖衬衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012548~~喷胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000012549~~针棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000012550~~尼龙胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012551~~XIAOLANDIE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012552~~硅胶条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021399~~否~~其他橡胶~~~~~~',
    //     '000012553~~挂耳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10702130199~~否~~其他初级形态塑料~~~~~~',
    //     '000012554~~女士羽绒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012555~~女士棉服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012556~~男士PU夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012557~~男士羽绒服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012558~~男式长袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012559~~男式短袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012560~~男式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012561~~男士夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012562~~女士裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012563~~男士衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012564~~男士长袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012565~~女士衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012566~~女士毛呢上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012567~~塑料垫片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060110~~否~~塑料零件~~~~~~',
    //     '000012568~~原料短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012569~~双层麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012570~~防盗标解锁器~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012571~~涤弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012572~~金属挂链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012573~~挂镀绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012574~~麻棉色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012575~~天丝麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012576~~天丝麻印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012577~~全棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012578~~印花人棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012579~~粘棉弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012580~~四面弹乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012581~~针织绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012582~~尺码标贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012583~~塑料钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000012584~~箱贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012585~~PINNIUZAI-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012586~~BEIERHUANG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012587~~PINNIUZAIKG-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012588~~E-PUJIDAO-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012589~~R/C条子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012590~~涤麻混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012591~~针织POLO衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012592~~装饰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000012593~~XIXIZONG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012594~~QIANSHASHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012595~~涤棉口袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012596~~E-CHANGSHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012597~~ROUSHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012598~~DANLV-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012599~~SHUANGKOUMI-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012600~~梭织童装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012601~~梭织女童连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012602~~梭织女幼童连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012603~~打缆加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012604~~麻粘色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012605~~NINADUAN-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012606~~LANDAIXIANKG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012607~~FENNIUZAIKG-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012608~~导电丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040901~~否~~裸电线~~~~~~',
    //     '000012609~~弹力罗缎染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012610~~化纤制针织女式连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012611~~牛角扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012612~~纯亚麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012613~~HEIHUAMI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012614~~HEHUAMI-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012615~~ZONGHEYE-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012616~~腈纶针织帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000012617~~吊牌、腰卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012618~~商标、吊牌、尺码标、主标、洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012619~~人棉梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012620~~女式梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012621~~粘胶混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012622~~婴儿服装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012623~~棉涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012624~~条码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012625~~巴黎纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012626~~棉混纺纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012627~~有机棉针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012628~~合金面圆形四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012629~~合金吊牌 25MM 名字牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012630~~锦纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012631~~粘锦弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012632~~BAIXIANSHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012633~~MH-WEIKUO-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012634~~CUTEDOTST-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012635~~E-REDDOTST-衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000012636~~BEIERHUANGKG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012637~~腈纶圆带纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012638~~压胶条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '000012639~~全棉梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012640~~涤粘弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012641~~人纤制女士西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000012642~~人纤制女式西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '000012643~~BAIYUTA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012644~~E-TIANTA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012645~~BAIDAGE-连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012646~~DIEDIEXIAN-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012647~~HEIXIANSHA-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000012648~~麻粘色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012649~~化纤制女式无袖衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012650~~棉制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012651~~RUANKOUDAIKB-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012652~~FENBEIZHUKG-裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012653~~棉制女童防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012654~~人纤制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012655~~棉制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012656~~化纤制女式吊带背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012657~~化纤制女式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012658~~合纤制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012659~~化纤制女式针织吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012660~~吊牌 主标 尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012661~~人棉色织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012662~~人纤制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012663~~人纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012664~~合纤制女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012665~~化纤制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012666~~棉制女式背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012667~~合纤制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012668~~合纤制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012669~~女式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012670~~夹子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000012671~~LANDAIXIAN工装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012672~~FENFENDIE工装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012673~~男式套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000012674~~全涤剪花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012675~~口字扣 内经33MM~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012676~~合金面圆形撞钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041405~~否~~金属钉~~~~~~',
    //     '000012677~~3号隐形布边闭口拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012678~~纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012679~~化纤制女式连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012680~~女式半截裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012681~~棉制女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012682~~婴儿梭织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012683~~涤棉弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012684~~机械弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012685~~压褶雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012686~~涤粘里料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012687~~亚麻人棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012688~~粘亚麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012689~~亚麻棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012690~~含pbt纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012691~~女式梭织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012692~~女式梭织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012693~~自然色毛球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012694~~灰白毛尖毛球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012695~~黑色毛球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012696~~双色十字球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012697~~人纤制女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012698~~合纤制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012699~~女童机织风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012700~~女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012701~~合纤制女式加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012702~~人纤制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012703~~人纤制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012704~~化纤制女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012705~~合纤制女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012706~~大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012707~~扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012708~~布包腰夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012709~~外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012710~~半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012711~~短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012712~~短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012713~~夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012714~~磨砂透明带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000012715~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012716~~T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012717~~背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012718~~女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012719~~女式针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012720~~女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012721~~女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012722~~女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012723~~女式吊带衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012724~~女式连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012725~~女式紧身胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012726~~吊牌/主标/尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012727~~人造棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000012728~~女式套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012729~~女童裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000012730~~袖棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010605~~否~~垫子套~~~~~~',
    //     '000012731~~套环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000012732~~粘胶涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012733~~贝壳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012734~~化纤制女式针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012735~~合纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012736~~化纤制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012737~~棉涤弹力罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012738~~合纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012739~~压褶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012740~~人纤制女童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012741~~倒筒加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012742~~装饰品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050102~~否~~塑造工艺品~~~~~~',
    //     '000012743~~混纺胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012744~~涤纶混纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012745~~棉羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012746~~背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012747~~麂皮绒烫金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030605~~否~~人造毛皮制品~~~~~~',
    //     '000012748~~主唛、尺码唛、洗唛、吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012749~~上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012750~~长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012751~~连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012752~~带帽衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012753~~有色涤纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012754~~女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012755~~女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012756~~女式开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012757~~化纤制女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012758~~水洗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012759~~化纤制女式针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012760~~纸袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000012761~~亚麻制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012762~~泡沫垫子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000012763~~腰袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012764~~合纤制女式半身长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012765~~棉制女式吊带背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012766~~包包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030305~~否~~纺织材料作面类似箱、包容器~~~~~~',
    //     '000012767~~男式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012768~~女童针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012769~~女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012770~~女式长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012771~~围巾加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012772~~连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012773~~标、吊牌、贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000012774~~棉麻弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012775~~排苏~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012776~~平纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000012777~~箱袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012778~~毛类~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '000012779~~口字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012780~~钻石拉片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000012781~~貉子毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000012782~~假皮袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030605~~否~~人造毛皮制品~~~~~~',
    //     '000012783~~羽绒棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051213~~否~~棉胎~~~~~~',
    //     '000012784~~衣领~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '000012785~~试制费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000012786~~合纤制女童连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012787~~化纤制女童连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012788~~合纤制女童针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012789~~电子标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012790~~棉制婴儿防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012791~~棉制女童防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012792~~化纤制女式风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012793~~针织品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012794~~棉制女式长款马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012795~~男婴棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012796~~服装后整~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012797~~男式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012798~~橡胶木板材~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010604~~否~~木托板、箱形托盘及类似装载木板~~~~~~',
    //     '000012799~~有纺衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012800~~女式针织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012801~~服装整理费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012802~~摇粒绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012803~~女式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012804~~洗水唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012805~~男式针织开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012806~~裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012807~~马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012808~~合金脚牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012809~~打模~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012810~~化纤制女式连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012811~~棉制女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012812~~棉制女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012813~~人纤制女童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012814~~化纤制女式西装马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012815~~人纤制女童连身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012816~~机织婴儿大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012817~~女童机织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012818~~皮绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000012819~~合纤制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012820~~亚麻制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012821~~裤袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000012822~~金属腰扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012823~~PU人造革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000012824~~金属链条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '000012825~~毛条处理加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012826~~粘胶尼龙涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012827~~植绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012828~~饰品配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050102~~否~~塑造工艺品~~~~~~',
    //     '000012829~~印染加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012830~~后道整理费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012831~~锦涤粘弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012832~~女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012833~~女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012834~~女式梭织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012835~~工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012836~~布三档扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012837~~针织乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012838~~按扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012839~~药片口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012840~~化纤制女式针织连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012841~~棉制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012842~~合纤制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012843~~棉制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012844~~PU涂层人纤制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012845~~连接器~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012846~~棉制女式钩编上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012847~~棉制女式紧身胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012848~~成分唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012849~~款号唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012850~~尼丝纺烫金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012851~~棉粘弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012852~~仿丝绵~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '000012853~~桃皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012854~~金属装饰件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012855~~女童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012856~~女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012857~~化纤制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012858~~PU涂层化纤制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012859~~婴儿服饰~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012860~~男童针织卫衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012861~~女童针织裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012862~~男幼童针织裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012863~~梭织女大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000012864~~梭织女马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012865~~棉涤弹力双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012866~~大童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012867~~不干胶覆膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012868~~产地贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012869~~帽子 9593410~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '000012870~~胶条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '000012871~~西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012872~~童装外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012873~~腰牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012874~~背心裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012875~~涤纶珠片绣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012876~~衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012877~~腈纶涤纶氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000012878~~棉制女式防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012879~~洗水唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012880~~亚麻制女童短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012881~~针织类~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012882~~化纤制婴儿防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012883~~化纤制女童防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012884~~袖套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010605~~否~~垫子套~~~~~~',
    //     '000012885~~布包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012886~~袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000012887~~合纤制女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012888~~PU涂层化纤制女式紧身胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012889~~气泡纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010199~~否~~其他纸浆~~~~~~',
    //     '000012890~~高温袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000012891~~PU涂层化纤制女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012892~~气泡垫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105020205~~否~~塑料家具零配件~~~~~~',
    //     '000012893~~棉制婴儿衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012894~~棉制男童衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012895~~棉制女式短上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012896~~棉制女式连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012897~~棉制女式连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012898~~PU涂层化纤制女式针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012899~~化纤制女式针织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012900~~毛呢外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020301~~否~~毛皮服装~~~~~~',
    //     '000012901~~锦纶网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012902~~羽毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000012903~~女式针织吊带衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012904~~女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012905~~女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012906~~婴儿梭织连体衣套装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012907~~斜条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000012908~~直条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000012909~~织带斜条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000012910~~女童开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012911~~门襟拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012912~~天地板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000012913~~口袋拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012914~~织带绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012915~~子母带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000012916~~侧缝拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000012917~~护目镜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109062001~~否~~眼镜成镜~~~~~~',
    //     '000012918~~金属绳夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000012919~~提花织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011002~~否~~纺织材料制传输带~~~~~~',
    //     '000012920~~3M棉包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030399~~否~~其他箱、包及类似容器~~~~~~',
    //     '000012921~~棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012922~~丝带唛头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000012923~~领吊~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011402~~否~~领带~~~~~~',
    //     '000012924~~雪梨纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012925~~皮标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000012926~~主标、洗标、尺码标、吊粒、干燥剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000012927~~女式梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012928~~女士梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012929~~女士连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012930~~女士梭织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012931~~机织婴儿休闲上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012932~~男童机织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012933~~女童机织派克大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012934~~机织婴儿休闲上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000012935~~男童机织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '000012936~~女式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012937~~合纤制女童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012938~~合纤制女童短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012939~~合纤制女式背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012940~~合纤制女式针织半身长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012941~~化纤制女式针织连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012942~~绳带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012943~~泡沫垫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000012944~~复合丝植绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012945~~3M新棉2型~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '000012946~~绳止~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000012947~~女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012948~~莫代尔印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012949~~莫代尔染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012950~~服装打揽绣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012951~~12裤/裙架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060105~~否~~塑料条、棒、型材~~~~~~',
    //     '000012952~~价格吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012953~~合金四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012954~~女式工装裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012955~~套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012956~~全棉弹力贡缎布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012957~~女式背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012958~~女式针织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012959~~女式针织开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012960~~女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012961~~粘胶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012962~~金属扣子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012963~~女式防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012964~~60克仿丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012965~~棉制婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012966~~棉制男童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012967~~纽袢松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000012968~~休闲长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000012969~~女童防寒连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000012970~~印花灯芯绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012971~~绒包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012972~~女式梭织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012973~~水钻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108011403~~否~~室内及室内装饰用玻璃器具~~~~~~',
    //     '000012974~~药片扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000012975~~连衣短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012976~~PU连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012977~~女式针织背心裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012978~~染色弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000012979~~女式梭织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012980~~女式针织裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012981~~格子法兰绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000012982~~190T涤塔夫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012983~~男式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012984~~复合丝雪纺印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012985~~女式腰带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012986~~涤腈布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012987~~扣头+腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000012988~~合纤制针织女式T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012989~~绣花制品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050601~~否~~刺绣工艺品~~~~~~',
    //     '000012990~~透明弹力带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000012991~~含芯压线纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012992~~女式文胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012993~~打钮模~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010503~~否~~木模~~~~~~',
    //     '000012994~~工字钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040410~~否~~打孔冲及类似手工工具~~~~~~',
    //     '000012995~~垫肩棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '000012996~~合纤制女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000012997~~白色吊卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000012998~~210T涤纶里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000012999~~洗水唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013000~~女童针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013001~~女士梭织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013002~~四针六线车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013003~~平眼车布鲁斯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013004~~斯玛诺蒸汽发生器~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013005~~四线拷边机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013006~~筒绷机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013007~~五线拷边机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013008~~套结机布鲁斯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013009~~绷缝车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013010~~钉扣机布鲁斯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013011~~电脑套结机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013012~~吊牌、标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013013~~女童防寒连体服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013014~~猪鼻扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013015~~防滑橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000013016~~女童棉上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013017~~天地板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013018~~花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013019~~男童棉上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013020~~女童棉长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013021~~男童防寒连体服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013022~~插扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013023~~金属气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013024~~全涤穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013025~~金属四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013026~~拉绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013027~~针织牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013028~~287MS款气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013029~~人字斜纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013030~~针织罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013031~~H721RR款气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013032~~针织毛圈布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013033~~TR罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013034~~机织婴儿派克大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013035~~化纤制女童防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013036~~针刺棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013037~~粘胶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013038~~鱼网花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013039~~手缝扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013040~~金属暗脚扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013041~~金属撞钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013042~~棉锦弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013043~~女式西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013044~~双瓦纸箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '000013045~~包边橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000013046~~肩带橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000013047~~女童棉背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013048~~丝绒带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013049~~化纤制女式针织连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013050~~单边花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013051~~楼梯花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013052~~双边花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013053~~包布袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000013054~~PU涂层化纤制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013055~~泡沫垫片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000013056~~汽泡纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000013057~~大花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013058~~下摆花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013059~~上口花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013060~~黑色睫毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051202~~否~~刷子类制品~~~~~~',
    //     '000013061~~揿钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013062~~PU涂层化纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013063~~内扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013064~~大扣子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013065~~衬条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '000013066~~包布环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013067~~涤腈粘针织染色拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013068~~急钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022703~~否~~日用杂品生产专用设备~~~~~~',
    //     '000013069~~棉涤弹力贡缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013070~~胶带贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060299~~否~~其他塑料半成品、辅料~~~~~~',
    //     '000013071~~商标、吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013072~~女式连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013073~~化纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013074~~商标辅料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013075~~EPE~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013076~~人纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013077~~调节袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013078~~打条费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013079~~女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013080~~女式针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013081~~金属包头绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013082~~腰绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013083~~水晶呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013084~~PU涂层合纤制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013085~~绳头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013086~~成份标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013087~~魔术贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000013088~~涤纶标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013089~~胶片吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013090~~面包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013091~~塑料电镀绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013092~~四爪扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013093~~防盗标贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013094~~后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013095~~150D仿记忆~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '000013096~~橡筋绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013097~~女式棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013098~~100有机棉 2/32S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013099~~裤钩模具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021105~~否~~塑料用模具~~~~~~',
    //     '000013100~~吊染费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013101~~涤纶复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013102~~网眼里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013103~~透明肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021302~~否~~合成橡胶~~~~~~',
    //     '000013104~~T/R/SP格子面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013105~~去污剂~~~~~~0.13~~~~瓶~~0.0~~False~~0000000000~~False~~107029903~~否~~擦洗膏、去污粉及类似制品~~~~~~',
    //     '000013106~~洗唛吊牌~~~~~~0.13~~~~套~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013107~~涤粘印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013108~~女士上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013109~~女式梭织披风加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013110~~女式针织吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013111~~男式T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000013112~~男式卫衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000013113~~服装修色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013114~~热转印~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013115~~塑料尺码珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013116~~女士背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013117~~涤塔夫绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010402~~否~~蚕丝及交织机织物~~~~~~',
    //     '000013118~~拉头挂件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013119~~仿羽绒手塞棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '000013120~~300T春亚纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013121~~横机螺纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013122~~男童棉衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013123~~三角皮标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013124~~胶袋贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013125~~银丝织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000013126~~纸制标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013127~~裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013128~~价格牌贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013129~~五金配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '000013130~~双平纸箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '000013131~~棉制女童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013132~~棉制女式防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013133~~棉制男童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013134~~棉质女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013135~~化纤制男式防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013136~~敦煌线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013137~~棉制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013138~~PU涂层合纤制女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013139~~棉制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013140~~PU皮带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000013141~~罗纹2*2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013142~~灰鸭绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000013143~~锦涤桃皮绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013144~~纯白鸭绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000013145~~扎染加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013146~~标 吊牌 洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013147~~棉制女式短上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013148~~PU涂层化纤制女式针织背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013149~~女式梭织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013150~~女式机织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013151~~锦涤粘染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013152~~扣眼带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010399~~否~~其他麻纺织品~~~~~~',
    //     '000013153~~11W灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000013154~~16W灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000013155~~女士长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013156~~垫板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013157~~男士上衣后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013158~~侧缝标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013159~~针织女式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013160~~橡胶标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013161~~男装背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013162~~成衣修色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013163~~化纤机制花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013164~~涤纶弹力印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013165~~黑色整烫松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013166~~组合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013167~~嵌条布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013168~~钉珠花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013169~~粉色睫毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013170~~合纤制女式针织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013171~~合纤制女式针织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013172~~合纤制女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013173~~化纤制女式防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013174~~垫肩+棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000013175~~松紧绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013176~~PU搭扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013177~~暗四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013178~~棉制女式紧身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013179~~亚麻制女式梭织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013180~~棉制男式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013181~~小毛皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030201~~否~~成品革~~~~~~',
    //     '000013182~~方形贝壳钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013183~~方形树脂纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013184~~TC汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013185~~棉制女童短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013186~~棉制婴儿背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013187~~棉制男童背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013188~~仿木拉条扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013189~~100D平雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013190~~棉制女童背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013191~~连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013192~~织片加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013193~~挂衣带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013194~~挂衣绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013195~~洗标+追踪标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013196~~珠子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013197~~仿兔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '000013198~~全棉灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013199~~棉制男童防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013200~~调节松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013201~~化纤制女式梭织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013202~~平雪纺染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013203~~金属对扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013204~~刺毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000013205~~短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000013206~~仿醋酸色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013207~~涤纶混纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013208~~190T涤丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013209~~长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013210~~洗水标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013211~~粉色吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013212~~色织格子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013213~~女式针织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013214~~色丁面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013215~~打揽加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013216~~撬边机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013217~~马王带机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013218~~倒筒机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022810~~否~~纺织纱线处理机械~~~~~~',
    //     '000013219~~烫台~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013220~~三针麦夹车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109060299~~否~~其他工业仪表~~~~~~',
    //     '000013221~~圆眼车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013222~~大排扣车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013223~~棉涤弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013224~~涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013225~~修色加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013226~~珍珠绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000013227~~针织汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013228~~女式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013229~~胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000013230~~603CH款工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013231~~水洗费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013232~~假毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013233~~全涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013234~~吊牌小贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000013235~~女童棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013236~~小兔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000013237~~女式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013238~~胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000013239~~603CH款工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013240~~水洗费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013241~~T400韩国点~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013242~~树脂开口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013243~~树脂闭口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013244~~金属闭口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013245~~金属双开~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013246~~金属开口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013247~~UPC贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013248~~价格贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013249~~贴箱纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013250~~条码唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013251~~衣架贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013252~~圆形贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013253~~金银纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013254~~PU涂层合纤制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000013255~~衬布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013256~~全棉色织绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013257~~BNB 主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013258~~BNB 尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013259~~BNB 印标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013260~~BNB 洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013261~~女式西服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013262~~内衬隔条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013263~~D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013264~~绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013265~~成分标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013266~~皮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013267~~女式其他服装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013268~~塑料纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013269~~葫芦袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013270~~机织婴儿上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013271~~男童机织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013272~~机织婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013273~~男童机织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013274~~椰子扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013275~~斜纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013276~~衣架配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013277~~合纤制女式背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013278~~涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013279~~机织婴儿上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013280~~男童机织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013281~~透明夹子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013282~~日字袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013283~~袖棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013284~~女式针织外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013285~~经编衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '000013286~~人纤制女式针长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013287~~粗纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013288~~合纤制女童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013289~~金属蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013290~~塑胶吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000013291~~合纤制男式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013292~~3#尼龙顶口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013293~~三针链条车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013294~~平头锁眼车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013295~~三线拷边机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013296~~电脑平车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013297~~耳机标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013298~~荧光标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013299~~四合扣明扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013300~~染色涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013301~~男式棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013302~~四合扣暗扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013303~~416WX款钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013304~~双面记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013305~~喷漆双面气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013306~~气眼喷漆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013307~~气眼垫片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013308~~皇冠型尺码夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013309~~女士套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013310~~女式人造革长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013311~~女式人造革短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013312~~梭织色织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013313~~210T涤塔夫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013314~~女式人造革半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013315~~双面胶衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000013316~~人纤制女式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013317~~合纤制裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013318~~色丁染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013319~~新树脂扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013320~~里布(SL-29)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013321~~里布(SL-3FINER)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013322~~里布(SL-3 FINER)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013323~~化纤制女式针织吊带背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013324~~PU涂层合纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013325~~PU涂层化纤制女式针织连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013326~~扣头、腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000013327~~亚克力钻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060102~~否~~塑料板、片~~~~~~',
    //     '000013328~~涤弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013329~~垫肩、棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020301~~否~~毛皮服装~~~~~~',
    //     '000013330~~裤扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013331~~女式梭织西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013332~~裤钩/风纪扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013333~~拉头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013334~~后整理加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013335~~织布加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013336~~修布加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013337~~压花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013338~~植绒压花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013339~~包边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013340~~后整理加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013341~~拉袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013342~~道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013343~~鸭绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030702~~否~~加工填充用羽绒~~~~~~',
    //     '000013344~~纱线加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013345~~粘胶长丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040202~~否~~人造纤维长丝~~~~~~',
    //     '000013346~~女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013347~~锦粘色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013348~~腰带扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013349~~涤黏布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013350~~涤纶粘胶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013351~~皇冠型尺码夹（SZ/R47)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013352~~合纤制女式半身长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000013353~~化纤制女式钩编套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013354~~棉制女式钩编套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013355~~亚麻面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013356~~布包袢子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013357~~合纤制女童短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013358~~针织里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013359~~面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013360~~化纤制女式开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013361~~化纤制女式针织开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013362~~PU涂层女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013363~~涤纶珠片布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013364~~松紧带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013365~~帽子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013366~~主挂牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013367~~男童机织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000013368~~厚道加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013369~~提花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013370~~注塑衣架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013371~~树脂花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013372~~双空绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013373~~腰园绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013374~~金属调节扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013375~~梭织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013376~~涤纶针织染色烫金布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013377~~全涤平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013378~~弹丝缎面雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013379~~PU牛角搭扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013380~~修补费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013381~~电脑平车（重机8100－7、兄弟6200A)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013382~~PU涂层面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013383~~嵌条44~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013384~~女式机织防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013385~~背袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000013386~~裙架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013387~~PU涂层化纤制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013388~~男童机织风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013389~~染色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013390~~警告标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013391~~菲羽FL700~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030701~~否~~加工填充用羽毛~~~~~~',
    //     '000013392~~挂耳绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '000013393~~裤钩衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013394~~女式机织百慕大式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000013395~~日子扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013396~~金属纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013397~~假捻段~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013398~~粘锦弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013399~~印刷辅料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013400~~PU涂层化纤制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013401~~合纤制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013402~~棉制女式中裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013403~~合纤制男式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013404~~PU涂层化纤制女式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013405~~金色气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013406~~棉腈纶涤纶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013407~~布衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013408~~女式毛呢加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013409~~毛呢布衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013410~~棉绳尾夹加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013411~~TC螺纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013412~~TC绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013413~~脚纽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013414~~吊牌机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1090299~~否~~其他专用设备及零部件~~~~~~',
    //     '000013415~~PU涂层化纤制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013416~~化纤制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013417~~100%有机棉 2/20S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013418~~涤纶染色针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013419~~格子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013420~~女式梭织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013421~~海绵~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060107~~否~~泡沫塑料~~~~~~',
    //     '000013422~~涤毛色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013423~~提花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013424~~挂装贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013425~~涤塔夫绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013426~~钮攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013427~~BNB 金属牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032702~~否~~金属制地名牌、号码及类似标志~~~~~~',
    //     '000013428~~金属制钮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013429~~BNB 旗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013430~~女式T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013431~~女式吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013432~~男式T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013433~~BNB 洗标 P4~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013434~~PB 洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013435~~布制标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013436~~纸质~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013437~~金属制纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013438~~全棉汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013439~~热转移印花标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013440~~特殊吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013441~~狮子金属袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013442~~黑白松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013443~~男童针织开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000013444~~女婴裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000013445~~男婴裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000013446~~带钻链条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '000013447~~印字水溶性胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000013448~~PU涂层化纤制女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013449~~PU涂层化纤制女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013450~~软棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000013451~~合纤制女式针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013452~~PU涂层女式风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013453~~全棉色织提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013454~~金属标牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000013455~~标、吊牌、洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013456~~针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013457~~棉涤印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013458~~女式开衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013459~~16#单色两眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013460~~黑包袋贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013461~~涤棉针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013462~~女式针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000013463~~口子环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013464~~粘锦弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013465~~玉带绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '000013466~~女童夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013467~~旗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013468~~全涤梭织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013469~~全棉罗纹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013470~~隔档~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013471~~翻标加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013472~~男士卫衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013473~~涤纶染色绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013474~~UID标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013475~~双面涤纶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013476~~日期标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013477~~塑料调节扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013478~~染色蕾丝花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013479~~女式梭织背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013480~~女式梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013481~~金属挂件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '000013482~~小D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013483~~锦涤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013484~~大D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013485~~全面面料染色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013486~~非织物标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013487~~拉花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013488~~褪色标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013489~~粘锦弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013490~~弹力棉涤粘3/1左斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013491~~化纤制女式防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013492~~金属尾夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '000013493~~粘涤纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013494~~棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013495~~胶唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013496~~男式防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013497~~男式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013498~~印花版~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010299~~否~~其他机制纸及纸板~~~~~~',
    //     '000013499~~涤纶制滑雪服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013500~~塑胶拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '000013501~~法兰绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000013502~~男式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013503~~男式防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013504~~女式防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013505~~男士短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013506~~化纤棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040101~~否~~化纤棉绒浆粕~~~~~~',
    //     '000013507~~标签产品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013508~~男童防寒背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013509~~男式便服套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013510~~女式防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013511~~男童棉背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013512~~拉链穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013513~~圆橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013514~~仿羽绒吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013515~~手塞棉挂卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013516~~男童上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013517~~记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013518~~涤塔夫印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013519~~罗纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013520~~金属领链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013521~~穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '000013522~~涤纶珠片秀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013523~~黑包袋贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013524~~BNB 吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013525~~皮条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000013526~~纽襻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013527~~22#两眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013528~~纽攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013529~~前道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013530~~领罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013531~~代号标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013532~~绗棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013533~~梭织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013534~~金属扣配铜针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013535~~T/C汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013536~~T/C绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013537~~螺纹2*2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013538~~女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013539~~女式风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013540~~化纤制女式连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013541~~袢子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000013542~~TRSP 色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013543~~连身装/游戏裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013544~~上衣/背心式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013545~~女式针织吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013546~~棉粘四面弹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013547~~棉粘色织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013548~~涤粘锦弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013549~~麻粘面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013550~~涤锦粘弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013551~~锦粘弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013552~~男童夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013553~~男童棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013554~~涤纶织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013555~~绗棉加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013556~~厚涤纶织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013557~~内滚棉绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013558~~包头穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013559~~女童棉衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013560~~女童马夹加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013561~~男童棉马夹加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013562~~间色织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013563~~女童棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013564~~粘棉涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013565~~麻粘染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013566~~LOVE织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013567~~领角~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000013568~~领底呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000013569~~人纤制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013570~~合纤制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013571~~空白唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013572~~毛呢大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013573~~挂链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013574~~亚麻梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013575~~主标、吊牌、洗标、吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013576~~女童梭织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013577~~女童梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013578~~女童梭织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013579~~主标、洗标、吊牌、贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013580~~粘锦涤弹力梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013581~~兄弟平缝车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013582~~5匹吸顶空调~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109041599~~否~~其他家用空气调节器~~~~~~',
    //     '000013583~~PU涂层合纤制女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013584~~PU涂层化纤制女式连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013585~~化纤制女式长款衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013586~~反光面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013587~~袖袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000013588~~钉珠费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013589~~化纤制女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013590~~PU涂层化纤制女式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013591~~棉制针织男童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013592~~女童针织吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013593~~靠枕~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000013594~~全涤针刺毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '000013595~~涤粘斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013596~~假皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000013597~~棉制女式风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013598~~棉制女式长款上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013599~~人纤制斜纹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013600~~四合扣模具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021105~~否~~塑料用模具~~~~~~',
    //     '000013601~~有纺衬布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013602~~朵花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013603~~棉制婴儿连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013604~~涤纶坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013605~~棉制女童连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013606~~女式梭织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013607~~全棉毛圈布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013608~~涤纶球~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000013609~~涤氨染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013610~~棉锦染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013611~~女式PU上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013612~~兰精人棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013613~~涤腈色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013614~~涤锦粘弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013615~~涤线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013616~~吊牌(FSC MIX 70%)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013617~~信息标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013618~~全棉印花汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013619~~1*1罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013620~~棉氨罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013621~~100%尼龙弹力丝 2/70D 面包丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013622~~粘胶棉胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013623~~190T~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013624~~PU涂层化纤制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013625~~化纤制女式背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013626~~PU涂层化纤制女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013627~~化纤制女式棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013628~~塑料电镀纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013629~~PU涂层合纤制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013630~~PU腰带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030403~~否~~皮革制衣着附件~~~~~~',
    //     '000013631~~女士梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013632~~女式梭织长裙加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013633~~机织婴儿防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013634~~女童机织防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013635~~环扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013636~~子母牵带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013637~~GRS无胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000013638~~仿绒棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000013639~~麻粘绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013640~~人纤制女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013641~~胸棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000013642~~女式针织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013643~~长裤/吊带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000013644~~白山羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000013645~~合纤制女式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013646~~女式连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013647~~压胶材料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '000013648~~内纽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013649~~塑钢拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013650~~假毛标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013651~~涤氨汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013652~~梭织毛呢面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013653~~女式针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013654~~干霸干燥剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~103021199~~否~~其他食品添加剂~~~~~~',
    //     '000013655~~说明唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013656~~空白标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013657~~空白洗唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013658~~主吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013659~~胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013660~~白腈纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000013661~~白尼龙短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040301~~否~~锦纶纤维~~~~~~',
    //     '000013662~~有色腈纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000013663~~双层四面弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013664~~女式pu长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013665~~海岛缎印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013666~~女式PU长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013667~~全棉色织格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013668~~色丁印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013669~~全棉园绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013670~~滴油扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013671~~版费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010299~~否~~其他机制纸及纸板~~~~~~',
    //     '000013672~~女式针织吊带背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013673~~海岛缎提花印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013674~~全弹高丝宝印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013675~~银丝顺纡印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013676~~海岛缎染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013677~~梭织斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013678~~全弹高丝宝印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013679~~锦粘弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013680~~锦粘弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013681~~信息吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013682~~380T尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000013683~~打揽线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013684~~特殊标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013685~~全亚麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013686~~硅油衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000013687~~手镯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108032301~~否~~包金金属材料~~~~~~',
    //     '000013688~~两眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013689~~包布日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013690~~面料染色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013691~~全涤乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013692~~全涤乱麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013693~~棉弹力泡泡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013694~~拉链衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '000013695~~女式梭织半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013696~~亚麻制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013697~~亚麻制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013698~~二眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013699~~亚麻制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013700~~亚麻制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013701~~合纤制女式长款上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013702~~日期唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013703~~210T涤丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013704~~色织格子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013705~~棉弹力贡缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013706~~粘锦罗缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013707~~网绣布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013708~~黑胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000013709~~人纤制女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013710~~女式其他上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013711~~棉制女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013712~~女式梭织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013713~~网布面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013714~~印花针织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013715~~竹节纱卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013716~~女式梭织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013717~~修色加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013718~~天丝涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013719~~袜子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011306~~否~~针织袜~~~~~~',
    //     '000013720~~女士梭织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013721~~纸质吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013722~~人纤制女童裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013723~~人纤制婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013724~~人纤制男童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013725~~麻棉弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013726~~麻棉混纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013727~~白色梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013728~~领压条白色汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013729~~白色提花梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013730~~白色汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013731~~绣花梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013732~~全棉提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013733~~全棉条纹汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013734~~领压条汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013735~~吊带背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013736~~棉网~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010902~~否~~网类制品~~~~~~',
    //     '000013737~~210T涤塔夫染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013738~~金属揿扭~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040799~~否~~其他刀剪及类似日用金属工具~~~~~~',
    //     '000013739~~*纺织产品*缝纫线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013740~~晴纶涤纶氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013741~~弹力贡缎印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013742~~全涤棉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013743~~190T袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013744~~亚麻制婴儿短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013745~~亚麻制男童短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013746~~开线绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013747~~BNB吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013748~~女士裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013749~~100有机棉 2/20S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013750~~100有机棉 2/21S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013751~~说明书~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000013752~~洋装/背心裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000013753~~男童针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000013754~~棉布绣花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013755~~合纤制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013756~~金属裤钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000013757~~人棉色丁布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013758~~精梳棉网~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010902~~否~~网类制品~~~~~~',
    //     '000013759~~白橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013760~~透明橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013761~~白色橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013762~~色织罗缎面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013763~~莫代尔涤坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013764~~封口绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013765~~全棉坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013766~~天丝麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013767~~粘胶印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013768~~女式针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013769~~丝光防缩绵羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000013770~~女式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013771~~亚麻混纺吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013772~~女式梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013773~~染色全棉平纹府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013774~~男童针织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000013775~~四眼狮子攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013776~~绳扣黑色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013777~~牛皮纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '000013778~~化纤制女式长款马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013779~~树脂纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013780~~玩具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040802~~否~~填充类玩具~~~~~~',
    //     '000013781~~织造加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013782~~全棉染色绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013783~~有机棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013784~~包芯线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013785~~T/C面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013786~~棉制女式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013787~~男式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013788~~白粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000013789~~腈纶散纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000013790~~粘胶短纤维~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '000013791~~女式梭织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013792~~女式针织开衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013793~~棉尼龙弹力府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013794~~女式梭织PU长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013795~~主标 尺码标 洗标 吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013796~~装饰链条+气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013797~~四合扣浅金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013798~~合纤制女式背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013799~~粘锦弹染色斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013800~~浅金色大扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013801~~日字扣浅金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013802~~金属开口拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013803~~汽眼浅金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013804~~四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013805~~水溶条花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013806~~锦氨面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013807~~毛制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013808~~浅金色金属纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013809~~染色全棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013810~~50%棉 50%涤纶 2/30S 胚筒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013811~~55%棉 45%腈纶 2/30S 胚筒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013812~~时尚布口罩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000013813~~女式马夹加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013814~~压褶加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013815~~横纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013816~~厚织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013817~~装饰标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013818~~角口拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013819~~帽子拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013820~~女童棉背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013821~~2.3CM防滑橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013822~~服装修色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013823~~斜纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013824~~针织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013825~~黑橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013826~~扣眼橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000013827~~男童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013828~~拉链圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013829~~女童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013830~~100%有机棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013831~~女式长款马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013832~~女式短款马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013833~~化纤制女式短款马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013834~~有机棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013835~~配比袋贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013836~~塑料四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013837~~成份唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013838~~梭织尼丝纺~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~101010199~~否~~其他谷物~~~~~~',
    //     '000013839~~女式机织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013840~~化纤制女童大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013841~~再生涤纶面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013842~~女童防寒连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013843~~T/C口袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013844~~34g纸衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010399~~否~~其他加工纸~~~~~~',
    //     '000013845~~11.5*6mm气眼全套/配J105胶介子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013846~~SAP贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013847~~男士长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013848~~全尼龙女式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000013849~~反光布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013850~~女式针织泳衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013851~~棉制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013852~~棉制女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013853~~罗文织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013854~~调节扣8字~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013855~~女式肚兜加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013856~~38L纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013857~~小日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013858~~42L纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013859~~大日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013860~~揿纽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013861~~裕袍~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013862~~拉花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '000013863~~橡筋织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013864~~睡裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013865~~睡衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013866~~园松紧绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013867~~UPC标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013868~~洗水布标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013869~~TR弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013870~~无胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000013871~~裤钩浅金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013872~~闭口金属拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013873~~隐形小滴水头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013874~~浅金色四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013875~~日字扣4个色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013876~~四眼扣4个色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013877~~挂牌+挂绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013878~~3#隐形拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013879~~LR商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013880~~主麦尺码唛#73~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013881~~开口金属拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000013882~~亚银绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013883~~金色四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013884~~亚银汽眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013885~~全棉帽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013886~~天丝斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013887~~女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013888~~锦纶条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000013889~~女式梭织夹克衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013890~~全涤平纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000013891~~粘胶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013892~~干白~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000013893~~干红~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000013894~~木盒干红~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1030305~~否~~葡萄酒~~~~~~',
    //     '000013895~~女式背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013896~~色织棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013897~~钮袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013898~~口罩加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013899~~涤纶包芯线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000013900~~花筒费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010508~~否~~纸制筒管、卷轴、纡子及类似品~~~~~~',
    //     '000013901~~PU涂层女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013902~~服装水洗加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013903~~全棉梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013904~~装饰金属牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032702~~否~~金属制地名牌、号码及类似标志~~~~~~',
    //     '000013905~~玲珑麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013906~~涤 ECO 16S~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000013907~~粗纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013908~~A2精梳棉网~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010902~~否~~网类制品~~~~~~',
    //     '000013909~~脱脂绵羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000013910~~40D扁平丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000013911~~鱼刺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000013912~~男式运动服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013913~~干燥包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~103021199~~否~~其他食品添加剂~~~~~~',
    //     '000013914~~涤纶四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013915~~反光条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032701~~否~~发光标志及类似品及其零件~~~~~~',
    //     '000013916~~女童防寒羽绒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013917~~防寒围巾加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013918~~猪鼻扣橡皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013919~~组合吊钟橡皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013920~~羊毛吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013921~~主麦~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013922~~主麦+尺码唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013923~~吸铁石~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000013924~~弹力带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000013925~~合纤制女式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013926~~样布费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013927~~女式梭织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013928~~女式梭织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013929~~PU涂层化纤制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013930~~PU涂层化纤制女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013931~~纽袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013932~~REP独码单胶袋贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013933~~sp040~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013934~~SORT贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013935~~挂卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000013936~~钩边松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000013937~~PTT短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000013938~~普梳棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '000013939~~珠片纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013940~~连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '000013941~~针刺毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010701~~否~~毡呢~~~~~~',
    //     '000013942~~菊花眼绣花费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013943~~女式PU衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013944~~皮搭扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013945~~浅金色扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013946~~亮银色扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013947~~亚克力烫钻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000013948~~男童西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013949~~树脂激光扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013950~~金属三档扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013951~~男童商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013952~~毛毡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013953~~男童短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013954~~金属标牌模具费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021102~~否~~金属、硬质合金用模具~~~~~~',
    //     '000013955~~特殊织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013956~~全人棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013957~~莫代尔涤染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013958~~裤型卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013959~~水洗灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000013960~~人纤制女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013961~~合纤制女式针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013962~~挂镀合金扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013963~~婴儿针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013964~~合纤制女童针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013965~~PU涂层化纤制女婴针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013966~~婴儿针织夹克衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013967~~合纤制女童针织夹克衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013968~~合纤制男童针织手套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011307~~否~~针织手套~~~~~~',
    //     '000013969~~样衣费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013970~~眼镜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109062001~~否~~眼镜成镜~~~~~~',
    //     '000013971~~塑料胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030304~~否~~塑料作面类似箱、包容器~~~~~~',
    //     '000013972~~平板布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013973~~化纤制女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013974~~PU涂层合纤制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013975~~吊牌、商标、洗标、贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013976~~棉制女童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013977~~人纤制女童连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013978~~压胶费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013979~~服装印花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013980~~女式中裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000013981~~银丝短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000013982~~普梳棉网~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010902~~否~~网类制品~~~~~~',
    //     '000013984~~涤锦纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000013985~~羽绒包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '000013986~~保费等~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~306030202~~否~~机动车交通事故责任强制保险服务~~~~~~',
    //     '000013987~~胶章~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107051001~~否~~硬质橡胶~~~~~~',
    //     '000013988~~梭织男大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013989~~梭织女棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000013990~~CC 洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013991~~主标 洗标 吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000013992~~蜡绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000013993~~钻扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013994~~防霉片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107029901~~否~~室内散香或除臭制品~~~~~~',
    //     '000013995~~挂镀金属粘树脂扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013996~~牛角纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000013997~~化纤制婴儿针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013998~~化纤制女童针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000013999~~棉制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014000~~棉制婴儿针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014001~~化纤制婴儿针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014002~~人纤制女童针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014003~~化纤制婴儿针织小短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014004~~人纤制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014005~~女式PU连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014006~~女式PU胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014007~~珍珠棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014008~~金属闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000014009~~金属开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000014010~~抽绳+金属尾夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '000014011~~吊牌贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014012~~配比贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014013~~吊牌+绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014014~~化纤制女式防寒衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014015~~合纤制女式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014016~~男童机织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014017~~化纤制男童防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014018~~PU涂层化纤制女式针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014019~~合纤制婴儿针织拉链衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014020~~合纤制男童针织拉链衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014021~~腈纶尼龙羊毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014022~~涤纶腈纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014023~~含芯压线加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014024~~女式针织文胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014025~~小花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014026~~女式针织皮裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014027~~棉制女童背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014028~~棉制女童裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014029~~棉制婴儿连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014030~~棉制男童连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014031~~装饰牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014032~~棉弹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014033~~尼龙勿尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000014034~~男式棉背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014035~~价格牌/洗水吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014036~~粘胶衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014037~~橡皮筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '000014038~~毛料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014039~~合纤制婴儿马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014040~~化纤制男童马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014041~~含珠片加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014042~~紫山羊绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '000014043~~针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014044~~涤纶印花色丁里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014045~~针织迷彩印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014046~~印花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014047~~女式针织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014048~~中线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014049~~素色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014050~~弹力印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014051~~袖笼棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014052~~有孔橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014053~~女童棉短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014054~~金属绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014055~~脚口拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000014056~~厚橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014057~~涤棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014058~~亚麻粘~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014059~~外套/披风~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014060~~男童机织防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014061~~蛇纹涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014062~~钩边松紧带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000014063~~棉制女式半身长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014064~~棉制女式长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014065~~红色PU皮面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014066~~红色PU皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000014067~~吊牌/价格牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014068~~腰带洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014069~~人纤制女式半身长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014070~~1cm双面胶衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014071~~纺涤纶复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014072~~涤氨空气层~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014073~~女式半连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014074~~11.5*6mm气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014075~~毛制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014076~~男童机织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000014077~~单面麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014078~~搭袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014079~~管子扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014080~~纸板过桥板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014081~~纸箱纸板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014082~~包装过桥板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '000014083~~斜桃~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014084~~男童机织防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000014085~~人纤制女童短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014086~~亚麻制女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014087~~化纤制男式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014088~~绗棉制品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014089~~转印标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014090~~涤丝纺印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014091~~全涤压光涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014092~~绣花标1~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014093~~绣花标2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014094~~貉子毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000014095~~三角扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014096~~3cm织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014097~~4cm织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014098~~皮标2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000014099~~搭扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014100~~皮标1~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000014101~~2cm织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014102~~魔术贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014103~~绣花标3~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014104~~对丝加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014105~~剪花染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014106~~女式针织连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014107~~腰带洗水~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014108~~女式针织T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014109~~PU涂层化纤制女式针织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014110~~PU涂层化纤制女式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014111~~PU涂层化纤制女式针织胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014112~~棉制女式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014113~~女连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014114~~耳带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014115~~真牛角扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014116~~真牛角纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014117~~棉制男式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014118~~全棉针织汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014119~~女式皮裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014120~~女背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014121~~女式衬裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014122~~染色全棉机织斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014123~~女士衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014124~~金银丝法兰绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014125~~织片费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014126~~压胶加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014127~~全涤梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014128~~丝光毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000014129~~粘涤染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014130~~鱼骨~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000014131~~封箱牛皮纸胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '000014132~~化纤制女式针织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014133~~化纤制女式针织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014134~~无金银丝法兰绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014135~~女式半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000014136~~半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014137~~棉制男童背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014138~~棉制婴儿背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014139~~面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014140~~化纤制男童衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014141~~棉制婴儿针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014142~~棉制女童针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014143~~棉制女童针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014144~~棉制婴儿针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014145~~合纤制婴儿针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014146~~化纤制婴儿衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014147~~化纤制婴儿衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014148~~化纤制女童针织连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014149~~棉府绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014150~~附加吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014151~~经编布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014152~~四合扣底件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014153~~女式针织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014154~~黑色PU皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020202~~否~~非天然皮革服装~~~~~~',
    //     '000014155~~双面铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '000014156~~主唛+尺码唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014157~~梭织毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014158~~棉涤针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014159~~T/C斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014160~~裤型标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014161~~化纤制女式针织T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014162~~含丝纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014163~~雅美白粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '000014164~~棉纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014165~~女式短裤T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014166~~GEY单面麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014167~~CEY单面麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014168~~暗面四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014169~~麂皮绒染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014170~~女式针织马球衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014171~~化纤制女式短大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014172~~合纤制女式针织吊带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014173~~机织婴儿防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014174~~男童机织防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014175~~合纤制女式针织半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014176~~再生涤纶梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014177~~锦棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014178~~化纤制女式防寒短上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014179~~非零售粘胶长丝混纺纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014180~~女婴针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014181~~女婴针织开衫加工费~~~~~~0.13~~~~~~0.0~~True~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014182~~女童针织开衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014183~~棉纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014184~~PU涂层女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014185~~涤纶印花植绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014186~~女式PU套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014187~~非零售粘胶长丝混纺多股纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014188~~毯子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014189~~男式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014190~~染色色丁带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014191~~化纤制女式针织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014192~~主标价格牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014193~~PU涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014194~~1cm斜条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014195~~1cm双面胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000014196~~2cm黑双面胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000014197~~小吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014198~~机织婴儿防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014199~~男童机织防风夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '000014200~~羊毛制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014201~~女士半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014202~~塑料条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000014203~~裁剪~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014204~~锦涤染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014205~~梭织女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000014206~~梭织女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000014207~~合纤制女士上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014208~~化纤羊毛混纺毛呢布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014209~~口袋标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014210~~15mm四合扣全套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014211~~合纤制女式针织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014212~~合纤制女式针织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014213~~人纤制女式针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014214~~合纤制女式针织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014215~~涤纶仿记忆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014216~~洗槽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014217~~网单+绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014218~~胶签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014219~~印花天鹅绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '000014220~~金属扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014221~~女式针织皮衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014222~~合纤制女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014223~~棉制女童衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014224~~合纤制男童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014225~~100%再生涤纶梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000014226~~化纤制婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014227~~桁棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014228~~PU涂层女式连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014229~~人棉梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014230~~涤腈毛梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014231~~包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014232~~钻链流苏~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '000014233~~女童针织开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '000014234~~暗扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014235~~男式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014236~~再生尼丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014237~~优富手塞棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '000014238~~女式衬衫加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014239~~二眼扣漂白~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014240~~调节扣8字黑色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014241~~本布包扣黑色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014242~~四面弹PU面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014243~~女式皮衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014244~~钢骨~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014245~~海绵杯~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '000014246~~双曲无结冰麻纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014247~~涤纶弹力针织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014248~~尼龙毛毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014249~~拉头绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014250~~女式连身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014251~~棉制男童连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014252~~棉制婴儿连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014253~~水洗~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014254~~女式针织紧身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '000014255~~BREAL 吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014256~~富丽绉印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014257~~针织网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014258~~服装加工费女式针织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014259~~扣眼松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '000014260~~弹力衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014261~~激光加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014262~~人棉斜纹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014263~~化纤制女式针织紧身胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014264~~PU涂层化纤制女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014265~~PU涂层化纤制女式针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014266~~PU涂层化纤制女士针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014267~~TR针织复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014268~~金属挂镀蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014269~~金属揿扣包布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014270~~钦扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014271~~毛领加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014272~~女式梭织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014273~~儿童塑料条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000014274~~成人塑料条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '000014275~~睡裤水洗费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014276~~棉制男式睡裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014277~~棉制男式睡衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014278~~女式连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014279~~粘胶睛纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014280~~蛇皮涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014281~~色织格纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014282~~60%棉 40%涤纶女式针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014283~~金属绳止~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000014284~~女士连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014285~~男式睡裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014286~~男式睡衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014287~~100%有机棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014288~~60%有机棉 40%粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014289~~女式针织拉链衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014290~~黑色羽绒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '000014291~~条染复精梳加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014292~~套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014293~~防缩毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '000014294~~围脖加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014295~~全涤摇粒绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000014296~~全毛汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014297~~合纤制婴儿套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014298~~化纤制女童针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014299~~化纤制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014300~~合纤制男式针织手套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011307~~否~~针织手套~~~~~~',
    //     '000014301~~PU涂层女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014302~~人纤混纺平纹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014303~~水洗磨毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014304~~吊牌 吊绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014305~~梭织棉锦弹力双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014306~~全涤缎面雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014307~~梭织女式套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '000014308~~梭织女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000014309~~女式PU皮短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '000014310~~人棉涤斜纹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014311~~双面胶2cm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000014312~~双面胶1cm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '000014313~~做织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014314~~PU皮面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030202~~否~~再生皮革~~~~~~',
    //     '000014315~~滚边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014316~~合纤制女童针织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014317~~化纤制女式针织防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014318~~呢绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014319~~塑料制品胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '000014320~~男童防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014321~~绗棉打揽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014322~~男童防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014323~~双面小吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014324~~男童防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014325~~4cm橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014326~~5cm橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014327~~1.8cm全棉穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014328~~装饰标1~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014329~~婴儿防寒背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014330~~提花肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '000014331~~5#尼龙银齿门襟闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '000014332~~2.5CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014333~~橡筋扣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014334~~黑炭衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014335~~人纤制女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014336~~化纤制女童针织开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014337~~合纤制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014338~~合纤制女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014339~~化纤制女式针织背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014340~~合纤制女式针织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014341~~珠片线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014342~~合格证~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014343~~腰带鸡眼绣花费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014344~~印花针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014345~~耳带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000014346~~水洗衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014347~~全涤富丽绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014348~~其他材料制女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014349~~棉制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014350~~挂镀金属蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014351~~化纤制女式无袖防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014352~~化纤制女式针织防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014353~~大白扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014354~~全棉纱卡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014355~~拉花日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014356~~拉花四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014357~~女式针织连帽开衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014358~~蜡绳+子弹头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014359~~熨斗帖~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000014360~~涤锦弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014361~~75D雪珠纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014362~~四面弹单面麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014363~~钻石绒印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014364~~75/36平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014365~~100/96平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014366~~龙凤呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014367~~合纤制女式梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014368~~圆环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000014369~~化纤制女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014370~~喷漆绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014371~~喷漆四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014372~~合金二眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014373~~双打模具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060110~~否~~塑料零件~~~~~~',
    //     '000014374~~天丝人棉斜纹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014375~~女式针织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014376~~化纤制女童针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014377~~人纤制男式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014378~~棉粘针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014379~~女式针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014380~~女士针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014381~~皮带3个色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '000014382~~色织灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '000014383~~样卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '000014384~~开具~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021105~~否~~塑料用模具~~~~~~',
    //     '000014385~~女童化纤制针织内衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014386~~化纤制男式针织T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014387~~化纤制男式针织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014388~~风钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000014389~~二手缝纫机~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000014390~~口罩贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014391~~成人耳带穿绳扣手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014392~~化纤制女式连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014393~~合纤制女式无袖连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014394~~人纤制女式无袖连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014395~~烫钻加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014396~~紫色PU皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000014397~~白色PU皮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '000014398~~儿童单结耳带穿绳扣手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014399~~儿童双结耳带穿绳扣手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014400~~白色耳带穿绳扣手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014401~~涤棉弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014402~~针织牛仔面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014403~~粘锦涤弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014404~~合纤制男式针织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014405~~织带钦扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014406~~涂层化纤制男式外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014407~~天丝棉弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014408~~天丝棉弹胚布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014409~~皱感尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014410~~吊牌样卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014411~~粘锦弹力印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000014412~~优富仿羽棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014413~~全再生尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '000014414~~绳夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000014415~~女士针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014416~~M8FMDAA2684~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014417~~U02MCP2094A~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014418~~棉制女式连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014419~~全粘剪花印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000014420~~全棉法兰绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014421~~智能卡吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014422~~全天丝提花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014423~~麻棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014424~~再生涤纶梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '000014425~~50/72全涤平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014426~~银丝线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014427~~后整费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014428~~化纤制女童连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014429~~化纤制女童针织连身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014430~~化纤制女式无袖套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014431~~衬条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014432~~标 洗唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014433~~绳子包头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000014434~~主唛洗唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014435~~新疆棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '000014436~~四针六线（星菱）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000014437~~棉弹府绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014438~~珍珠蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022703~~否~~日用杂品生产专用设备~~~~~~',
    //     '000014439~~合金四合扣浅金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014440~~全棉府绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014441~~人棉氨纶针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014442~~涤氨染色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014443~~全棉斜纹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014444~~梭织男式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '000014445~~机织婴儿风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014446~~棉制女式半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014447~~化纤制女式短风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014448~~合纤制女式短袖上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014449~~粘锦针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014450~~棉弹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014451~~单丝人棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014452~~棉天丝弹力斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014453~~男童防风上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014454~~装饰铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '000014455~~女童防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014456~~绣花加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014457~~女童防风上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014458~~化纤制女式紧身胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014459~~蘑菇包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014460~~服装打揽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014461~~儿童耳带穿绳扣手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014462~~口罩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '000014463~~熨斗贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '000014464~~Stradivarius牌无型号非医用时尚配饰口罩加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014465~~Stradivarius牌非医用非防护时尚配饰口罩加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014466~~鼻压条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041101~~否~~铁丝~~~~~~',
    //     '000014467~~打揽费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014468~~化纤制女式针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014469~~止口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060110~~否~~塑料零件~~~~~~',
    //     '000014470~~服装打条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014471~~TC珠地布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014472~~尼龙衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014473~~锦棉弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014474~~肩棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '000014475~~女童防风外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014476~~全棉穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014477~~印字针织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014478~~2*2.5cm魔术贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '000014479~~3.2cm橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014480~~气泡膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10706010102~~否~~其他塑料薄膜~~~~~~',
    //     '000014481~~2.5cm提花橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '000014482~~装饰标2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014483~~尺码洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '000014484~~侧缝标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106020199~~否~~其他印刷品~~~~~~',
    //     '000014485~~防盗扣抵扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109025311~~否~~防盗、防火报警器及类似装置~~~~~~',
    //     '000014486~~合金双针扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014487~~开模费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014488~~*纺织产品*打揽线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '000014489~~CD件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '000014490~~涤纶PTT短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '000014491~~尺码圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000014492~~牛皮纸挂钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '000014493~~打绳头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '000014494~~女式POLO衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014495~~55%RWS羊毛 45%棉 2/30nm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '000014496~~针织女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014497~~人纤制女裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014498~~童裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '000014499~~无纺布袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010702~~否~~包装用织物制品~~~~~~',
    //     '000014500~~自动开袋机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '000014501~~名字标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00002~~半裙~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00003~~人棉尼龙弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00004~~女式羽绒背心~~~~~~0.13~~~~件~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '00005~~棉涤弹力帆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00006~~花式纱~~~~~~0.13~~~~千克~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00007~~女式风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020116~~否~~女大衣、女风衣~~~~~~',
    //     '00008~~春亚纺~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00009~~化纤棉针织布~~~~~~0.13~~~~码~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00010~~黏钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051009~~否~~节庆庆典用品及相关娱乐用品~~~~~~',
    //     '00011~~无光膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1060203~~否~~印刷用附件~~~~~~',
    //     '00012~~花瑶绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00013~~四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00014~~水磨布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010399~~否~~其他加工纸~~~~~~',
    //     '00015~~分袖圆领衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00016~~丝绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00017~~天鹅绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00018~~罗纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00019~~醋酸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00020~~斜纹坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00021~~风纪扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00022~~腰头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '00023~~抽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010904~~否~~绳梯类制品~~~~~~',
    //     '00024~~毛绒包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '00025~~罗缎面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00026~~染色布~~~~~~0.13~~~~米~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00027~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00028~~牛津布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00029~~保费-机动车商业保险产品等~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~306030299~~否~~其他财产保险服务~~~~~~',
    //     '00030~~珍珠扣~~~~~~0.13~~~~粒~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00031~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00032~~女式防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00033~~男式防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '00034~~女式防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020122~~否~~防寒服~~~~~~',
    //     '00035~~涤氨针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010404~~否~~化纤长丝机织物~~~~~~',
    //     '00036~~机织婴儿防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00037~~棉锦弹力双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00038~~棉锦弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00039~~XIEBIANGE-半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00040~~T/R拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00041~~PU布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '00042~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00043~~天丝棉梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00044~~粘锦罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00045~~腈纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '00046~~棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00047~~黑腈纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '00048~~白ca~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '00049~~莫代尔坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00050~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00051~~平丝绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00052~~粗纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00053~~平眼车~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00054~~成条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00055~~含芯线纺纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00056~~人造纤维纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00057~~尼龙纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00058~~特级精梳棉网~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00059~~皮棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00060~~人造毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '00061~~拉片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00062~~ID标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00063~~胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '00064~~男士衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00065~~合金对扣哑金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00066~~螺纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00067~~尼龙印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00068~~纯棉穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00069~~男童防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00070~~女童风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00071~~打条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00072~~印花加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00073~~锦涤纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00074~~棉粘色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00075~~四合扣下二件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00076~~染色涤棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00077~~衬布条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00078~~扣攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00079~~四方立绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00080~~女式针织连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00081~~棉制男童防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00082~~化纤制婴儿连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00083~~合纤制婴儿短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00084~~化纤制女童吊带上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00085~~大肚竹节~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00086~~粘锦梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00087~~人棉涤纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00088~~男式机织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00089~~100%涤纶染色机织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00090~~42L四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00091~~38L四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00092~~40L透明扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00093~~染费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00094~~4CM日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00095~~6CM日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00096~~LR附加挂牌补~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00097~~无缝针织坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00098~~女士防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00099~~化纤制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00100~~挂镀手缝扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00101~~挂镀圆环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00102~~珠光扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00103~~棉制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00104~~其他材料制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00105~~人丝人棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00106~~*印刷品*LR商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00107~~*纸制品*箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00108~~喷橡胶扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00109~~针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00110~~价格贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00111~~剪刀标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00112~~挂金吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00113~~挂金带脚扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00114~~小织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00115~~裤钩浅金~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00116~~合金扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00117~~全棉府绸印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00118~~全棉府绸染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00119~~115 3251 220~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00120~~棉涤弹贡缎染色加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00121~~女式针织抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00122~~合纤制女童半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00123~~化纤制婴儿套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00124~~化纤制男童套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00125~~17衣架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00126~~444MS款金属条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00127~~其他制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00128~~铜揿纽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00129~~浅金包圈扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00130~~合金蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00131~~合金扣头哑金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00132~~式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00133~~女士夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00134~~男士夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00135~~羊驼毛条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '00136~~*劳务*女式棉背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00137~~*劳务*女式棉夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00138~~*纺织产品*人造革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '00139~~*纸制品*封箱带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022103~~否~~纸或纸板整理机械~~~~~~',
    //     '00140~~*劳务*女士棉背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00141~~针织平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00142~~雪纺染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00143~~*劳务*压褶加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00144~~透明胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '00145~~*劳务*女式针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00146~~男式针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00147~~合金扣头浅金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00148~~裤钩浅~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00149~~古铜花纹扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00150~~橡筋袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00151~~四孔麻花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00152~~刻字麻花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00153~~四孔珠光扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00154~~包头圆绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00155~~开襟衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00156~~学生装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00157~~富丽绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00158~~女式梭织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00159~~亚麻制男童连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00160~~亚麻制婴儿连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00161~~服装防盗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00162~~棉混纺染色针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00163~~棉染色针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00164~~人纤混纺染色针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00165~~面料HS19W-022564~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00166~~布包日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00167~~合金四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00168~~布包日字扣2色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00169~~烫银网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00170~~标贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00171~~男式梭织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00172~~风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00173~~服装压褶费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00174~~亚麻制婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00175~~亚麻制男童长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00176~~袖绒条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030604~~否~~人造毛皮~~~~~~',
    //     '00177~~斜桃袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00178~~圆形尺码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00179~~矽利康胶章~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040109~~否~~胶水~~~~~~',
    //     '00180~~保护袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00181~~明四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00182~~圆形尺码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00183~~仿贝扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00184~~彩色吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00185~~四孔扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00186~~毛毡布垫片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00187~~波纹扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00188~~古铜切线扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00189~~洗唛,吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00190~~白贝扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00191~~进口标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00192~~包头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '00193~~全棉提花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00194~~尼龙注塑~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '00195~~再生尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00196~~亚麻棉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00197~~麻棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00198~~衣片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00199~~女士针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00200~~其他材料制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00201~~金属双孔卡扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00202~~卡扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00203~~春亚纺四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00204~~三坑纸箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '00205~~轮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00206~~女式针织休闲服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00207~~人棉涤纶染色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00208~~T400~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00209~~日字环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00210~~翻工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00211~~亚麻绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00212~~英国贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00213~~水洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00214~~化纤制女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00215~~半药片扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00216~~打气眼费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00217~~睡裤水洗加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00218~~中裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00219~~全棉巴黎纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00220~~珠链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '00221~~印字织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00222~~1CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00223~~门禁拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00224~~0.5CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00225~~仿丝棉220g~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '00226~~印字橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00227~~羽绒棉150g~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00228~~羽绒棉50g~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00229~~75D平纹仿记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00230~~50g仿羽绒棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00231~~男童连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00232~~200g仿丝棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00233~~100g仿羽绒棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00234~~80g仿羽绒棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00235~~75D平纹仿记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00236~~梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '00237~~RFID标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00238~~女式针织其他上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00239~~男式梭织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00240~~纸卡/贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00241~~衣服半成品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00242~~滚条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00243~~防火标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00244~~纸制品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '00245~~亚麻棉梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00246~~滚条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00247~~剪花梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00248~~花边织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00249~~人纤制女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00250~~人纤制女式吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00251~~合纤制女式吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00252~~黑色水洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00253~~普通日期标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00254~~黑色领吊~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011402~~否~~领带~~~~~~',
    //     '00255~~包装塑料袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '00256~~针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00257~~裁片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00258~~打缆线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00259~~紧身胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00260~~博拉粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010202~~否~~毛纱~~~~~~',
    //     '00261~~晾衣带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00262~~透明胶针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00263~~长套环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00264~~长胶针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00265~~金属双D形环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00266~~女士短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00267~~金属绳头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00268~~女大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00269~~金属D形环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00270~~亚麻纤制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00271~~针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00272~~三角折~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00273~~涤粘弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00274~~女裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00275~~棉制婴儿防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00276~~泰纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00277~~女式罩衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00278~~化纤制婴儿大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00279~~附唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00280~~棕色格子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00281~~女童背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00282~~3CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00283~~印字包边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '00284~~女童连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00285~~全涤针织经编衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00286~~涤棉梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00287~~调节扣浅金色~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00288~~Po标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00289~~副吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00290~~全涤印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00291~~塑钢细齿开口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00292~~全涤仿记忆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00293~~放样费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00294~~化纤面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00295~~斜纹桃皮绒袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00296~~合纤制女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00297~~女式胸罩加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00298~~化纤制女童针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00299~~拉链头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00300~~鱼衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00301~~女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00302~~金属名字牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109032702~~否~~金属制地名牌、号码及类似标志~~~~~~',
    //     '00303~~三挡扣带针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00304~~金属圆环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '00305~~男大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00306~~粘胶弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00307~~金属口字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00308~~全棉色织绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00309~~女式机织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00310~~裤钩亮银~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00311~~全涤双层四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00312~~合金铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00313~~全涤双层四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00314~~毛衣片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00315~~钩编绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '00316~~化纤制女童针织防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00317~~吊勾~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00318~~素布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00319~~小绣花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00320~~印花桃皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00321~~男式梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00322~~服装配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00323~~弹力针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00324~~女式抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00325~~新料白尼龙短纤1.5Dx38mm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00326~~PVC塑料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00327~~透明内扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00328~~女式机织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00329~~PU涂层合纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00330~~古铜蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00331~~包钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00332~~拉花腰袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00333~~背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00334~~提花肩带橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00335~~拷贝纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010302~~否~~转印纸~~~~~~',
    //     '00336~~金属珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108011403~~否~~室内及室内装饰用玻璃器具~~~~~~',
    //     '00337~~仿绒衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00338~~塑料片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00339~~女童防寒背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00340~~提花肩带橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00341~~复合棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00342~~粘涤弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00343~~再生布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00344~~锦涤棉弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '00345~~不粘衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00346~~男式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00347~~挂镀腰夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00348~~挂镀汽眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '00349~~布包扣头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00350~~女士梭织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00351~~塑料插扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00352~~针织麂皮绒底~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00353~~精梳棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '00354~~全棉色织府绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00355~~全棉色织平纹织物~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00356~~全棉府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00357~~R/T斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00358~~TR弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00359~~仿贝壳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00360~~衣服后道整理费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00361~~纽扣攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00362~~服装女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00363~~圆珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00364~~尼龙起绉PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030202~~否~~再生皮革~~~~~~',
    //     '00365~~300T尼丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00366~~杜邦棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00367~~树脂拉花D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00368~~金属扁圆环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00369~~布包汽眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00370~~毛制女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00371~~合纤制婴儿针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00372~~合纤制婴儿针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00373~~化纤制女式防寒服上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00374~~化纤制女式防寒服上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00375~~男式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00376~~男式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00377~~雪纺衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00378~~检查标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00379~~肩垫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '00380~~安全标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00381~~仿记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00382~~吊珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108011403~~否~~室内及室内装饰用玻璃器具~~~~~~',
    //     '00383~~外箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '00384~~色带丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00385~~服装女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00386~~服装连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00387~~1cm织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00388~~小珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '00389~~消光尼丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00390~~胸袋拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00391~~侧口袋拉链2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00392~~金属链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00393~~侧口袋拉链1~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00394~~杜邦球棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00395~~尼龙牛津纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00396~~0.3cm圆橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00397~~半光尼丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00398~~仿羽绒吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00399~~防寒背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00400~~防寒背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00401~~婴儿上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00402~~阳涤拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00403~~婴儿上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00404~~75D雪纺珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00405~~挂镀吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00406~~透明嵌条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '00407~~发圈加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00408~~棉制女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00409~~棉制婴儿针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00410~~女童连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00411~~女式连体短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00412~~合纤制女式半裙后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00413~~烫钻织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00414~~粘胶原料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '00415~~圆眼机(兄弟RH-9820)~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00416~~电脑钉扣机富山HK2903SS（装钮机）~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00417~~圆眼机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00418~~电脑钉扣机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00419~~全涤色织针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00420~~隐型拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00421~~四面弹PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00422~~涤纶弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00423~~塑料夹子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00424~~仿贝壳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00425~~挂镀D形腰夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00426~~儿童针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00427~~斜纹桃皮绒垫布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00428~~标类~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00429~~绒感仿记忆~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00430~~黑丝绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00431~~防棉衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00432~~羊羔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~101030305~~否~~动物毛类~~~~~~',
    //     '00433~~双层弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00434~~金属双眼绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '00435~~府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00436~~装饰布条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00437~~服装加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00438~~纸制品附加费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00439~~拉链5#尼龙闭口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00440~~童装棉衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00441~~回收料环保胶针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00442~~拉链5#尼龙单开~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00443~~T/C 涤棉染色平纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00444~~T/C涤棉染色平纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00445~~背心棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00446~~棉衣背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00447~~棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00448~~全棉绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00449~~脚口闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00450~~门禁闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00451~~侧门禁拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00452~~脚口片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00453~~加厚橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00454~~男童背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00455~~2.5CM肩带橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00456~~针织织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00457~~寸板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '00458~~3#尼龙拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00459~~5#隐形拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00460~~棉氨汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00461~~纱卡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00462~~PU礼盒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030303~~否~~皮革作面类似箱、包容器~~~~~~',
    //     '00463~~合纤制男童针织手套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00464~~合纤制男式针织手套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00465~~化纤制男式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00466~~粘涤印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00467~~印字弹力肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '00468~~转移标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00469~~拉盼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00470~~裤钩工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00471~~古铜扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00472~~磨毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00473~~尺码粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00474~~棉质女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00475~~30D牙签条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00476~~隐形格子麦尼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00477~~深灰斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00478~~装饰布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00479~~拉毛格子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00480~~260T尼龙绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00481~~梭织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00482~~男式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00483~~PU 涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00484~~化纤制女式梭织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00485~~带子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00486~~棉锦弹力平纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00487~~涤纶高弹线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00488~~塑胶衣架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00489~~空心绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00490~~女式针织连体短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00491~~灯心绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00492~~针织灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00493~~男式针织夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00494~~男款裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00495~~男款短袖T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00496~~男款外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00497~~梯形标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00498~~女士皮裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00499~~双面粘胶衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00500~~女童裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00501~~口袋拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00502~~拷贝纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00503~~化纤制针织女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00504~~金属D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050210~~否~~金属制艺术标牌及类似品~~~~~~',
    //     '00505~~尼龙牛津布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00506~~复合网眼布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00507~~毛制男式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00508~~棉麂皮色胶涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00509~~帽毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010201~~否~~毛条~~~~~~',
    //     '00510~~激光打孔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00511~~胸标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00512~~防棉压光~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00513~~小红标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00514~~锦涤布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00515~~四孔树脂纽扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00516~~橡皮塞~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00517~~防水标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00518~~斜纹桃皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00519~~印花带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00520~~后整理费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00521~~口袋版~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00522~~女式针织带帽衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00523~~女士半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00524~~嵌条绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '00525~~数码~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00526~~水印~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00527~~收塞棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00528~~拉链闭口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00529~~包绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00530~~男士上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00531~~毛衫片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00532~~支架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00533~~印花PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00534~~摆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00535~~棉衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00536~~弹力包边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00537~~硅油尼龙衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00538~~熨斗贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00539~~再生纤维210T~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040399~~否~~其他合成纤维~~~~~~',
    //     '00540~~熨斗贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00541~~拉链开口~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00542~~女童针织带帽衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00543~~卡套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00544~~女童针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00545~~梭织人造革~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '00546~~服装后道整理费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00547~~女童针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00548~~女婴针织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00549~~针织氨纶法式罗纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00550~~男式针织开衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00551~~双面衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00552~~兰精粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00553~~男式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00554~~65857~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00555~~金属包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00556~~带扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00557~~里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00558~~里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00559~~PU涂层化纤制女式防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00560~~女式机织休闲服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00561~~女式机织休闲服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00562~~肩棉条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '00563~~女童针织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '00564~~织带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00565~~女童针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00566~~女婴针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00567~~对丝加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00568~~120g仿羽绒棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00569~~5号树脂门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00570~~5号树脂口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00571~~220g仿丝棉双面复合~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00572~~150g仿羽绒棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00573~~8号金属门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00574~~8号金属口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00575~~男童羽绒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00576~~劳务印花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00577~~5号防水口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00578~~10号树脂侧缝闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00579~~劳务印花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00580~~3.7CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00581~~10号树脂内门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00582~~10号树脂门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00583~~0.8CM白橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00584~~5号尼龙开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00585~~10号树脂袖口闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00586~~5号防水口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00587~~10号树脂门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00588~~5.5CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00589~~仿绒衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00590~~婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00591~~纸标签~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00592~~里襟标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00593~~检验标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00594~~毛衣款~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00595~~4.5CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00596~~面料样品费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00597~~塑料揿纽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00598~~布包四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00599~~挂镀D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00600~~包布四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00601~~款号标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00602~~涤弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00603~~女式机织休闲服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00604~~合纤制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00605~~绗线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00606~~纽扣织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00607~~粘锦涤梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00608~~女式针织皮抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00609~~尼龙涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00610~~金属工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00611~~线扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00612~~腰袢扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00613~~袖袢扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00614~~吊牌穿针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '00615~~男式梭织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00616~~裤型牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00617~~140G棉涤汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00618~~雪尼尔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00619~~麂皮空气层印花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00620~~棉制女式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00621~~210T里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00622~~装饰织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00623~~婴儿连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00624~~婴儿连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00625~~婴儿连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00626~~0.6CM金属珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00627~~0.7CM气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00628~~1.4CMD型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00629~~1.4CM四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00630~~1.4CM猪鼻扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00631~~1.2CM四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00632~~4CM金属插扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00633~~0.6CM金属珠106~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00634~~0.7CM气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00635~~1.4CM四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00636~~1.4CM猪鼻扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00637~~全麻梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00638~~泡泡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00639~~隔板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '00640~~筋线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00641~~全涤牛津纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00642~~印花涤丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00643~~尺码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00644~~合纤制女士短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00645~~行缝棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00646~~行缝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00647~~女式梭织皮背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00648~~针织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00649~~针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00650~~针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00651~~梭织短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00652~~再生纤维210T里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00653~~梭织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00654~~梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00655~~人纤制女式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00656~~翻条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00657~~对扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00658~~细边扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00659~~篮球扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00660~~防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00661~~喷漆扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00662~~麻花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00663~~塔夫绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00664~~猫眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00665~~服装烫钻加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00666~~礼盒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030303~~否~~皮革作面类似箱、包容器~~~~~~',
    //     '00667~~不干胶贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00668~~金属主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '00669~~化纤制女式夹棉外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00670~~双头绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00671~~灯芯绒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00672~~仿牛角扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00673~~刻字扣子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00674~~女式中裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00675~~小包加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00676~~化纤制女式夹棉马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00677~~内胆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00678~~健康标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00679~~平桃布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00680~~绳尖~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00681~~粗纺毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '00682~~涤塔夫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00683~~女婴针织连帽套衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00684~~女童针织连帽套衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00685~~金属铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00686~~女式机织连体紧身衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00687~~圆形尺码贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00688~~白棉绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00689~~门禁织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '00690~~彩色橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00691~~嵌绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00692~~走马带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '00693~~后约克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00694~~边条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00695~~棉058~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00696~~女式挂脖胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00697~~棉粘四面弹坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00698~~全涤印花涤塔夫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00699~~贴膜雪梨纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00700~~涤棉面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00701~~单平寸板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010511~~否~~箱板纸~~~~~~',
    //     '00702~~蝴蝶结~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00703~~女式PU半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00704~~编条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00705~~化纤制男式内衣套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00706~~化纤制童款内衣套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00707~~化纤制男式针织围巾加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00708~~化纤制女式内衣套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00709~~人棉弹力罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00710~~粘氨汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00711~~婴儿套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00712~~全棉磨毛汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00713~~透明胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '00714~~PU标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00715~~小缸费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00716~~金丝织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00717~~金属肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '00718~~防水织唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00719~~植绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00720~~起毛斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00721~~格子绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00722~~硬涤纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00723~~领标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00724~~牵条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00725~~可回用涤纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00726~~涤纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00727~~毛制式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00728~~合纤制女士长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00729~~合纤制女士长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00730~~皮短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00731~~尺码吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00732~~风机扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00733~~女童针织皮短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00734~~55% RWS羊毛 45%棉 2/30nm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00735~~压花加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00736~~仿记忆涂层~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00737~~绗缝加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00738~~棉弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00739~~棉氨府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00740~~女式棉马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00741~~涤纶植绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00742~~女式棉马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00743~~颗粒绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00744~~男式针织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00745~~针刺匹染毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00746~~男式针织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00747~~NR罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00748~~TR 弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00749~~筒子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00750~~粘胶弹力印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00751~~粘锦青年布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00752~~全涤色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '00753~~全涤雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00754~~女上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00755~~精纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00756~~女士裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00757~~梭织男长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00758~~涤纶弹力梭织色织纬起绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00759~~小泡泡色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '00760~~40g仿丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00761~~化纤制女式吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00762~~人纤制女士短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00763~~男童机织背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00764~~男童机织背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00765~~针织螺纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00766~~白色全棉毛圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00767~~硫化黑全棉毛圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00768~~白色全棉汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00769~~氨纶罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00770~~硫化黑全棉汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00771~~防伪纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00772~~棉涤弹力布坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00773~~粘衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '00774~~织带贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00775~~羽绒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00776~~PU挂耳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00777~~人棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00778~~雪纺珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00779~~牙签条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00780~~化纤制婴儿针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00781~~化纤制婴儿针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00782~~化纤制婴儿针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00783~~针织长袖套衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00784~~针织婴儿背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00785~~PU涂层化纤制女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00786~~女式PU短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00787~~反光带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00788~~针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00789~~包布领钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00790~~连身裤/游戏裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '00791~~儿童外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00792~~儿童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00793~~婴儿服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00794~~儿童裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00795~~人纤制女式针织短裙后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00796~~化纤制女婴防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00797~~PU皮标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030599~~否~~其他相关皮革制品~~~~~~',
    //     '00798~~硅胶套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021399~~否~~其他橡胶~~~~~~',
    //     '00799~~毛毯加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00800~~平雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00801~~男童连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00802~~男婴连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00803~~男婴连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00804~~女童背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00805~~绳索~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00806~~调色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00807~~洋装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00808~~毛线帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '00809~~PU涂层化纤制女式针织胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00810~~化纤制女式针织连衣裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '00811~~胸垫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020302~~否~~毛皮服装附件~~~~~~',
    //     '00812~~PU涂层化纤制女式针织半身长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00813~~扣眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00814~~女童套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00815~~合纤制女式针织吊带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00816~~化纤制女式针织肚兜加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00817~~涤纶氨纶针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00818~~女童针织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00819~~涤粘弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00820~~羊羔绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00821~~人纤制女式针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '00822~~化纤制女式防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '00823~~麻粘梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00824~~扣拌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00825~~金属汽眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080417~~否~~家具、建筑用金属附件及架座；金属制建筑装饰及其零件~~~~~~',
    //     '00826~~梭织女大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00827~~化纤制女式内衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00828~~化纤制男士内衣套装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00829~~链条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00830~~手工穿珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00831~~胶带贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00832~~贴膜春亚纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00833~~袖口织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00834~~化纤制女士夹棉外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00835~~化纤制女士夹棉外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00836~~色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00837~~贴膜加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00838~~化纤制女士夹棉马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00839~~化纤制女士夹棉马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00840~~棉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00841~~毛衫配件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00842~~弹力八枚缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00843~~其他制女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00844~~全涤梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00845~~仿富丽绉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00846~~帽攀~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00847~~四面弹复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00848~~尼龙通道布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00849~~手工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00850~~PU女式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00851~~连体衣白胚~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00852~~连衣裙白胚~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00853~~女式梭织西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00854~~网钻袖筒加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00855~~棉扁绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00856~~120G针棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~1010105~~否~~棉花~~~~~~',
    //     '00857~~帽织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00858~~全棉色织梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00859~~麻粘印花梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00860~~化纤制女式针织睡衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00861~~锦纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '00862~~胶短纤维~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '00863~~男式针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '00864~~染色斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00865~~罗纹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00866~~围兜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00867~~春亚纺压花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00868~~再生棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00869~~侧封标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00870~~涤粘弹力针织色织拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00871~~无妨衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011102~~否~~无纺织物制品~~~~~~',
    //     '00872~~女式针织长袖套衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00873~~男式针织裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00874~~棉制女式针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00875~~全棉格子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00876~~梭织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00877~~全棉格子面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00878~~染色剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021102~~否~~用作发光体有机、无机产品~~~~~~',
    //     '00879~~弹力色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00880~~棉坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00881~~全涤雪尼尔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00882~~有光弹力色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00883~~衬衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00884~~铜管挂链装饰品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00885~~棉弹梭织童裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00886~~彩色挂牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00887~~洗标贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00888~~平面蘑菇扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00889~~梭织男上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00890~~金属吊链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '00891~~钢圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00892~~条码纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00893~~防伪贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00894~~棉弹力染色斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00895~~其他制女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00896~~梭织男大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00897~~棉制女童防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00898~~化纤制女童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00899~~棉制婴儿防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00900~~化纤制婴儿防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00901~~棉制婴儿防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00902~~棉制女童防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00903~~化纤制女童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00904~~针织背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00905~~塑料珠子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '00906~~亚麻制女式裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00907~~亚麻制女士裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00908~~旗帜标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00909~~针刺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00910~~TR罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00911~~粗针磨毛汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00912~~涤氨针织乱麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00913~~男式夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00914~~女西服上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00915~~斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00916~~涤纶针织染色起绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00917~~大裤钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00918~~梭织女上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00919~~色丁里料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00920~~人棉弹力~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '00921~~麻粘印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00922~~粘胶腈纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00923~~涤弹里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00924~~烫钻带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00925~~嵌线绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00926~~面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00927~~刚打OK~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00928~~人纤制女式针织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00929~~井字架~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '00930~~人纤制女式针织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00931~~双面乱花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00932~~黑色胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '00933~~防滑带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00934~~棉涤弹双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00935~~女童机织防寒马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00936~~机织婴儿防寒马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00937~~棉制女式针织裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00938~~化纤制女童无袖防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00939~~全涤纶毛呢布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00940~~侧标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00941~~梭织男棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00942~~梭织男夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00943~~可回用涤纶印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00944~~罗纹袖~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00945~~棉花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '00946~~连身装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '00947~~女式PU吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00948~~PU涂层化纤制女式针织半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00949~~装饰织带标2~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00950~~包头橡筋绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '00951~~7号尼龙口袋防水闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00952~~加厚织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '00953~~钥匙扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '00954~~7号尼龙门襟防水开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00955~~打样加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00956~~中厚橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '00957~~网布植绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00958~~水性PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060108~~否~~塑料人造革、合成革~~~~~~',
    //     '00959~~衣服后道整理~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00960~~全涤斜纹美丽绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00961~~中斜纹美丽绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00962~~色纺涤粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00963~~黑色花边~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019905~~否~~图案花边~~~~~~',
    //     '00964~~女式机织罩衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00965~~机织婴儿防寒马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00966~~女童机织防寒马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '00967~~棉制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00968~~棉制女式针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00969~~棉制婴儿上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00970~~PU胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00971~~电源稳压器~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109040307~~否~~电动机零件~~~~~~',
    //     '00972~~女童机织防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00973~~棉制女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00974~~全涤双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00975~~T/C府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00976~~袋鼠侧标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00977~~/C朝阳格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00978~~袋鼠商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '00979~~T/C朝阳格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00980~~棉尼龙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107021304~~否~~合成纤维聚合物~~~~~~',
    //     '00981~~仿麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00982~~尼龙起泡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00983~~梭织花式布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00984~~7号尼龙防水门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00985~~7号尼龙防水口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00986~~仿羽绒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '00987~~机织女式长袖衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00988~~全涤粗花呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00989~~5号尼龙门襟开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00990~~装饰标4~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00991~~装饰标3~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00992~~包头橡胶绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '00993~~字母标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00994~~5号普通树脂口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00995~~8号树脂门襟方牙开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '00996~~离心纸面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '00997~~字母标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '00998~~男风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '00999~~女士短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01000~~男式西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01001~~拉链衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01002~~RN面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01003~~女童梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01004~~热转移标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01005~~空芯绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '01006~~竖条绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01007~~5号金属门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01008~~塑料托盘~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '01009~~箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '01010~~袖扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01011~~服装后整费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01012~~棉涤色织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01013~~流苏绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01014~~棉粘色纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01015~~棉粘色纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01016~~女童防寒马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01017~~球棉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~104010101~~否~~已梳皮棉~~~~~~',
    //     '01018~~印字罗纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01019~~印字罗纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01020~~亚麻制女式吊带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01021~~亚麻制女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01022~~纽扣膜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~10706010102~~否~~其他塑料薄膜~~~~~~',
    //     '01023~~人纤制女式套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01024~~线袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01025~~亚麻棉粘布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01026~~打头绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01027~~棉弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01028~~亚麻制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01029~~化纤制女式针织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01030~~样卡册~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '01031~~涤粘色纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01032~~男式针织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '01033~~男式针织休闲西服上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01034~~热熔线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01035~~人棉罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01036~~人棉汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01037~~梭织丝绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01038~~其他材料制女士大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01039~~PU女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01040~~亚麻制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01041~~色丁印花带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01042~~衣架吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01043~~挂镀Z字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01044~~模具费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021199~~否~~其他模具~~~~~~',
    //     '01045~~链条车~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '01046~~切割攀丁机~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '01047~~开袋机~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '01048~~裤腿开缝~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109023001~~否~~缝纫机及其零件~~~~~~',
    //     '01049~~棉粘麻色纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01050~~荧光黄不干胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050402~~否~~橡胶粘带~~~~~~',
    //     '01051~~燥剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~103021199~~否~~其他食品添加剂~~~~~~',
    //     '01052~~女裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01053~~男短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01054~~女短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01055~~塑料包装袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '01056~~男裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01057~~人纤制女式针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01058~~化纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01059~~挂镀三角形~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '01060~~超弹色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01061~~男式针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020117~~否~~男大衣、男风衣~~~~~~',
    //     '01062~~尼丝纺加涂层面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01063~~尼丝纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01064~~小包标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01065~~小包吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01066~~塔丝绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01067~~镶色面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01068~~男式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01069~~双面棉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01070~~全棉蕾丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01071~~化纤制婴儿防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01072~~裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01073~~橡筋2cm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01074~~素色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01075~~橡筋0.6cm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01076~~全棉巴厘纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01077~~全涤斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01078~~亚麻制女式挂脖抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01079~~2孔古铜扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01080~~背带裙服装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01081~~粘胶胚筒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01082~~毛圈针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01083~~女童梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01084~~女婴针织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01085~~女婴梭织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01086~~女婴梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01087~~女童针织连体短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01088~~女婴针织连体短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01089~~男式针织夹克衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01090~~花边布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01091~~棉粘弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01092~~棉制西装马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01093~~涤氨针织乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01094~~小标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01095~~衬条5820~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01096~~衬条0.3白纱带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01097~~棉制女式针织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01098~~后道整理加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01099~~纺织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01100~~30G硬涤纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01101~~针织卫衣布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01102~~腈纶涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01103~~OSTIN成份标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01104~~OSTIN贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01105~~OSTIN价格贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01106~~OSTIN剪刀标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01107~~黏胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01108~~三友环保粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011101~~否~~无纺布（无纺织物）~~~~~~',
    //     '01109~~胸花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '01110~~人纤制女式针织吊带连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01111~~粘胶弹力汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01112~~亚麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010303~~否~~亚麻布~~~~~~',
    //     '01113~~乳胶松紧~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01114~~饰品扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01115~~摇绞加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01116~~绳子包头古铜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01117~~4CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01118~~D型环/日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01119~~3.5CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01120~~装饰四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01121~~天丝梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01122~~天丝梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01123~~化纤制女式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01124~~TR弹汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01125~~人棉弹力汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01126~~牛皮贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010599~~否~~其他纸制品~~~~~~',
    //     '01127~~平纹里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01128~~人棉锦纶印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01129~~仿麻雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01130~~白色吊粒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '01131~~仿雪麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01132~~涤纶染色烫金布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01133~~棉制女式针织半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01134~~冷冻去骨牛前部位肉90VL~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01135~~冷冻去骨牛后部位肉90VL~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01136~~化纤制女式无袖马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01137~~人纤制女式针织半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01138~~背扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01139~~弹力肩带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '01140~~上机费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~202~~否~~修理修配劳务~~~~~~',
    //     '01141~~5号尼龙防水哑光口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01142~~5号尼龙防水哑光门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01143~~8号树脂门禁开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01144~~5号尼龙防水口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01145~~美利奴羊毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010205~~否~~特种羊毛或动物细毛织物~~~~~~',
    //     '01146~~棉涤坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01147~~铆钉扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01148~~棉制针织女童T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01149~~女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01150~~麂皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '01151~~棉制男式短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01152~~化纤制女式无袖风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01153~~螺纹带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '01154~~合纤制女式长裤后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01155~~d=2.5cm拉花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01156~~d=2cm 二眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01157~~环保粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01158~~空气层~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01159~~棉麻绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01160~~双排扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01161~~麻粘弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01162~~色织记忆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01163~~T/R条纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01164~~100%粘胶胚筒纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01165~~100%尼龙猫毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01166~~男式裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01167~~合纤制婴儿大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01168~~化纤制女童防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01169~~主标尺码~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '01170~~100%兰精粘胶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01171~~亚克力钥匙扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01172~~兰精环保粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '01173~~女婴针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01174~~女婴针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01175~~合纤制男婴防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01176~~针织毛绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01177~~段彩罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01178~~夹棉空气层~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01179~~女婴针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01180~~女婴针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01181~~男婴针织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01182~~男童针织外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01183~~女式梭织T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01184~~转角贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01185~~麻棉织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01186~~海岛缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01187~~冷冻去骨缺失牛肉90CL~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01188~~拉绒底PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01189~~消光涤丝纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01190~~斜纹毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01191~~斜纹磨毛桃皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01192~~涤塔夫反面压光~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01193~~单孔绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01194~~三友环保白粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '01195~~三友环保黑粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '01196~~化纤制女式抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01197~~合纤制婴儿针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01198~~棉制婴儿棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01199~~合纤制针织女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01200~~短毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01201~~衬布不粘衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01202~~男式羽绒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01203~~空白吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01204~~牛津仿记忆面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01205~~双面复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01206~~全涤灯芯绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01207~~手穿针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01208~~男马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01209~~RI-377~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01210~~男夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01211~~细斜纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01212~~水洗皱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01213~~汽眼包钮~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01214~~衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01215~~棉制婴儿针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01216~~棉制女童针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01217~~全涤网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01218~~男童半身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01219~~二维码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01220~~铭字牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01221~~配比贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01222~~防滑硅胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '01223~~防绒布胆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01224~~白鸭绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '01225~~涤丝纺里料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01226~~涤丝纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01227~~金属双孔绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01228~~男士梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01229~~人棉锦纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01230~~涤弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01231~~棉弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01232~~PU女式吊带衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01233~~PU女式胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01234~~男式梭织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01235~~冷冻去骨牛肉冷冻牛腩~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01236~~人纤制女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01237~~PU女式裙裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01238~~尼龙人棉压皱布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01239~~带脚扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01240~~60/40灰鸭绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '01241~~梭织丝绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124201~~*罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124202~~重型裁床~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124203~~松布机~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124204~~粘机进料辅助台~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124205~~开包台~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124206~~裁片领料框~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124207~~辅工桌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108040399~~否~~其他金属切削工具~~~~~~',
    //     '0124208~~粘涤梭枳布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124209~~100%再生尼龙梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124210~~机织女式长袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '0124211~~涤毛精纺染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124212~~电子标签通道门禁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109061699~~否~~其他光学、电子测量仪器~~~~~~',
    //     '0124213~~透明挂衣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '0124214~~纺织产品*毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010204~~否~~毛机织物（呢绒）~~~~~~',
    //     '0124215~~租金~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~3040502020101~~否~~公共住房租赁~~~~~~',
    //     '0124217~~雇主责任险~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~306030199~~否~~其他人身保险服务~~~~~~',
    //     '0124218~~法定传染病身故或伤残保险~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~306030199~~否~~其他人身保险服务~~~~~~',
    //     '0124219~~防嗮衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '0124220~~防晒衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01243~~棉制女式针织半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01244~~真丝布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01245~~4孔树脂盆型扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01246~~浴帽加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01247~~4孔仿贝扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01248~~高弹雪纺格~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01249~~新吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01250~~尺码织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01251~~TR弹力斜纹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01252~~婴儿针织套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01253~~PU女式半裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01254~~PU女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01255~~棉涤麂皮绒色胶涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01256~~冷冻去骨牛龟腱带腱心~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01257~~冷冻去骨牛前腱~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01258~~冷冻去骨牛后腱~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01259~~腈纶纤维3DX64mm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040303~~否~~腈纶纤维~~~~~~',
    //     '01260~~回料涤纶纤维2.5DX51mm~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040302~~否~~涤纶纤维~~~~~~',
    //     '01261~~100%三友环保涡流纺粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040202~~否~~人造纤维长丝~~~~~~',
    //     '01262~~PU涂层化纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01263~~翻条加工~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01264~~全涤羊羔绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01265~~色丁带挂耳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01266~~0.6cm橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01267~~全棉挂耳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01268~~纺针刺棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01269~~黄色胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '01270~~蓝色胶带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1070502~~否~~橡胶带、管~~~~~~',
    //     '01271~~罗文反光织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01272~~水洗绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01273~~功能吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01274~~兰精环保粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '01275~~PU涂层化纤制女式半身长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01276~~平桃口袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01277~~牛仔面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01278~~棉莫代尔氨纶汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01279~~粗纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01280~~毛制女式短大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01281~~涤纶网布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01282~~女式梭织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01283~~网单贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01284~~跟踪标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01285~~小圆珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01286~~帆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01287~~塑料针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '01288~~210T涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01289~~PU女式衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01290~~冷冻去骨牛臀肉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01291~~弹力色丁布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01292~~腰链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080427~~否~~金属链条及零件~~~~~~',
    //     '01293~~100%美利奴羊毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010205~~否~~特种羊毛或动物细毛织物~~~~~~',
    //     '01294~~钮袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '01295~~仿丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01296~~麦穗绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01297~~女童外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01298~~男童衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01299~~粘锦弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01300~~粘锦弹染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01301~~哑光弹力色丁~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01302~~粘涤梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01303~~冷冻去骨牛肉冷冻牛腩80VL~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01304~~粘锦涤梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01305~~粘锦涤弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01306~~化纤制女童针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01307~~女童机织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01308~~机织婴儿短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01309~~条码贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01310~~牛皮箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01311~~喷漆工字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01312~~牵带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01313~~PU涂层合纤制女式针织吊带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01314~~针织婴儿长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01315~~合纤制婴儿针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01316~~针织婴儿连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01317~~针织婴儿马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01318~~男童针织马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01319~~涤锦纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01320~~明扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01321~~双孔绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01322~~主标-织造防伪商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01323~~独码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01324~~贴布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01325~~胶袋条码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01326~~口袋织标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01327~~290T涤塔夫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01328~~2孔麻花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01329~~沙溪绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01330~~纺麻雪纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01331~~4孔扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01332~~水洗绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01333~~2CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01334~~反光织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '01335~~斜纹绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01336~~斜方格绗棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01337~~全涤拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01338~~四合扣CD件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01339~~皮革面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01340~~涤纶印花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01341~~涤锦纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01342~~尼龙包胶扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01343~~男婴针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01344~~男婴梭织衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01345~~1.2四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01346~~金属包头帽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01347~~2.8CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01348~~0.6汽眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01349~~2孔调节扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01350~~茶色麻花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01351~~天丝仿牛仔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01352~~珠片流苏面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01353~~砂洗格子布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01354~~女童机织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01355~~机织婴儿连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01356~~门襟扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01357~~圆形金属牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01358~~婴儿裤子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01359~~0.8刻字铆钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01360~~1.4刻字四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01361~~金属珠织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01362~~1.5cm橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01363~~全涤织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01364~~0.2CM内滚棉绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01365~~男童羊毛大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01366~~国际单贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01367~~5CM提花橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01368~~2CM包边橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01369~~300T消光尼丝仿亮PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01370~~针织印字织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01371~~全涤麂皮绒色胶涂层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01372~~扣伴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01373~~PU涂层化纤制女式抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01374~~化纤制女式连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01375~~仿铜氨罗纹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01376~~粘锦涤弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01377~~兰精粘胶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040201~~否~~人造纤维短纤维~~~~~~',
    //     '01378~~女式长裤后道加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01379~~2cm气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01380~~尼龙绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01381~~注意吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01382~~女短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01383~~女西服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01384~~人丝布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01385~~PU涂层人纤制女式针织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01386~~针织婴儿连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01387~~针织婴儿T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01388~~女童针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01389~~针织婴儿马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01390~~男童针织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01391~~针织婴儿长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01392~~男童针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01393~~机织婴儿长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01394~~女童机织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01395~~棉制婴儿套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01396~~棉制女童套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01397~~女童机织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01398~~舒绒棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01399~~塑料绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01400~~拉绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01401~~塑料扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01402~~金属腰带扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01403~~粘涤弹力色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01404~~金属绳尖~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01405~~涤帽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01406~~西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '01407~~服装打样费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01408~~女式PU短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01409~~裤夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01410~~棉制男式长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01411~~两孔绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01413~~贝壳标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '01414~~PU涂层人纤制女式针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01415~~PU涂层人纤制女式针织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01416~~化纤制女式针织吊带衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01417~~机织婴儿连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01418~~女童机织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01419~~弹力缎~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01420~~PU复合针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01421~~挂镀揿扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01422~~针织服装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '01423~~1.1cm气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01424~~2cm金属绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01425~~消光尼丝仿亮PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01426~~2.2cm气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01427~~下摆穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01428~~手塞~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019901~~否~~纺织材料絮胎及其制品~~~~~~',
    //     '01429~~1.5cm金属四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01430~~领口织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01431~~麻棉起毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01432~~1.3cm绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01433~~复合针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01434~~复合PU~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01435~~女式套衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01436~~PU涂层人纤制女式针织胸衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01437~~PU女式上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01438~~棉制女式背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01439~~化纤制男童防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01440~~棉制女童连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01441~~手工订爪链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106050299~~否~~其他金属工艺品~~~~~~',
    //     '01442~~织带风纪扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01443~~PU女式马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01444~~PU女式风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01445~~1.1cmD型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01446~~1.5cm树脂四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01447~~粘锦印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01448~~钻链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01449~~PU涂层化纤制女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01450~~3.2cm口字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01451~~3.2cm日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01452~~2.5cm日字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01453~~条纹针织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01454~~男童防寒连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01455~~3cm金属插扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01456~~1cm四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01457~~1.5cm绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01458~~1.4cmD型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01459~~印字穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01460~~0.2CM圆橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01461~~3cm树脂插扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01462~~印字针织带穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01463~~全涤弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01464~~女式防寒外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01465~~1.5cm四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01466~~梭织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01467~~涤纶提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01468~~棉竹节~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01469~~西裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '01470~~特殊主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01471~~板材~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~105010101~~否~~普通锯材~~~~~~',
    //     '01472~~PU涂层合纤制女式针织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01473~~PU涂层合纤制女式针织连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01474~~全涤针刺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01475~~皮绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01476~~羊角扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01477~~书包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01478~~桃皮绒面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01479~~涤网珠片面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01480~~女婴套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01481~~硅胶标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01482~~女式棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01483~~防寒上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01484~~封口贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01485~~黑色针织毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01486~~刻字扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01487~~金属吊领~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01488~~男上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01489~~腰衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '01490~~涤棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01491~~全涤网眼布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01492~~桃皮绒口袋布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01493~~印花织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01494~~化纤制女式吊带衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01495~~涤棉粘弹力针织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01496~~鱼骨帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '01497~~领袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019999~~否~~其他未列明纺织产品~~~~~~',
    //     '01498~~棉涤弹梭织布染色加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01499~~拉链挂件~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01500~~针织染色起绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01501~~皇帝绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010203~~否~~绒线~~~~~~',
    //     '01502~~铜制调节扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01503~~针刺拉毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01504~~全棉起绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01505~~4孔树脂扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01506~~弹力滚边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01507~~仿毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01508~~2孔药片扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01509~~橡筋纽襻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01510~~女吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01511~~晾衣绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01512~~机打模~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109021199~~否~~其他模具~~~~~~',
    //     '01513~~粘麻梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01514~~单边绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01515~~套色费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01516~~TR汗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01517~~兰精粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01518~~空白小吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01519~~棉帽绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01520~~尼龙纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01521~~中斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01522~~双层树脂拉花扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01523~~化纤制针织女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01524~~全涤小兔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01525~~海绵片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01526~~袖套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01527~~压衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '01528~~合纤制女式钩编长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01529~~双色绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01530~~毛绒复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01531~~细斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01532~~女式羽绒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01533~~防水尼龙面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01534~~GRS仿羽棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01535~~GRS平棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01536~~包棉衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '01537~~全涤仿毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01538~~粗纺毛呢面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01539~~全涤针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01540~~弹力缎面~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01541~~全自动运模机~~~~~~0.03~~~~~~0.0~~False~~0000000000~~False~~109012999~~否~~其他利用温度变化加工机械~~~~~~',
    //     '01542~~防盗芯片贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01543~~化纤制女童风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01544~~针织手套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01545~~棉竹节纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01546~~风机扣带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01547~~羽毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030701~~否~~加工填充用羽毛~~~~~~',
    //     '01548~~白蜡绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010999~~否~~其他纤维纺制线、绳、索、缆~~~~~~',
    //     '01549~~男式针织防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01550~~女童机织长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01551~~条衬布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01552~~单丝人棉汽流纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01553~~亚麻纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01554~~打褶加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01555~~复合费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01556~~短兔毛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010205~~否~~特种羊毛或动物细毛织物~~~~~~',
    //     '01557~~单平纸箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010501~~否~~纸和纸板制容器~~~~~~',
    //     '01558~~口袋片加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01559~~古银吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01560~~金色吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01561~~博拉环保粘胶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01562~~主标贴布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01563~~双面呢面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01564~~单丝人棉印花~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01565~~男童套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01566~~婴儿套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01567~~字母带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01568~~1.4CM布包扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01569~~杜邦平棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01570~~婴儿睡袋加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01571~~婴儿连体棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01572~~1.0CM四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01573~~棉涤弹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01574~~备纽标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01575~~条形码贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01576~~冷冻带骨牛肉冷冻牛羽骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01577~~冷冻带骨牛肉冷冻牛肩胛骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01578~~冷冻带骨牛肉冷冻牛膝骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01579~~冷冻带骨牛肉冷冻牛胸骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01580~~冷冻带骨牛肉冷冻牛肋眼骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01581~~冷冻带骨牛肉冷冻牛西冷骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01582~~冷冻带骨牛肉冷冻牛脖骨~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01583~~单孔绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01584~~涂层面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01585~~印花里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01586~~全涤涂层印花面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01587~~粘涤锦弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01588~~粘锦麻弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01589~~挂绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01590~~PU女式短裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01591~~斜纹纱卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01592~~吊带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01593~~单肩包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '01594~~弹力牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01595~~7CM橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01596~~小熊头主标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01597~~婴儿背带裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01598~~反光滚边条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01599~~男童外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01600~~1.6CM气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~108041401~~否~~钢铁制紧固件~~~~~~',
    //     '01601~~男童外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01602~~涤塔夫面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01603~~后道整理~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01604~~女式PU西装加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01605~~OSTIN洗标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01606~~女长裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01607~~荧光贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01608~~PU涂层化纤制女式针织长裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01609~~机织婴儿罩衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01610~~女童机织罩衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01611~~抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01612~~挂牌贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01613~~包头绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01614~~空气层面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01615~~吊带加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01616~~锦粘梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01617~~2CM绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01618~~女童防寒背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01619~~染色扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01620~~发圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '01621~~女式子裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01622~~女式防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01623~~机织婴儿罩衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01624~~女童机织罩衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01625~~针织婴儿套头衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01626~~女童针织紧身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01627~~针织婴儿紧身裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01628~~机织婴儿休闲衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01629~~男童机织休闲衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01630~~针织婴儿裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01631~~女童针织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01632~~男式针织棉服背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01633~~男式针织防寒马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01634~~主尺码标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01635~~钻石扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01636~~男童针织T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01637~~针织婴儿T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01638~~针织婴儿紧身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01639~~女童针织紧身裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01640~~男童机织休闲衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01641~~机织婴儿休闲衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01642~~女式梭织连体裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01643~~男童针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01644~~麻粘胶梭织面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01645~~涤弹双面针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01646~~1.3cm四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01647~~侧口袋拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01648~~上口袋拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01649~~弹力斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01650~~棉麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01651~~线轮扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01652~~调节环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01653~~棉麻弹力皱布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01654~~化纤制婴儿针织防寒服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01655~~棉制婴儿短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01656~~棉制婴儿背带裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01657~~圆牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01658~~塑料钩子+D环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01659~~1.8CM气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01660~~5号防水门襟开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01661~~1.1CM四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01662~~2.0CM绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01663~~男童防风衣夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01664~~1.4CM气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01665~~女童防风夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01666~~二孔扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01667~~8号树脂门襟开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01668~~4号尼龙帽子开尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01669~~棉腈胚纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01670~~单孔丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01671~~针织水洗布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01672~~30%再生弹力缎面~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01673~~25%再生雪纺珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01674~~雪珠纺~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01675~~人丝染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01676~~其他材料制女式连衣裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01677~~双层布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01678~~棉天丝染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01679~~平橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107050803~~否~~日用橡胶制品~~~~~~',
    //     '01680~~白纱带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01681~~女式无袖T恤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01682~~其他材料制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01683~~棉制女童连衣裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01684~~涤纶混纺梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01685~~包边织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01686~~红贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01687~~圆贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01688~~白贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01689~~涤美丽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01690~~双面吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01691~~透明枪针~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109013305~~否~~喷枪及类似器具零件~~~~~~',
    //     '01692~~女马甲加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01693~~鱼骨衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '01694~~TC面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01695~~棉弹磨毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01696~~抽绳包头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01697~~PU涂层化纤制女式针织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01698~~4号树脂口袋闭尾拉链~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051206~~否~~拉链及其零件~~~~~~',
    //     '01699~~0.9cm气眼~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01700~~TR府绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01701~~毛涤斜纹西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020119~~否~~西服套装~~~~~~',
    //     '01702~~单丝人棉印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01703~~吊牌吊绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01704~~女式T恤衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01705~~过桥板~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01706~~橡筋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019906~~否~~供技术用途纺织品~~~~~~',
    //     '01707~~冷冻去骨牛肉冷冻去骨牛西冷~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01708~~冷冻去骨牛肉冷冻牛眼肉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01709~~全涤雪纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01710~~PU涂层化纤制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01711~~棉天丝~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01712~~领条~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01713~~蝴蝶片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01714~~氨纶绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01715~~纫线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010104~~否~~缝纫线~~~~~~',
    //     '01716~~四爪裤钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01717~~D型环~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01718~~涤弹四面弹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01719~~冷冻去骨牛肉牛后部肉块~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01720~~冷冻去骨牛肉牛前部肉块~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01721~~染色提花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01722~~冷冻去骨牛肉牛肋条~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01723~~冷冻去骨牛肉肋条肉~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01724~~冷冻去骨牛肉肋条~~~~~~0.09~~~~~~0.0~~False~~0000000000~~False~~10301070199~~否~~其他鲜、冷、冻肉~~~~~~',
    //     '01725~~钮襻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01726~~女式衬衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01727~~编织织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01728~~胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '01729~~楼梯织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01730~~编织绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01731~~服装水洗费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01732~~天丝弹力牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01733~~斜纹美丽绸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01734~~吊牌粒子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '01735~~裤子后整理加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01736~~方巾加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01737~~人纤制女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01738~~粘弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01739~~木珠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01740~~小型条形码~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01741~~细平布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01742~~竖条布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01743~~合金钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01744~~皮带头~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~1080424~~否~~金属制日用杂品~~~~~~',
    //     '01745~~全棉牛仔布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01746~~钢圈套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01747~~女式无袖衬衫加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01748~~竹节纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01749~~尼龙卷毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01750~~针织牛仔~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01751~~全棉丝光棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01752~~空气层乱麻~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01753~~棉弹力斜纹面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01754~~尼龙面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01755~~全涤印花梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01756~~人棉涤染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01757~~粗花呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01758~~塑料制品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '01759~~棉弹斜纹磨毛布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01760~~速干面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01761~~涤氨针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01762~~女式背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01763~~粘涤弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01764~~夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01765~~涤麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01766~~口袋布印字~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01767~~布条印字~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01768~~开条加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01769~~女童针织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01770~~金鱼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01771~~耳袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01772~~女童针织短裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01773~~染纱加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01774~~鱼丝线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01775~~1CM树脂四眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01776~~男童防寒外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01777~~1.3CM金属绳扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01778~~1.2CM塑料四合扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01779~~290T内胆布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01780~~裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01781~~金属圈~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051299~~否~~其他日用杂品~~~~~~',
    //     '01782~~化纤制梭织女式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01783~~毛制梭织男式大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01784~~化纤制男式针织大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01785~~棉制女式棉服加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01786~~钮扣袢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01787~~杨柳绉布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01788~~发泡布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01789~~全棉里布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01790~~锦纶人棉弹力印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01791~~印刷品~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01792~~女式胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01793~~胸衣片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010699~~否~~其他纺织制品~~~~~~',
    //     '01794~~女童梭织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01795~~女婴梭织背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01796~~女婴梭织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01797~~女童梭织裙子加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01798~~女婴梭织连体衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01799~~女童梭织连体裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01800~~男童梭织棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01801~~婴儿梭织棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01802~~吊卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01803~~女童梭织棉袄加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01804~~机织婴儿裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01805~~女童机织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01806~~杨柳绉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01807~~气流布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01808~~府绸布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01809~~条纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01810~~消光尼丝纺面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01811~~尾夹铜~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106040105~~否~~夹具类文具~~~~~~',
    //     '01812~~女婴梭织上衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01813~~天丝罗马布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01814~~涤弹针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01815~~女式针织中裤加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01816~~装饰织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01817~~包布穿绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01818~~2CM吊钟~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01819~~1.8CM猪鼻扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01820~~四孔细边扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01821~~女式毛呢大衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01822~~女式防寒夹克加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01823~~天丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01824~~再生尼龙染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01825~~再生涤纶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01826~~包布绳~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010901~~否~~纤维纺制绳、缆~~~~~~',
    //     '01827~~女童防寒外套加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01828~~徽章~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01829~~鱼骨线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01830~~塑料夹~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060112~~否~~日用塑料制品~~~~~~',
    //     '01831~~皇冠~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051210~~否~~头发装饰用物品~~~~~~',
    //     '01832~~平纹织带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011201~~否~~经编织物~~~~~~',
    //     '01833~~水洗吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01834~~印字弹力带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011007~~否~~狭幅机织物~~~~~~',
    //     '01835~~棉氨针织坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01836~~女童防风衣加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01837~~女童半身裙加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01838~~女童抹胸加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01839~~边带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '01840~~棉胚~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01841~~树脂药片二眼扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01842~~涤弹四面~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01843~~化纤制女式钩编背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01844~~卡片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010507~~否~~纸或纸板制标签~~~~~~',
    //     '01845~~皮带扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01846~~涤塔夫布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01847~~针织女式背心加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '01848~~包布腰扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106051207~~否~~扣类制品及其零件~~~~~~',
    //     '01849~~亚麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01850~~双面胶条衬~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104011006~~否~~硬挺纺织物~~~~~~',
    //     '01851~~女童防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01852~~女式长款上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01853~~PU女式半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01854~~女式吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01855~~女式防寒夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01856~~PU涂层化纤制女式针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01857~~化纤制女式马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01858~~棉制女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01859~~女童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01860~~女童大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01861~~人纤制女童短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01862~~棉制女童短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01863~~PU女式胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '01864~~PU涂层化纤制女式针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01865~~PU涂层化纤制男式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01866~~人纤制男式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01867~~男童防寒外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01868~~女童防寒外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01869~~套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01870~~女式梭织半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01871~~女式梭织衬衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01872~~涤粘金属丝弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01873~~涤粘锦弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01874~~棉尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01875~~女式针织拉链衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01876~~男婴梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01877~~男婴针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01878~~男婴梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01879~~男婴梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01880~~男童梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01881~~男童梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01882~~男婴针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01883~~男婴针织T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01884~~男童针织T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01885~~男婴针织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01886~~男婴针织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01887~~男童针织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01888~~棉尼龙羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01889~~腈纶尼龙羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01890~~男童防寒背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01891~~粘锦涤色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01892~~粘涤锦布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '01893~~女式梭织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01894~~女童短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01895~~女童帽子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '01896~~女式梭织PU短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01897~~PU女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01898~~女童梭织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01899~~烫标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01900~~商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '01901~~纸张~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~109022103~~否~~纸或纸板整理机械~~~~~~',
    //     '01902~~男式梭织衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '01903~~男式梭织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01904~~女式西服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01905~~女式西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01906~~女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01907~~涤纶芯线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01908~~涤纶短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01909~~腈纶~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01910~~涤包氨压线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '01911~~尼龙短纤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01912~~女式防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01913~~合纤制女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01914~~女童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01915~~婴儿防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01916~~PU涂层化纤制女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01917~~女式防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01918~~女式长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01919~~女式抹胸上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01920~~PU女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01921~~PU女式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01922~~防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01923~~连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01924~~裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01925~~套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01926~~防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01927~~梭织女裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01928~~梭织男防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01929~~梭织男防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01930~~梭织女长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01931~~梭织女连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01932~~梭织女短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01933~~针织女长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01934~~梭织男长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01935~~男童衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01936~~女式毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01937~~棉制女式连身衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01938~~PU涂层化纤制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01939~~女式文胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01940~~化纤制女式胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01941~~梭织男夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01942~~梭织女风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01943~~女式梭织PU长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01944~~女式T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01945~~PU涂层化纤制女式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01946~~开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01947~~女式吊带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01948~~回料尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '01949~~女童套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01950~~化纤制女式连衣裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01951~~化纤制女式吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01952~~裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01953~~吊带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01954~~女童防寒背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01955~~男童防寒连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01956~~男童防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01957~~女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01958~~女童短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01959~~女婴背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01960~~女童背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01961~~男童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '01962~~女童背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01963~~女婴背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01964~~男式梭织防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01965~~女式斗篷~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01966~~女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01967~~男式外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01968~~女式针织长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01969~~PU女式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01970~~PU女式夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01971~~女式针织长袖T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01972~~女式长袖T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01973~~女式套头针织衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01974~~女式开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01975~~女式马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01976~~女式无袖短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01977~~化纤制女式针织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01978~~女童棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01979~~男婴棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01980~~男童棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01981~~婴儿防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01982~~男童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01983~~女童马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01984~~女婴梭织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01985~~女婴梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01986~~女婴针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01987~~女童梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01988~~男婴梭织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '01989~~梭织PU皮女长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '01990~~梭织PU皮女短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01991~~男式针织T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '01992~~女式短风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01993~~女式针织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01994~~女式梭织长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01995~~女式连帽衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01996~~女式连帽衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01997~~PU女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '01998~~PU涂层化纤制女式针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '01999~~男式梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02000~~男式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02001~~合纤制女式钩编吊带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02002~~男式梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02003~~PU涂层化纤制女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02004~~女童裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02005~~涤纶针织布加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02006~~涤纶针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02007~~涤纶针织染色布加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '02008~~棉制女式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02009~~再生涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02010~~棉涤交织~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02011~~梭织涤纶布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02012~~人纤制女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02013~~女童半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02014~~化纤制女式吊带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02015~~PU涂层化纤制女式防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02016~~PU女式衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02017~~毛制男式短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02018~~婴儿防寒背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02019~~女童防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02020~~男童防寒上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02021~~女童羽绒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02022~~女童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02023~~女婴防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02024~~婴儿衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02025~~女式连体服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02026~~女式抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '02027~~斜纹绒布面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02028~~女童连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02029~~女童针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02030~~女童针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02031~~女童针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02032~~女童梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02033~~女童梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02034~~PU涂层化纤制男式针织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02035~~PU涂层化纤制男式针织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02036~~PU涂层化纤制女式针织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02037~~梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02038~~梭织女背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02039~~PU女式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02040~~女童衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02041~~女式梭织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02042~~女童防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02043~~女童防寒羽绒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02044~~女童防寒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02045~~PU涂层化纤制女式针织防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02046~~梭织男衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '02047~~长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02048~~女童吊带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02049~~女童背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02050~~PU涂层化纤制女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02051~~男式开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02052~~男式马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02053~~女式PU短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02054~~女式PU长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02055~~仿丝棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107040399~~否~~其他合成纤维~~~~~~',
    //     '02056~~水钻加工费~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~20105~~否~~其他加工劳务~~~~~~',
    //     '02057~~合纤制女童针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02058~~合纤制男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02059~~女式工装裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02060~~男式梭织外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02061~~女式PU连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02062~~喷胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '02063~~喷胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010106~~否~~印染布~~~~~~',
    //     '02064~~喷胶棉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010103~~否~~线~~~~~~',
    //     '02065~~PU涂层化纤制女式针织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02066~~化纤制女式短款马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02067~~化纤制女式针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02068~~化纤制女式无袖防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02069~~化纤制女式针织无袖防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02070~~化纤制女式针织防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02071~~粘胶尼龙弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02072~~涤粘锦梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02073~~女士西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02074~~亚麻粘胶染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02075~~棉梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02076~~涤纶梭织剪花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02077~~女式防寒短上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02078~~女式针织连帽拉链开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02079~~女式梭织背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02080~~男童机织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02081~~男童机织防寒马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02082~~合纤制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02083~~合纤制女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02084~~化纤制女式连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02085~~斗篷~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020501~~否~~帽子~~~~~~',
    //     '02086~~男防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02087~~上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02088~~上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02089~~裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02090~~背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02091~~背心裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02092~~商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02093~~贴纸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02094~~绳子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060299~~否~~其他塑料半成品、辅料~~~~~~',
    //     '02095~~印唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02096~~彩色挂牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02097~~智能卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02098~~染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02099~~女式针织拉链开衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02100~~无袖防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02101~~女式其他服装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02102~~化纤制女式针织挂脖抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02103~~梭织背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02104~~面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02105~~梭织PU皮背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02106~~化纤制男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02107~~全棉男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02108~~女童半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02109~~女式梭织西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02110~~化纤制女式针织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02111~~防盗钉~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019903~~否~~成匹编带、装饰带及类似品~~~~~~',
    //     '02112~~防盗扣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104019902~~否~~纺织材料制标签、徽章及类似品~~~~~~',
    //     '02113~~PU涂层化纤制女式针织大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02114~~商标~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '02115~~吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '02116~~洗唛~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '02117~~大纸卡~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020199~~否~~其他服装~~~~~~',
    //     '02118~~人纤制女式短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02119~~全棉梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02120~~亚麻粘梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02121~~毛领面料~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010701~~否~~毡呢~~~~~~',
    //     '02122~~女童抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02123~~女式针织抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02124~~棉弹力坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02125~~涤弹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02126~~棉粘胶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02127~~男童大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02128~~化纤制男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02129~~棉制男式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02130~~全棉男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02131~~男式裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02132~~棉亚麻纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02133~~羊毛绒布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02134~~涤纶斜纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02135~~涤纶梭织印花剪花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02136~~粘锦弹力针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02137~~棉针织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02138~~梭织女夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02139~~亚麻梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02140~~PU涂层合纤制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02141~~棉粘莱赛尔梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02142~~服务费~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~30499~~否~~其他现代服务~~~~~~',
    //     '02143~~男童游戏服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02144~~棉涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02145~~化纤制女式针织防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02146~~棉制女式防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02147~~亚麻棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02148~~腈纶尼龙涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02149~~纱线~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02150~~PU涂层化纤制女式针织半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02151~~女式梭织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02152~~合纤制女式梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02153~~PU涂层合纤制女式短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02154~~人纤制女式套头T恤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02155~~婴儿连体棉服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02156~~女童夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02157~~女童针织外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02158~~梭织PU皮女上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02159~~针织女连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02160~~梭织男棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02161~~梭织女短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02162~~梭织PU皮女风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02163~~棉纺纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02164~~棉涤纶氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02165~~婴儿套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02166~~女童套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02167~~梭织男短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02168~~梭织男马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02169~~上衣棉袄~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02170~~涤氨乱麻坯布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02171~~女童胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02172~~女童梭织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02173~~女童梭织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02174~~女童梭织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02175~~女童梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02176~~尼龙染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02177~~涤纶棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02178~~女式其他上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02179~~PU涂层化纤制女式针织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02180~~PU女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02181~~男式针织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02182~~涤麻染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02183~~全棉染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02184~~上衣套装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02185~~女童长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02186~~婴儿长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02187~~男童防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02188~~PU女式连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02189~~女式梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02190~~男童外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02191~~男童背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02192~~男童风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02193~~女式针织西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02194~~梭织上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02195~~针织女式背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02196~~女童针织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02197~~女童针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02198~~女式针织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02199~~女式针织带帽衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02200~~男童羽绒外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02201~~男童羽绒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02202~~女童羽绒外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02203~~女童羽绒背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02204~~棉腈纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02205~~棉制女式防风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02206~~化纤制女式针织短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02207~~合纤制女式半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02208~~化纤制女童针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02209~~化纤制女式针织短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02210~~棉制男童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02211~~男童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02212~~棉弹力梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02213~~涤氨弹力布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02214~~防寒短外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02215~~女式和服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02216~~涤锦染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02217~~合纤制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02218~~女式无袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02219~~女童无袖衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02220~~粘胶涤纶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02221~~锦氨梭织染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02222~~PU涂层化纤制女式大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02223~~收绳包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030302~~否~~手提包（袋）、背包~~~~~~',
    //     '02224~~六片帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020502~~否~~帽子附件~~~~~~',
    //     '02225~~盆帽~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020502~~否~~帽子附件~~~~~~',
    //     '02226~~斜背包~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104030399~~否~~其他箱、包及类似容器~~~~~~',
    //     '02227~~腈纶涤纶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02228~~亚麻棉涤纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02229~~女式皮风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02230~~女式无袖皮裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02231~~胸罩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020101~~否~~女内衣~~~~~~',
    //     '02232~~裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02233~~女式无袖风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02234~~纸箱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '02235~~胶袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060106~~否~~塑料丝、绳、袋及编织品~~~~~~',
    //     '02236~~箱贴~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~106010204~~否~~包装用纸及纸板~~~~~~',
    //     '02237~~化纤制女童针织防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02238~~毛衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020120~~否~~女毛衣~~~~~~',
    //     '02239~~合纤制女童裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02240~~合纤制女童上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02241~~涤纶腈纶尼龙纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02242~~男式梭织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02243~~棉制女式裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02244~~棉制女式无袖上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02245~~梭织男风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02246~~粘锦弹梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02247~~全棉梭织印花布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02248~~女式无袖夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02249~~合纤制女式梭织马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02250~~合纤制女式梭织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02251~~PU涂层棉制女式针织长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02252~~女婴针织半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02253~~女童针织半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02254~~女童针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02255~~女婴针织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02256~~女童针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02257~~女婴针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02258~~涤纶氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02259~~粘麻色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02260~~女童梭织连体裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02261~~氨纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02262~~女士裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02263~~人纤制女式无袖上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02264~~棉制男式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02265~~女裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02266~~女式无袖上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02267~~PU涂层化纤制女式针织连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02268~~梭织女背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02269~~女式吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02270~~女式马裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02271~~梭织PU皮抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02272~~色纺布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02273~~粘亚麻布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02274~~女式PU连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02275~~人身意外险~~~~~~0.06~~~~~~0.0~~False~~0000000000~~False~~306030199~~否~~其他人身保险服务~~~~~~',
    //     '02276~~四面弹麂皮绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02277~~女士马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02278~~女式梭织抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02279~~梭织女衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02280~~涤棉弹力染色布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02281~~女式PU半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02282~~女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02283~~女式长款马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02284~~棉粘莱赛尔弹力梭织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02285~~女童外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02286~~吊牌~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060299~~否~~其他塑料半成品、辅料~~~~~~',
    //     '02287~~塑钩~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107060199~~否~~其他塑料制品~~~~~~',
    //     '02288~~针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02289~~男式西装裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02290~~女式梭织裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02291~~化纤制女式针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02292~~人造长毛绒~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010507~~否~~寝具及类似填充用品~~~~~~',
    //     '02293~~男式梭织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02294~~女款条纹衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02295~~PU女式马甲~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02296~~女童连体服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02297~~莫代尔棉纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02298~~棉腈纶涤纶纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02299~~男式防寒夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02300~~合纤制男式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02301~~PU女童半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02302~~针织男长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02303~~针织男上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02304~~女式针织裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02305~~男式棉服背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02306~~男式棉服夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02307~~男式棉服外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02308~~涤粘弹力梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02309~~裤装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02310~~干燥剂~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~107029901~~否~~室内散香或除臭制品~~~~~~',
    //     '02311~~拖鞋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020499~~否~~其他鞋~~~~~~',
    //     '02312~~棉制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02313~~长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02314~~女裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02315~~男短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020114~~否~~男裤子~~~~~~',
    //     '02316~~女式挂脖吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02317~~涤粘梭织色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02318~~女式挂脖胸衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02319~~中性款净色衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020107~~否~~女衬衫~~~~~~',
    //     '02320~~女式梭织裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02321~~化纤制女式针织连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02322~~人纤制女式针织裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02323~~化纤制女式拉链外套~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02324~~女式带帽开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02325~~粘锦弹针织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02326~~涤粘梭织毛呢~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02327~~女式针织半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02328~~化纤制男童针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02329~~化纤制男童针织裤子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02330~~化纤制女童针织T恤衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02331~~PU涂层合纤制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02332~~涤粘弹色织布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02333~~棉莫代尔纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02334~~人纤制女式裙子~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02335~~化纤制女式针织吊带~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02336~~PU女式吊带衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02337~~PU女式抹胸~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02338~~PU女式针织短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02339~~PU女式针织半身裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02340~~PU女式半裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02341~~梭织PU皮女裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02342~~针织PU皮女长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02343~~针织男衬衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020108~~否~~男衬衫~~~~~~',
    //     '02344~~梭织男短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02345~~针织女防风短上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02346~~针织女短大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02347~~针织女上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02348~~梭织男防风短上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02349~~女式绗棉背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02350~~PU涂层棉制女式长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02351~~全涤平纹布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02352~~男童背带裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02353~~男童连体服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02354~~睡袋~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020105~~否~~女浴衣~~~~~~',
    //     '02355~~腈纶羊毛纱~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010102~~否~~纱~~~~~~',
    //     '02356~~女童游戏服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02357~~披风~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02358~~男式上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02359~~合纤制女式针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020113~~否~~女裤子~~~~~~',
    //     '02360~~抹胸上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02361~~女式抹胸上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02362~~化纤制女式针织吊带背心~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02363~~男式梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02364~~女式梭织套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02365~~男式梭织西装~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020112~~否~~男上衣~~~~~~',
    //     '02366~~织片~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02367~~合纤制女童针织短裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02368~~女童连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02369~~男童连体衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02370~~女式梭织风衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02371~~男童梭织夹克~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02372~~女式毛呢大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02373~~女式无袖拉链衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02374~~男童防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02375~~男婴防寒服~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02376~~梭织假毛女大衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02377~~梭织女防风短上衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02378~~女童PU连衣裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02379~~女童PU短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02380~~女童PU长裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02381~~女童PU长裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02382~~涤纶针织染色复合布~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104010105~~否~~布~~~~~~',
    //     '02383~~女童裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02384~~女式连身衣~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02385~~女式针织裙裤~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020115~~否~~裙子~~~~~~',
    //     '02386~~高领衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02387~~套头衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020111~~否~~女上衣~~~~~~',
    //     '02388~~女童开襟衫~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02389~~婴儿短裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~',
    //     '02390~~婴儿背带裙~~~~~~0.13~~~~~~0.0~~False~~0000000000~~False~~104020124~~否~~婴幼儿服装~~~~~~'
    //   ];
    //   // 02390 编码, 婴儿背带裙 名称, 简码, 商品税目, 0.13税率, 规格型号, 计量单位, 0.0单价,False含税价标志, 0000000000隐藏标志, False, 104020124分类编码, 否, 婴幼儿服装, , ,
    //   const label = '编码~~名称~~简码~~商品税目~~税率~~规格型号~~计量单位~~单价~~含税价标志~~隐藏标志~~不知道是啥标志~~税收分类编码~~是否享受优惠政策~~税收分类编码名称~~优惠政策类型~~零税率标识~~编码版本号';
    //   const labels = label.split('~~');
    //   let a = [];
    //   console.log(a);
    //   t.forEach((str) => {
    //     const arr = str.split('~~');
    //     const item = {
    //       名称: arr[1],
    //       项目分类: arr[labels.findIndex((i) => i === '税收分类编码名称')],
    //       分类编码: arr[labels.findIndex((i) => i === '税收分类编码')],
    //       规格型号: arr[labels.findIndex((i) => i === '规格型号')],
    //       单位: arr[labels.findIndex((i) => i === '计量单位')],
    //       单价: arr[labels.findIndex((i) => i === '单价')],
    //       是否含税: arr[labels.findIndex((i) => i === '含税价标志')] === 'True' ? 'Y' : 'N',
    //       简码: arr[labels.findIndex((i) => i === '简码')],
    //       税率: arr[labels.findIndex((i) => i === '税率')],
    //       是否使用优惠政策: arr[labels.findIndex((i) => i === '是否享受优惠政策')],
    //       优惠政策类型: arr[labels.findIndex((i) => i === '优惠政策类型')],
    //     };
    //     a.push(item);
    //   });
    //   if (a.length > 0) {
    //     const sheet = xlsx.utils.json_to_sheet(a);
    //     const wb = xlsx.utils.book_new();
    //     xlsx.utils.book_append_sheet(wb, sheet, 'sheet1');
    //     xlsx.writeFile(wb, (this.$utils.formatDate(new Date(), 'yyyyMMdd') + '.xlsx'));
    //   }
    // },
  },
  watch: {
    loginData(val) {
      if (val) {
        this.$store.dispatch('AUTH_GET_USER').then(() => {
          this.$router.push('/');
        });
      }
    },
  },
};

</script>

<style>
  .full-screen {
    /*height: 100vh;*/
  }
  .login-width {
    width: 360px;
    max-width: 100%;
  }
  .login-wrap {
    margin: 0 auto;
    margin-top: 10vh;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
  }
  .login-title {
    color: #fff;
    background: #fff;
    border-color: #3a3f51;
    padding: 20px 15px 10px;
  }
  .login-title-name {
    font-size: 24px;
    color: #00c0f1;
    line-height: 2;
    font-weight: bold;
  }
  .login-content {
    background: #fff;
    padding: 10px 20px 20px;
  }
  .login-description {
    font-size: 14px;
    color: #afb9c3;
    line-height: 1.42857143;
    margin-bottom: 10px;
  }
  .login-button {
    width: 100%;
    height: 32px;
    font-size: 14px;
  }
  .login-addition a {
    color: #647484;
    text-decoration: none;
  }
  .login-addition a:hover {
    text-decoration: underline;
  }
  .login-forgot-password {
    margin-right: 15px;
  }
  .login-new-account {
    margin-left: 15px;
  }
</style>
