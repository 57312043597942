import Vue from 'vue';
import ElementUi from 'element-ui';
import axios from 'axios';
// import VuePreview from 'vue-preview';
import VueAxios from 'vue-axios';
import Utils from '@/plugins/utils';
import '@/assets/css/unify-default.css';
import router from './router';
import store from './store/index';
// import './styles/theme/unify-default.scss';
import './styles/app.scss';

const debug = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = false;
if (!debug) {
  /* eslint-disable no-console */
  Vue.config.errorHandler = (err) => {
    console.error(err);
  };
}

Vue.use(ElementUi);
Vue.use(VueAxios, axios);
// input default size
Vue.prototype.$ELEMENT = { ...Vue.prototype.$ELEMENT, size: 'small' };
Vue.use(Utils); // 必须在最后，因为用到了vue-axios和element-ui

/* eslint-disable no-new */
const data = { isToggle: true };
new Vue({
  data,
  el: '#app',
  router,
  render: h => h(
    'div',
    {
      attrs: { id: 'app' },
      class: { toggle: data.isToggle },
    },
    [h('router-view')],
  ),
  store,
});
